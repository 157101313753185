import React from "react";
import {
  Box,
  Tabs,
  TabList,
  Tab,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { HamburgerIcon, BellIcon, InfoIcon, AddIcon, EditIcon, WarningTwoIcon } from "@chakra-ui/icons";

const NavigationBar = ({ onOpenAddStock, onOpenSuggestion, bgColor, setSelectedTab, onDrawerOpen }) => {
  return (
    <Box w="100%" h="50px" zIndex="1000" bg={bgColor} display="flex">
      <Tabs orientation="horizontal" isFitted>
        <Box display="flex" justifyContent="space-between" alignItems="center" px={4} pt={2}>
          <TabList display="flex" flexDirection="row">
            <Tooltip label="Quotes">
              <Tab onClick={() => setSelectedTab('quotes')}>
                <HamburgerIcon />
              </Tab>
            </Tooltip>
            <Tooltip label="Analysis">
              <Tab onClick={() => setSelectedTab('analysis')}>
                <InfoIcon />
              </Tab>
            </Tooltip>
            <Tooltip label="Sugestion">
              <Tab onClick={() => setSelectedTab('Suggestion')}>
                <EditIcon />
              </Tab>
            </Tooltip>
            <Tooltip label="Infostock">
              <Tab onClick={() => setSelectedTab('infoStock')}>
                <WarningTwoIcon />
              </Tab>
            </Tooltip>
          </TabList>
        </Box>
      </Tabs>
    </Box>
  );
};

export default NavigationBar;
