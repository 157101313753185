import React, { useState, useEffect, useCallback, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  Grid,
  GridItem,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Card,
  CardBody,
  CardHeader,
  Heading,
  useToast,
  Select,
  Flex
} from "@chakra-ui/react";
import SymbolSearch from './SymbolSearch'; // Importe o componente SymbolSearch


const BacktestAnalysis = ({ symbol, tickers }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const formDataRef = useRef(null);
  const [manualStrategy, setManualStrategy] = useState(false);
  const [shouldRunBacktest, setShouldRunBacktest] = useState(false);


  const strategyNames = {
    'kairi_strategy': 'Estratégia Kairi',
    'breakout_strategy': 'Estratégia Breakout',
    'ifr_strategy': 'Estratégia IFR (Divergencia)',
    'squeeze_strategy': 'Estratégia Squeeze',
    'turtle_strategy': 'Estratégia Turtle',
    'darvas_strategy': 'Estratégia Darvas'
  };

  const [formData, setFormData] = useState(() => {
    const endDate = new Date();
    const startDate = new Date();
    startDate.setMonth(startDate.getMonth() - 24);

    return {
      strategy_name: typeof symbol === 'object' ? symbol.strategy || "ifr_strategy" : "ifr_strategy",
      symbol: typeof symbol === 'object' ? symbol.symbol : symbol,
      init_cash: 10000,
      sl_stop: 0.03,
      tp_stop: 0.06,
      period: '24',
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0]
    };
  });

  useEffect(() => {
    formDataRef.current = formData;
  }, [formData]);

  const [backtestResults, setBacktestResults] = useState(null);
  const [loading, setLoading] = useState(false);

  const errorMessages = {
    'No trades executed in the period': 'Nenhuma operação encontrada no período selecionado.',
    'Could not get data': 'Não foi possível obter dados para este ativo.',
    'Failed to connect to database': 'Erro de conexão com o servidor.',
    'Lost connection to MySQL server': 'Erro temporário de conexão. Por favor, tente novamente.',
    'cannot access local variable': 'Erro no processamento dos dados. Por favor, tente novamente.',
  };

  // Função de tratamento de mensagens dentro do componente
  const getFriendlyErrorMessage = (error) => {
    // Verifica se a mensagem de erro está no nosso mapeamento
    for (const [technicalMsg, friendlyMsg] of Object.entries(errorMessages)) {
      if (error.toLowerCase().includes(technicalMsg.toLowerCase())) {
        return friendlyMsg;
      }
    }
    // Mensagem default se não encontrar no mapeamento
    return 'Ocorreu um erro ao executar o backtest. Tente novamente mais tarde.';
  };

// Memoize a função handleSubmit com useCallback
const handleSubmit = useCallback(async () => {
  setLoading(true);
  try {
    const response = await fetch('https://data.fatcat.app.br/backtest', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formDataRef.current)
    });

    const data = await response.json();

    if (data.error) {
      throw new Error(data.error);
    }

    if (data.status === 'success' && data?.data?.performance) {
      setBacktestResults(data.data);
      onClose();
      toast({
        title: "Backtest concluído",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    }
    else if (data.status === 'warning') {
      toast({
        title: "Aviso",
        description: getFriendlyErrorMessage(data.message),
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }
    else {
      throw new Error(data.message || 'Erro desconhecido');
    }
  } catch (error) {
    console.error('Error running backtest:', error);
    const errorMessage = error.message || 'Erro desconhecido';

    toast({
      title: "Erro no Backtest",
      description: getFriendlyErrorMessage(errorMessage),
      status: "error",
      duration: 5000,
      isClosable: true,
    });

    if (errorMessage.includes('Lost connection')) {
      toast({
        title: "Sugestão",
        description: "Aguarde um momento e tente novamente.",
        status: "info",
        duration: 7000,
        isClosable: true,
      });
    }
  } finally {
    setLoading(false);
  }
}, [onClose, toast]); // Remove formData das dependências


useEffect(() => {
  if (shouldRunBacktest) {
    onOpen();
    const timer = setTimeout(() => {
      handleSubmit();
      setShouldRunBacktest(false);
    }, 100);
    return () => clearTimeout(timer);
  }
}, [shouldRunBacktest, onOpen, handleSubmit]);

  // Adicione um useEffect para atualizar o formData quando o symbol mudar
  useEffect(() => {
    if (typeof symbol === 'object' && symbol.strategy && !manualStrategy) {
      setFormData(prev => ({
        ...prev,
        strategy_name: symbol.strategy,
        symbol: symbol.symbol
      }));
      setShouldRunBacktest(true);
    }
  }, [symbol, manualStrategy]);

  useEffect(() => {
    if (typeof symbol === 'object' && symbol.strategy) {
      setManualStrategy(false); // Reset manual strategy when new symbol comes
      setFormData(prev => ({
        ...prev,
        strategy_name: symbol.strategy,
        symbol: symbol.symbol
      }));
      setShouldRunBacktest(true);
    }
  }, [symbol]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('pt-BR');
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value);
  };

  const formatInteger = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  const calculateCapitalEvolution = (trades, initialCapital) => {
    let capitalHistory = [{
      date: trades[0].entry_date,
      capital: initialCapital,
      pnl: 0,
      accumulated_pnl: 0
    }];
    let currentCapital = initialCapital;
    let accumulatedPnl = 0;

    trades.forEach((trade) => {
      accumulatedPnl += trade.pnl;
      currentCapital += trade.pnl;
      capitalHistory.push({
        date: trade.exit_date,
        capital: currentCapital,
        pnl: trade.pnl,
        accumulated_pnl: accumulatedPnl
      });
    });

    return capitalHistory;
  };

  const calculateMonthlyCapitalEvolution = (trades, initialCapital) => {
    if (!trades || trades.length === 0) return [];
    const monthlyResults = new Map();

    const firstDate = new Date(trades[0].entry_date);
    const firstMonth = `${firstDate.getFullYear()}-${String(firstDate.getMonth() + 1).padStart(2, "0")}`;
    monthlyResults.set(firstMonth, { capital: initialCapital, date: firstMonth });

    let currentCapital = initialCapital;

    trades.forEach((trade) => {
      const exitDate = new Date(trade.exit_date);
      const monthKey = `${exitDate.getFullYear()}-${String(exitDate.getMonth() + 1).padStart(2, "0")}`;
      currentCapital += trade.pnl;
      monthlyResults.set(monthKey, { date: monthKey, capital: currentCapital });
    });

    return Array.from(monthlyResults.values()).sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR', { month: 'short', year: 'numeric' })
      .replace('.', '')
      .toUpperCase();
  };

  const calculateDates = (months) => {
    const end = new Date();
    const start = new Date();
    start.setMonth(start.getMonth() - parseInt(months));

    return {
      start_date: start.toISOString().split('T')[0],
      end_date: end.toISOString().split('T')[0]
    };
  };

  const handleInputChange = useCallback((field, value) => {
    if (field === 'period') {
      const dates = calculateDates(value);
      setFormData(prev => ({
        ...prev,
        [field]: value,
        start_date: dates.start_date,
        end_date: dates.end_date
      }));
    } else if (field === 'strategy_name') {
      setManualStrategy(true);
      setFormData(prev => ({
        ...prev,
        strategy_name: value,
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  }, []);
  
  const renderStats = () => {
    // Validação mais rigorosa
    if (!backtestResults?.performance || typeof backtestResults.performance !== 'object') {
      return null;
    }

    // Definindo valores default para evitar erros de undefined
    const {
      total_return = 0,
      win_rate = 0,
      final_value = 0,
      total_trades = 0,
      max_drawdown = 0,
      profit_factor = 0,
      avg_holding_period = 0
    } = backtestResults.performance;


    const stats = [
      {
        title: "Ativo",
        value: formData.symbol.toUpperCase(),
        isSymbol: true
      },
      {
        title: "Retorno",
        value: `${(total_return * 100).toFixed(0)}%`,
        isPositive: total_return > 0
      },
      {
        title: "Win Rate",
        value: `${(win_rate * 100).toFixed(0)}%`,  // Multiplicando por 100 para converter decimal em porcentagem
        isPositive: win_rate > 0.5
      },
      {
        title: "Capital Final",
        value: formatInteger(final_value),
        isPositive: final_value > formData.init_cash
      },
      {
        title: "Trades",
        value: total_trades,
        isNeutral: true
      },
      {
        title: "Drawdown Max",
        value: `${Math.abs(max_drawdown * 100).toFixed(0)}%`,
        isNegative: true
      },
      {
        title: "Fator de Lucro",
        value: profit_factor.toFixed(1),
        isPositive: profit_factor > 1
      },
      {
        title: "Dias Médio",
        value: avg_holding_period.toFixed(1),
        isNeutral: true
      }
    ];

    return (
      <Grid templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)", lg: "repeat(8, 1fr)" }} gap={2} mb={4}>
        {stats.map((stat, index) => (
          <Card key={index} size="sm" bg="white">
            <CardHeader p={2} pb={0}>
              <Text fontSize="xs" color="gray.500" fontWeight="semibold">
                {stat.title}
              </Text>
            </CardHeader>
            <CardBody pt={1} pb={2} px={2}>
              {stat.isSymbol ? (
                <Text
                  px={2}
                  py={0.5}
                  bg="blue.100"
                  color="blue.700"
                  fontSize="sm"
                  fontWeight="bold"
                  borderRadius="md"
                  display="inline-block"
                >
                  {stat.value}
                </Text>
              ) : (
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color={
                    stat.isPositive ? "green.500" :
                      stat.isNegative ? "red.500" :
                        stat.isNeutral ? "blue.500" :
                          "white"
                  }
                >
                  {stat.value}
                </Text>
              )}
            </CardBody>
          </Card>
        ))}
      </Grid>
    );
  };

  const renderChart = () => {
    if (!backtestResults?.trades || backtestResults.trades.length === 0) return null;

    const initialCapital = formData.init_cash;

    const monthlyData = calculateMonthlyCapitalEvolution(
      backtestResults.trades,
      initialCapital
    );

    return (
      <Card mb={6} bg="white">
        <CardHeader p={4}>
          <Heading size="sm" color="gray.600">Evolução do Capital</Heading>
        </CardHeader>
        <CardBody p={3}>
          <Box h="350px" w="100%">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={monthlyData}
                margin={{ top: 10, right: 30, left: 20, bottom: 35 }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  stroke="#e0e0e0"
                />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatMonthYear}
                  angle={-45}
                  textAnchor="end"
                  height={55}
                  interval={0}
                  tick={{
                    fontSize: 11,
                    fill: '#4A5568'  // gray.600
                  }}
                  stroke="#CBD5E0"
                />
                <YAxis
                  tickFormatter={formatCurrency}
                  tick={{
                    fontSize: 11,
                    fill: '#4A5568'  // gray.600
                  }}
                  stroke="#CBD5E0"
                  width={70}
                />
                <Tooltip
                  formatter={(value) => formatInteger(value)}
                  labelFormatter={formatMonthYear}
                  contentStyle={{
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    fontSize: '12px',
                    padding: '10px',
                    border: '1px solid #E2E8F0',
                    borderRadius: '4px'
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="capital"
                  stroke="#4299E1"
                  strokeWidth={2}
                  dot={{ r: 3, fill: '#4299E1' }}
                  activeDot={{ r: 5, fill: '#3182CE' }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </CardBody>
      </Card>
    );
  };

  const renderTable = () => {
    if (!backtestResults?.trades) return null;

    return (
      <Card>
        <CardHeader>
          <Heading size="md">Histórico de Operações</Heading>
        </CardHeader>
        <CardBody>
          <Box overflowX="auto">
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Seq</Th>
                  <Th>Data Entrada</Th>
                  <Th>Data Saída</Th>
                  <Th isNumeric>Preço Entrada</Th>
                  <Th isNumeric>Preço Saída</Th>
                  <Th isNumeric>Retorno %</Th>
                  <Th isNumeric>Lucro/Prejuízo</Th>
                  <Th isNumeric>Volume</Th>
                  <Th isNumeric>Duração</Th>
                </Tr>
              </Thead>
              <Tbody>
                {backtestResults.trades.map((trade, index) => (
                  <Tr key={index}>
                    <Td>{trade.seq}</Td>
                    <Td>{formatDate(trade.entry_date)}</Td>
                    <Td>{formatDate(trade.exit_date)}</Td>
                    <Td isNumeric>{trade.entry_price.toFixed(2)}</Td>
                    <Td isNumeric>{trade.exit_price.toFixed(2)}</Td>
                    <Td isNumeric color={trade.return > 0 ? "green.500" : "red.500"}>
                      {(trade.return * 100).toFixed(2)}%
                    </Td>
                    <Td isNumeric color={trade.pnl > 0 ? "green.500" : "red.500"}>
                      {formatCurrency(trade.pnl)}
                    </Td>
                    <Td isNumeric>{formatCurrency(trade.size)}</Td>
                    <Td isNumeric>{trade.duration} dias</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </CardBody>
      </Card>
    );
  };

  return (
    <Box p={6}>
    <Flex alignItems="center" gap={4} mb={6}>
      <Button
        colorScheme="blue"
        onClick={onOpen}
      >
        Executar Novo Backtest
      </Button>

      {backtestResults && (
        <Text color="gray.500" fontSize="lg" fontWeight="medium">
          {strategyNames[formData.strategy_name]} • {formData.symbol.toUpperCase()}
        </Text>
      )}
    </Flex>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Backtest Parameters</DrawerHeader>

          <DrawerBody>
            <VStack spacing={4}>
            <FormControl>
  <FormLabel>Ativo</FormLabel>
  <SymbolSearch
    tickers={tickers} // Lista de ativos
    onSelect={(value) => handleInputChange('symbol', value)} // Atualiza o formData quando selecionado
    initialSymbol={formData.symbol} // Valor inicial do símbolo
  />
</FormControl>
              <FormControl>
                <FormLabel>Estratégia</FormLabel>
                <Select
                  value={formData.strategy_name}
                  onChange={(e) => handleInputChange('strategy_name', e.target.value)}
                >
                  <option value="kairi_strategy">Kairi</option>
                  <option value="breakout_strategy">Breakout</option>
                  <option value="ifr_strategy">IFR</option>
                  <option value="squeeze_strategy">Squeeze</option>
                  <option value="turtle_strategy">Turtle</option>
                  <option value="darvas_strategy">Darvas</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Período</FormLabel>
                <Select
                  value={formData.period}
                  onChange={(e) => handleInputChange('period', e.target.value)}
                >
                  <option value="6">6 Meses</option>
                  <option value="12">12 Meses</option>
                  <option value="18">18 Meses</option>
                  <option value="24">24 Meses</option>
                  <option value="30">30 Meses</option>
                  <option value="36">36 Meses</option>
                </Select>
              </FormControl>

              <FormControl>
                <FormLabel>Capital Inicial</FormLabel>
                <NumberInput
                  value={formData.init_cash}
                  onChange={(valueString) => handleInputChange('init_cash', parseFloat(valueString))}
                  min={1000}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Stop Loss (%)</FormLabel>
                <NumberInput
                  value={formData.sl_stop}
                  onChange={(valueString) => handleInputChange('sl_stop', parseFloat(valueString))}
                  step={0.01}
                  min={0.01}
                  max={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>

              <FormControl>
                <FormLabel>Ganho (%)</FormLabel>
                <NumberInput
                  value={formData.tp_stop}
                  onChange={(valueString) => handleInputChange('tp_stop', parseFloat(valueString))}
                  step={0.01}
                  min={0.01}
                  max={1}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>



              <Button
                colorScheme="blue"
                onClick={handleSubmit}
                isLoading={loading}
                width="full"
                mt={4}
              >
                Executar Backtest
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {backtestResults && (
        <Box>
          {renderStats()}
          {renderChart()}
          {renderTable()}
        </Box>
      )}
    </Box>
  );
};

export default BacktestAnalysis;