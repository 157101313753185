import React, { useState, useEffect } from 'react';
import {
    Box, Skeleton, Heading, Text, useToast, Table, Thead, Tbody, Tr, Th, Td, TableContainer, Select, Flex, Grid, Card, CardBody,
    Stat, CardHeader, StatLabel, StatNumber, StatHelpText, StatGroup, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, Icon,
    useColorModeValue
} from '@chakra-ui/react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, LabelList, ReferenceLine } from 'recharts';
import axios from 'axios';
import logo from './assets/Logo.png';
import { ArrowUpIcon, ArrowDownIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { InfoIcon } from '@chakra-ui/icons';


const KairiList = ({ cliente = 0, setSelectTicker }) => {
    const [kairiData, setKairiData] = useState([]);
    const [loadingKairi, setLoadingKairi] = useState(false);
    const [selectedSymbols, setSelectedSymbols] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'kairiAtualAtivo', direction: 'desc' });
    const [selectedSector, setSelectedSector] = useState('');
    const [sectors, setSectors] = useState([]);
    const [modalData, setModalData] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isProOpen, onOpen: onProOpen, onClose: onProClose } = useDisclosure();
    const { isOpen: videoIsOpen, onOpen: openVideo, onClose: closeVideo } = useDisclosure(); // Controle do modal do vídeo
    const toast = useToast();

    // Cores dinâmicas baseadas no modo de tema
    const tableBg = useColorModeValue("gray.50", "gray.700");
    const tableTextColor = useColorModeValue("gray.800", "gray.200");
    const cardBg = useColorModeValue("#e2e8f0", "#2d3748");
    const cardTextColor = useColorModeValue("black", "white");
    const axisColor = useColorModeValue("#000000", "#ffffff"); // Nova cor para os eixos
    const gridColor = useColorModeValue("#ccc", "#4A5568"); // Nova cor para o grid


    useEffect(() => {
        const fetchKairiData = async () => {
            setLoadingKairi(true);
            try {
                const response = await axios.get('https://api.fatcat.app.br/kairi');
                setKairiData(response.data);

                const sectorList = [...new Set(response.data.map(item => item.sector))];
                setSectors(sectorList);
                setSelectedSector(sectorList[0] || '');
            } catch (error) {
                console.error('Erro ao buscar dados do KAIRI:', error);
            } finally {
                setLoadingKairi(false);
            }
        };

        fetchKairiData();
    }, []);

    useEffect(() => {
        if (kairiData && kairiData.length > 0) {
            let data = kairiData;

            if (selectedSector) {
                data = data.filter(item => item.sector === selectedSector);
            }

            if (selectedSymbols.length > 0) {
                data = data.filter(item => selectedSymbols.includes(item.ticker));
            }

            setFilteredData(data);
        }
    }, [selectedSymbols, selectedSector, kairiData]);

    const handleCheckboxChange = (symbol) => {
        if (selectedSymbols.includes(symbol)) {
            setSelectedSymbols(prevSelected => prevSelected.filter(item => item !== symbol));
        } else {
            if (selectedSymbols.length < 20) {
                setSelectedSymbols(prevSelected => [...prevSelected, symbol]);
            } else {
                toast({
                    title: "Limite de seleção atingido.",
                    description: "Você só pode selecionar até 20 ativos para exibição no gráfico.",
                    status: "warning",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedData = filteredData ? [...filteredData].sort((a, b) => {
        const aValue = parseFloat(a[sortConfig.key]);
        const bValue = parseFloat(b[sortConfig.key]);

        if (aValue < bValue) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    }) : [];

    // Contagem para o card "Acima das Volatilidades"
    const countAboveSector = kairiData ? kairiData.filter(item =>
        item.kairiAtualAtivo >= Math.abs(item.volHistSetor) / 2
    ).length : 0;

    // Contagem para o card "Abaixo das Volatilidades"
    const countBelowSector = kairiData ? kairiData.filter(item =>
        item.kairiAtualAtivo <= -(Math.abs(item.volHistSetor) / 2)
    ).length : 0;

    const countNearSector = kairiData ? kairiData.filter(item => {
        const kairiAbs = Math.abs(item.kairiAtualAtivo);
        const volSetorHalf = Math.abs(item.volHistSetor / 2);
        const volAtivoHalf = Math.abs(item.volHistAtivo / 2);
        const threshold = Math.min(volSetorHalf, volAtivoHalf) * 0.05;
        const diffSetor = Math.abs(kairiAbs - volSetorHalf);
        const diffAtivo = Math.abs(kairiAbs - volAtivoHalf);

        return Math.min(diffSetor, diffAtivo) <= threshold;
    }).length : 0;

    const openModalWithCondition = (condition) => {
        if (cliente === 0) {
            onProOpen(); // Abre o modal de assinatura para clientes não Pro
            return;
        }

        let dataToShow = [];
        if (condition === 'above') {
            dataToShow = kairiData.filter(item =>
                item.kairiAtualAtivo >= Math.abs(item.volHistSetor) / 2
            );
        } else if (condition === 'below') {
            dataToShow = kairiData.filter(item =>
                item.kairiAtualAtivo <= -(Math.abs(item.volHistSetor) / 2)
            );
        } else if (condition === 'near') {
            dataToShow = kairiData.filter(item => {
                const kairiAbs = Math.abs(item.kairiAtualAtivo);
                const volSetorHalf = Math.abs(item.volHistSetor / 2);
                const volAtivoHalf = Math.abs(item.volHistAtivo / 2);
                const threshold = Math.min(volSetorHalf, volAtivoHalf) * 0.05;
                return Math.abs(kairiAbs - Math.min(volSetorHalf, volAtivoHalf)) <= threshold;
            });
        }

        setModalData(dataToShow);
        onOpen();
    };

    const CustomTooltip = ({ active = false, payload = [], label = "", cliente }) => {
        if (active && payload && payload.length) {
            const ativo = payload[0]?.payload;

            // Garante que os dados do ativo estão presentes
            if (!ativo) return null;

            const kairiAbs = Math.abs(ativo.kairiAtualAtivo || 0);
            const volSetorHalf = Math.abs(ativo.volHistSetor / 2 || 0);
            const isKairiAboveAverage = kairiAbs > volSetorHalf;

            // Verifica se o nome do ativo deve ser ocultado
            const isRestricted = cliente === 0 && isKairiAboveAverage;

            return (
                <Box bg="rgba(0, 0, 0, 0.8)" color="white" p={3} borderRadius="md">
                    <Text>
                        Ticker: {isRestricted ? "***" : ativo.ticker}
                    </Text>
                    <Text>Kairi Atual (Ativo): {Number(ativo.kairiAtualAtivo || 0).toFixed(4)}</Text>
                    <Text>Volatilidade Histórica (Ativo): {Number(ativo.volHistAtivo / 2 || 0).toFixed(4)}%</Text>
                    <Text>Volatilidade Histórica (Setor): {Number(ativo.volHistSetor / 2 || 0).toFixed(4)}%</Text>
                    <Text>Kairi Médio (Setor): {Number(ativo.kairiMedioSetor || 0).toFixed(4)}</Text>
                    <Text color={isKairiAboveAverage ? "green.300" : "red.300"}>
                        |Kairi Atual| {isKairiAboveAverage ? "acima" : "abaixo"} das volatilidades por {Math.abs(((kairiAbs - volSetorHalf) / volSetorHalf) * 100).toFixed(2)}%
                    </Text>
                </Box>
            );
        }
        return null;
    };

    const handleStockSelect = (symbol) => {
        setSelectTicker(symbol);

    };

    // Função para formatar números com duas casas decimais
    const formatNumber = (value) => {
        const num = parseFloat(value);
        return isNaN(num) ? '0.00' : num.toFixed(2);
    };

    const MetricCard = ({ icon: Icon, label, value, color, onClick }) => (
        <Card
            boxShadow="lg"
            borderRadius="xl"
            onClick={onClick}
            cursor="pointer"
            transition="0.3s"
            _hover={{ transform: 'scale(1.05)', boxShadow: '2xl' }}
            bg={cardBg} color={cardTextColor}
        >
            <CardBody>
                <Stat>
                    <Flex align="center" mb={3}>
                        <Box as={Icon} w={6} h={6} mr={3} color={color} />
                        <StatLabel fontSize="lg">{label}</StatLabel>
                    </Flex>
                    <StatNumber fontSize="2xl" color={color}>{value}</StatNumber>
                </Stat>
            </CardBody>
        </Card>
    );


    return (
        <Box p={4} position="relative" width="100%" height="100%">

            <Box position="relative" zIndex="1">
                {/* Heading and Video Link */}
                <Flex justifyContent="space-between" alignItems="center" mb={4}>
                    <Heading as="h2" size="lg">Gráfico de KAIRI Ritzu</Heading>
                    <Box
                        as="button"
                        onClick={openVideo}
                        cursor="pointer"
                        fontSize="sm"
                        color="blue.600"
                        _hover={{ textDecoration: 'underline' }}
                        display="flex"
                        alignItems="center"
                    >
                        <InfoIcon mr={2} />
                        Assista ao Vídeo
                    </Box>
                </Flex>

                <Flex justifyContent="center" alignItems="center" mb={4} gap="6">

                    <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={6} mb={6}>
                        <MetricCard
                            icon={ArrowUpIcon}
                            label="Acima das Volatilidades"
                            value={countAboveSector}
                            color="green.500"
                            onClick={() => openModalWithCondition('above')}
                        />
                        <MetricCard
                            icon={ArrowDownIcon}
                            label="Abaixo das Volatilidades"
                            value={countBelowSector}
                            color="red.500"
                            onClick={() => openModalWithCondition('below')}
                        />
                        <MetricCard
                            icon={InfoOutlineIcon}
                            label="Próximos"
                            value={countNearSector}
                            color="blue.500"
                            onClick={() => openModalWithCondition('near')}
                        />
                    </Grid>

                </Flex>

                <Modal isOpen={isProOpen} onClose={onProClose} isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Acesso Restrito</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Text>
                                Este recurso está disponível apenas para assinantes do Fatcat Pro.
                                Assine agora por R$ 47/mês e tenha acesso a tudo, com garantia de 14 dias.
                            </Text>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" onClick={() => window.open('https://fatcat.app.br/pro', '_blank')}>
                                Assinar Agora
                            </Button>
                            <Button variant="ghost" onClick={onProClose}>Fechar</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>


                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                    <ModalOverlay />
                    <ModalContent maxWidth="90%" width="800px">
                        <ModalHeader>Detalhes dos Ativos</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <TableContainer>
                                <Table variant="simple" size="sm">
                                    <Thead>
                                        <Tr>
                                            <Th>Ativo</Th>
                                            <Th>Setor</Th>
                                            <Th isNumeric>|Kairi Atual|</Th>
                                            <Th isNumeric>Vol. Ativo/2</Th>
                                            <Th isNumeric>Vol. Setor/2</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {modalData.map((item) => (
                                            <Tr
                                                key={item.ticker}
                                                onClick={() => {
                                                    setSelectedSector(item.sector);
                                                    onClose(); // Fecha o modal ao clicar no ativo
                                                }}
                                                cursor="pointer"
                                                _hover={{ backgroundColor: "gray.600", color: "white" }}
                                            >
                                                <Td>{item.ticker}</Td>
                                                <Td>{item.sector}</Td>
                                                <Td isNumeric>{Math.abs(item.kairiAtualAtivo).toFixed(4)}</Td>
                                                <Td isNumeric>{(item.volHistAtivo / 2).toFixed(4)}</Td>
                                                <Td isNumeric>{(item.volHistSetor / 2).toFixed(4)}</Td>
                                            </Tr>
                                        ))}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </ModalBody>
                        <ModalFooter>
                            <Button colorScheme="blue" mr={3} onClick={onClose}>
                                Fechar
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                {/* Modal for Video */}
                <Modal isOpen={videoIsOpen} onClose={closeVideo} size="xl" isCentered>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Assista ao Vídeo</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Box
                                as="iframe"
                                src="https://www.youtube.com/embed/riY_1gwSB54?si=bo3d04h5UF0_zCth"
                                width="100%"
                                height="400px"
                                allowFullScreen
                                frameBorder="0"
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={closeVideo}>Fechar</Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>

                <Flex justifyContent="center" alignItems="center" mb={4} gap="6">

                    <Box display="flex" gap="10px" mb={2}>
                        <Select
                            placeholder="Selecione o Setor"
                            value={selectedSector}
                            onChange={(e) => setSelectedSector(e.target.value)}
                        >
                            {sectors.map((sector, index) => (
                                <option key={index} value={sector}>
                                    {sector}
                                </option>
                            ))}
                        </Select>
                    </Box>

                </Flex>

                {loadingKairi ? (
                    <Skeleton height="400px" />
                ) : (

                    <Card bg={cardBg} color={cardTextColor} boxShadow="lg">
                        <CardHeader>
                            <Heading size="md">Gráfico de KAIRI</Heading>
                        </CardHeader>
                        <CardBody>
                            <Box h="400px">
                                <ResponsiveContainer>
                                    <BarChart data={sortedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid
                                            stroke={gridColor}
                                            strokeDasharray="3 3"
                                            strokeWidth={0.5}
                                            opacity={0.3}
                                        />
                                        <XAxis
                                            dataKey="ticker"
                                            tick={{ fill: cardTextColor, fontSize: "12px" }}
                                            stroke={axisColor}
                                            tickFormatter={(value, index) => {
                                                const entry = sortedData[index];
                                                const isRestricted = cliente === 0 && Math.abs(entry.kairiAtualAtivo) > Math.abs(entry.volHistSetor / 2);
                                                return isRestricted ? "***" : value;
                                            }}
                                        />
                                        <YAxis
                                            tick={{ fill: cardTextColor, fontSize: "12px" }}
                                            stroke={axisColor}
                                            domain={[
                                                (dataMin) => -Math.max(...sortedData.map(item => Math.abs(item.volHistSetor / 2) * 1.3), Math.abs(dataMin)),
                                                (dataMax) => Math.max(...sortedData.map(item => (item.volHistSetor / 2) * 1.3), dataMax),
                                            ]}
                                            tickFormatter={(value) => value.toFixed(2)}
                                        />
                                        <Tooltip
                                            content={<CustomTooltip cliente={cliente} restrictedData={sortedData} />}
                                            cursor={{ fill: 'transparent' }}
                                        />
                                        <ReferenceLine
                                            y={sortedData[0]?.volHistSetor / 2}
                                            stroke="#805ad5"
                                            strokeDasharray="3 3"
                                            label={{
                                                value: (sortedData[0]?.volHistSetor / 2).toFixed(2),
                                                position: "left",
                                                fill: "#805ad5",
                                                fontSize: 13,
                                            }}
                                        />
                                        <ReferenceLine
                                            y={-(sortedData[0]?.volHistSetor / 2)}
                                            stroke="#48bb78"
                                            strokeDasharray="3 3"
                                            label={{
                                                value: (-(sortedData[0]?.volHistSetor / 2)).toFixed(2),
                                                position: "left",
                                                fill: "#48bb78",
                                                fontSize: 13,
                                            }}
                                        />
                                        <ReferenceLine y={0} stroke={cardTextColor} strokeDasharray="3 3" />
                                        <Bar dataKey="kairiAtualAtivo" 
                                             stackId="a" name="Kairi Atual (Ativo)"                                         
                                             onClick={(data) => handleStockSelect(data.ticker)}
                                             cursor="pointer">
                                            <LabelList
                                                dataKey="kairiAtualAtivo"
                                                position="inside"
                                                formatter={formatNumber}
                                                style={{ fontSize: 11, fill: cardTextColor }}
                                            />
                                            {sortedData.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={parseFloat(entry.kairiAtualAtivo).toFixed(2) >= 0 ? "#48bb78" : "#805ad5"} />
                                            ))}
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                        </CardBody>
                    </Card>


                )}

                <Card bg={cardBg} color={cardTextColor} boxShadow="lg" mt={6} mb={6}>
                    <CardHeader>
                        <Heading size="md">Detalhamento dos Ativos</Heading>
                    </CardHeader>
                    <CardBody>
                        <TableContainer>
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th cursor="pointer" onClick={() => handleSort('ticker')}>
                                            Ativo {sortConfig.key === 'ticker' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                                        </Th>
                                        <Th isNumeric cursor="pointer" onClick={() => handleSort('kairiAtualAtivo')}>
                                            |Kairi Atual| {sortConfig.key === 'kairiAtualAtivo' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                                        </Th>
                                        <Th isNumeric cursor="pointer" onClick={() => handleSort('volHistAtivo')}>
                                            Vol. Ativo/2 {sortConfig.key === 'volHistAtivo' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                                        </Th>
                                        <Th isNumeric cursor="pointer" onClick={() => handleSort('volHistSetor')}>
                                            Vol. Setor/2 {sortConfig.key === 'volHistSetor' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                                        </Th>
                                        <Th isNumeric>Status</Th>
                                        <Th isNumeric>Diferença (%)</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {filteredData.map((item) => {
                                        const kairiAbs = Math.abs(item.kairiAtualAtivo);
                                        const volSetorHalf = Math.abs(item.volHistSetor / 2);
                                        const volAtivoHalf = Math.abs(item.volHistAtivo / 2);
                                        const maxVol = Math.max(volSetorHalf, volAtivoHalf);
                                        const percentageDiff = ((kairiAbs - volSetorHalf) / volSetorHalf) * 100;

                                        // Definir o status com base nas condições
                                        let status;
                                        if (kairiAbs < volSetorHalf) {
                                            status = "Neutro";
                                        } else if (item.kairiAtualAtivo > 0 && kairiAbs > volSetorHalf) {
                                            status = "Venda";
                                        } else if (item.kairiAtualAtivo < 0 && kairiAbs > volSetorHalf) {
                                            status = "Compra";
                                        }

                                        // Definir a cor do status com base no valor
                                        const statusColor = status === "Venda" ? "red.300" : status === "Compra" ? "green.300" : "gray.500";

                                        // Estilizar a linha para clientes não Pro
                                        const isRestricted = cliente === 0 && (status === "Venda" || status === "Compra");

                                        return (
                                            <Tr
                                                key={item.ticker}
                                                style={{
                                                    opacity: isRestricted ? 0.5 : 1,
                                                    filter: isRestricted ? "blur(3px)" : "none",
                                                    cursor: isRestricted ? "not-allowed" : "pointer",
                                                }}
                                                onClick={() => {
                                                    if (isRestricted) {
                                                        // Mostrar modal de assinatura
                                                        onProOpen();
                                                    }
                                                    handleStockSelect(item.ticker)
                                                    
                                                }}
                                            >
                                                <Td>{item.ticker}</Td>
                                                <Td isNumeric>{kairiAbs.toFixed(2)}</Td>
                                                <Td isNumeric>{volAtivoHalf.toFixed(2)}</Td>
                                                <Td isNumeric>{volSetorHalf.toFixed(2)}</Td>
                                                <Td isNumeric color={statusColor}>{status}</Td>
                                                <Td
                                                    isNumeric
                                                    color={
                                                        Math.abs(percentageDiff) < 5
                                                            ? "blue.400"
                                                            : percentageDiff > 0
                                                                ? "green.300"
                                                                : "red.300"
                                                    }
                                                >
                                                    {Math.abs(percentageDiff).toFixed(2)}%
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </CardBody>
                </Card>
            </Box>
        </Box>
    );
};

export default KairiList;
