import React, { useMemo } from "react";
import { Table, Thead, Tbody, Tr, Th, Td, Badge, useColorModeValue } from "@chakra-ui/react";

const OptionsGrid = ({ lastPrice, spotPrice, optionData, stockData, onBidAskClick }) => {
  // Cores baseadas no tema
  const headerBg = useColorModeValue("gray.200", "gray.700");
  const textColor = useColorModeValue("black", "white");
  const colStrike = useColorModeValue("#3D5064", "#b2f5ea");
  const textColorStrike = useColorModeValue("white", "black");

  // Separar opções CALL e PUT
  const { callOptions, putOptions } = useMemo(() => {
    const callOptions = optionData.filter(option => option.category === "CALL");
    const putOptions = optionData.filter(option => option.category === "PUT");
    return { callOptions, putOptions };
  }, [optionData]);

  // Mesclar opções CALL e PUT pelo strike
  const mergedOptions = useMemo(() => {
    return callOptions.map(call => {
      const put = putOptions.find(p => p.strike === call.strike);
      return { call, put };
    });
  }, [callOptions, putOptions]);

  const getDisplaySymbol = (symbol) => symbol.replace(/^BOVAH|^BOVAW|^BOVAP/, '');

  const getClosestStrike = (options, spotPrice) => {
    if (!options || options.length === 0) return null;
    let closestStrike = options[0].call?.strike;
    let minDifference = Math.abs(options[0].call?.strike - spotPrice);
    options.forEach(option => {
      if (!option.call) return;
      const difference = Math.abs(option.call.strike - spotPrice);
      if (difference < minDifference) {
        closestStrike = option.call.strike;
        minDifference = difference;
      }
    });
    return closestStrike;
  };

  const closestStrike = getClosestStrike(mergedOptions, spotPrice);

  // Função para buscar dados no stockData
  const getStockDataForSymbol = (symbol) => {
    return stockData[symbol] || {};
  };

  const formatNumber = (num) => {
    return (typeof num === 'number' && !isNaN(num)) ? num.toFixed(2) : '-';
  };

  return (
    <div className="options-grid-container" style={{ paddingRight: "20px", paddingLeft: "10px" }}>
      <div className="options-grid-header">
        <Table variant="simple" size="12px">
          <Thead bg={headerBg}>
            <Tr>
              <Th p={1} fontSize="12px" color={textColor}>Código</Th>
              <Th p={1} fontSize="12px" color={textColor}>Último</Th>
              <Th p={1} fontSize="12px" color={textColor}>Teórico</Th>
              <Th p={1} fontSize="12px" color={textColor}>Delta</Th>
              <Th p={1} fontSize="12px" color={textColor}>Vol Fin.</Th>
              <Th p={1} fontSize="12px" color={textColor}>Var</Th>
              <Th p={1} fontSize="12px" color={textColor}>Mid</Th>
              <Th p={1} fontSize="12px" color={textColor}>Bid</Th>
              <Th p={1} fontSize="12px" color={textColor}>Ask</Th>
              <Th p={1} fontSize="12px" color={textColor}>Strike</Th>
              <Th p={1} fontSize="12px" color={textColor}>Bid</Th>
              <Th p={1} fontSize="12px" color={textColor}>Ask</Th>
              <Th p={1} fontSize="12px" color={textColor}>Mid</Th>
              <Th p={1} fontSize="12px" color={textColor}>Var</Th>
              <Th p={1} fontSize="12px" color={textColor}>Vol Fin.</Th>
              <Th p={1} fontSize="12px" color={textColor}>Delta</Th>
              <Th p={1} fontSize="12px" color={textColor}>Teórico</Th>
              <Th p={1} fontSize="12px" color={textColor}>Último</Th>
              <Th p={1} fontSize="12px" color={textColor}>Código</Th>
            </Tr>
          </Thead>
        </Table>
      </div>
      <div className="options-grid-body">
        <Table variant="simple" size="12px">
          <Tbody>
            {mergedOptions.map((option, index) => {
              const callStockData = getStockDataForSymbol(option.call?.symbol);
              const putStockData = getStockDataForSymbol(option.put?.symbol);

              return (
                <Tr key={index} bg={option.call?.strike === lastPrice ? "green.200" : "transparent"} borderBottom="1px solid #1a202c" borderColor='GrayText'>
                  <Td p={1} fontSize="12px" color={textColor}>{option.call ? getDisplaySymbol(option.call.symbol.slice(4)) : '-'}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{option.call?.value || '-'}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{option.call ? option.call.theoretical : '-'}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{option.call ? option.call.delta : '-'}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{option.call?.financialVol || '-'}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{option.call?.variation || '-'}</Td>
                  <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.call.mid) || '-'}</Td>
                  
                  {/* Bid da Call */}
                  <Td p={1} fontSize="12px" color={textColor}>
                    {option.call?.bid ? (
                      <Badge
                        colorScheme="red"
                        borderRadius="md"
                        p={1}
                        fontSize="12px"
                        onClick={() => onBidAskClick(option.call, 'call', 'bid')}
                        cursor="pointer"
                      >
                        {option.call.bid}
                      </Badge>
                    ) : '-'}
                  </Td>
                  
                  {/* Ask da Call */}
                  <Td p={1} fontSize="12px" color={textColor}>
                    {option.call?.ask ? (
                      <Badge
                        colorScheme="green"
                        borderRadius="md"
                        p={1}
                        fontSize="12px"
                        onClick={() => onBidAskClick(option.call, 'call', 'ask')}
                        cursor="pointer"
                      >
                        {option.call.ask}
                      </Badge>
                    ) : '-'}
                  </Td>
                  
                  <Td p={1} backgroundColor={option.call?.strike === closestStrike ? "#A2AEBA" : colStrike} alignItems="center" color={textColorStrike} textAlign="center" fontSize="12px">
                    {option.call ? option.call.strike : '-'}
                  </Td>
                  
                  {option.put ? (
                    <>
                      {/* Bid da Put */}
                      <Td p={1} fontSize="12px" color={textColor}>
                        {option.put?.bid ? (
                          <Badge
                            colorScheme="red"
                            borderRadius="md"
                            p={1}
                            fontSize="12px"
                            onClick={() => onBidAskClick(option.put, 'put', 'bid')}
                            cursor="pointer"
                          >
                            {option.put?.bid || '-'}
                          </Badge>
                        ) : '-'}
                      </Td>
                      
                      {/* Ask da Put */}
                      <Td p={1} fontSize="12px" color={textColor}>
                        {option.put?.ask ? (
                          <Badge
                            colorScheme="green"
                            borderRadius="md"
                            p={1}
                            fontSize="12px"
                            onClick={() => onBidAskClick(option.put, 'put', 'ask')}
                            cursor="pointer"
                          >
                            {option.put?.ask || '-'}
                          </Badge>
                        ) : '-'}
                      </Td>
                      
                      <Td p={1} fontSize="12px" color={textColor}>{formatNumber(option.put?.mid) || '-'}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{option.put?.variation || '-'}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{option.put?.financialVol || '-'}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{option.put?.delta || '-'}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{option.put?.theoretical || '-'}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{option.put?.value || '-'}</Td>
                      <Td p={1} fontSize="12px" color={textColor}>{getDisplaySymbol(option.put.symbol.slice(4))}</Td>
                    </>
                  ) : (
                    <Td p={1} colSpan={10} />
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

export default OptionsGrid;
