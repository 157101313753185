export const calculateAveragePrice = (transactions, cycle=1, stock) => {
    let totalProfit = 0;
    let totalCost = 0;
    let totalQuantity = 0;
 
    transactions
      .filter((transaction) => transaction.cycle === cycle && transaction.symbol === stock)
      .forEach((transaction) => {
        const quantity = parseInt(transaction.quantity, 10);
        const price = parseFloat(transaction.price);
        const buybackPrice = parseFloat(transaction.buyback_price) || 0;
  
        switch (transaction.type) {
          case "sell_put":
            totalProfit += (price - buybackPrice) * quantity;
            break;
          case "sell_call":
            totalProfit += price * quantity;
            break;
          case "buy_stock":
            totalCost += price * quantity;
            totalQuantity += quantity;
            break;
          case "sell_stock":
            totalCost -= price * quantity;
            totalQuantity -= quantity;
            break;
          default:
            break;
        }
      });
  
    const averagePrice = totalQuantity ? (totalCost - totalProfit) / totalQuantity : 0;

    return averagePrice;
  };
  

export  const calculateReceivedAmount = (transactions, cycle, stock) => {
    let totalReceived = 0;

    transactions
      .filter((transaction) => transaction.cycle === cycle && transaction.symbol === stock && (transaction.type === "sell_put" || transaction.type === "sell_call"))
      .forEach((transaction) => {
        const quantity = parseInt(transaction.quantity, 10);
        const price = parseFloat(transaction.price);

        totalReceived += price * quantity;
      });

      return totalReceived;
  };

export  const calculatePaidAmount = (transactions, cycle, stock) => {
    let totalPaid = 0;

    transactions
      .filter((transaction) => transaction.cycle === cycle && transaction.symbol === stock && (transaction.type === "sell_put" || transaction.type === "sell_call"))
      .forEach((transaction) => {
        const buybackPrice = parseFloat(transaction.buyback_price) || 0;
        const quantity = parseInt(transaction.quantity, 10);

        totalPaid += buybackPrice * quantity;
      });

    return totalPaid;
  };

export  const calculateCurrentQuantity = (transactions, stock) => {
    let totalQuantity = 0;

    transactions
      .filter((transaction) => transaction.buyback_price === null && transaction.symbol === stock)
      .filter((transaction) => transaction.type === "buy_stock")
      .forEach((transaction) => {
        const quantity = parseInt(transaction.quantity, 10);
        totalQuantity += quantity;
      });


    return totalQuantity;
  };


export const calculateIntrinsicValue = (vpa, lpa) => {
    return Math.sqrt(vpa * lpa * 22.5);
  };

export  const calculatePriceCeiling = (dividends) => {
    return dividends / 0.06;
  };

export  const calculateUpsideValue = (price, vlInstiseco) => {
    return ((vlInstiseco - price) / price) * 100;
  };

