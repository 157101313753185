import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Center,
  Flex,
  Link,
} from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode'; // Importação corrigida
import axios from 'axios';
import { getUser, firstAccess } from '../api/user';
import CustomIcon from './CustomIcon';
import { createAtivos } from '../api/ativos';
import translations from '../util/translations'; // Importação do arquivo de traduções

const API_URL = 'https://api.fatcat.app.br/user';

const createUser = async (dados) => {
  const response = await axios.post(`${API_URL}/register`, dados);
  return response.data;
};

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isRegister, setIsRegister] = useState(false);
  const [language, setLanguage] = useState('pt'); // Idioma padrão
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    description: '',
    whatsapp: '',
  });
  const toast = useToast();

  // Detecta o hash na URL para entrar automaticamente na tela de registro
  useEffect(() => {
    if (window.location.hash === '#register') {
      setIsRegister(true);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error(translations[language].loginFailedDescription);
      }

      const data = await response.json();
      const decodedToken = jwtDecode(data.token);
      localStorage.setItem('token', data.token);
      localStorage.setItem('userId', decodedToken.id);

      // Verifica se é o primeiro acesso
      const user = await getUser(decodedToken.id);
      if (user.firstAccess) {
        const newPassword = prompt(
          translations[language].firstAccessPrompt || 'Por favor, defina uma nova senha:'
        );
        if (newPassword) {
          await firstAccess(user.email, newPassword);
          toast({
            title: translations[language].passwordChanged,
            description: translations[language].passwordChangedDescription || 'Senha alterada com sucesso!',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
          await createInitialAssets(decodedToken.id);
        } else {
          throw new Error(translations[language].passwordRequired || 'A senha é obrigatória no primeiro acesso.');
        }
      }

      onLogin(data.token);

      toast({
        title: translations[language].loginSuccess,
        description: translations[language].loginSuccessDescription,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: translations[language].loginFailed,
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const createInitialAssets = async (userId) => {
    const assets = [
      { symbol: 'BBAS3', bolsa: 'STOCK', idUser: userId, listId: 0 },
      { symbol: 'PETR4', bolsa: 'STOCK', idUser: userId, listId: 0 },
      { symbol: 'USIM5', bolsa: 'STOCK', idUser: userId, listId: 0 },
      { symbol: 'VALE3', bolsa: 'STOCK', idUser: userId, listId: 0 },
      { symbol: 'EMBR3', bolsa: 'STOCK', idUser: userId, listId: 0 },
      { symbol: 'ITSA3', bolsa: 'STOCK', idUser: userId, listId: 0 },
      { symbol: 'BBDC4', bolsa: 'STOCK', idUser: userId, listId: 0 },
      { symbol: 'SUZB3', bolsa: 'STOCK', idUser: userId, listId: 0 },
    ];

    try {
      for (const asset of assets) {
        await createAtivos(asset);
      }
      console.log('Initial assets created successfully.');
    } catch (error) {
      console.error('Failed to create initial assets:', error);
    }
  };

  const handleSubmitRegister = async (e) => {
    e.preventDefault();
    try {
      const newUser = await createUser(formData);
      toast({
        title: translations[language].userCreated,
        description: translations[language].userCreatedDescription,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      await createInitialAssets(newUser.id);

      setFormData({
        username: '',
        email: '',
        password: '',
        description: '',
        whatsapp: '',
      });
      setIsRegister(false);
    } catch (error) {
      console.error('Failed to create user:', error);
      toast({
        title: translations[language].errorOccurred,
        description: translations[language].errorOccurredDescription,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex minH="100vh" align="center" justify="center" bgGradient="linear(to-r, teal.400, blue.500)">
      <Box
        bg="white"
        p={8}
        maxW="md"
        borderWidth={1}
        borderRadius="lg"
        boxShadow="lg"
        w="full"
      >
        <Center mb={2}>
          <CustomIcon fill="#1DA5CA" width="80px" height="80px" />
        </Center>
        <Heading mb={2} textAlign="center" color="teal.600">
          {isRegister ? translations[language].register : translations[language].welcome}
        </Heading>
        {isRegister ? (
          <form onSubmit={handleSubmitRegister}>
            <VStack spacing={4}>
              <FormControl id="username" isRequired>
                <FormLabel color="gray.800">{translations[language].username}</FormLabel>
                <Input
                  name="username"
                  value={formData.username}
                  onChange={handleChange}
                  bg="gray.100"
                  border={0}
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ bg: 'gray.200', borderColor: 'teal.400' }}
                  color="gray.800"
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel color="gray.800">{translations[language].email}</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  bg="gray.100"
                  border={0}
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ bg: 'gray.200', borderColor: 'teal.400' }}
                  color="gray.800"
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel color="gray.800">{translations[language].password}</FormLabel>
                <Input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  bg="gray.100"
                  border={0}
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ bg: 'gray.200', borderColor: 'teal.400' }}
                  color="gray.800"
                />
              </FormControl>
              <FormControl id="description">
                <FormLabel color="gray.800">{translations[language].description}</FormLabel>
                <Input
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  bg="gray.100"
                  border={0}
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ bg: 'gray.200', borderColor: 'teal.400' }}
                  color="gray.800"
                />
              </FormControl>
              <FormControl id="whatsapp">
                <FormLabel color="gray.800">{translations[language].whatsapp}</FormLabel>
                <Input
                  name="whatsapp"
                  value={formData.whatsapp}
                  onChange={handleChange}
                  bg="gray.100"
                  border={0}
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ bg: 'gray.200', borderColor: 'teal.400' }}
                  color="gray.800"
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" width="full">
                {translations[language].registerButton}
              </Button>
              <Link color="teal.500" onClick={() => setIsRegister(false)}>
                {translations[language].alreadyHaveAccount}
              </Link>
            </VStack>
          </form>
        ) : (
          <form onSubmit={handleSubmitLogin}>
            <VStack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel color="gray.700">{translations[language].email}</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  bg="gray.100"
                  border={0}
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ bg: 'gray.200', borderColor: 'teal.400' }}
                  color="gray.800"
                />
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel color="gray.700">{translations[language].password}</FormLabel>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  bg="gray.100"
                  border={0}
                  _hover={{ bg: 'gray.200' }}
                  _focus={{ bg: 'gray.200', borderColor: 'teal.400' }}
                  color="gray.800"
                />
              </FormControl>
              <Button
                type="submit"
                width="full"
                bgGradient="linear(to-r, teal.500, blue.500)"
                color="white"
                _hover={{ bgGradient: 'linear(to-r, teal.600, blue.600)' }}
              >
                Login
              </Button>
              <Link color="teal.500" onClick={() => setIsRegister(true)}>
                {translations[language].dontHaveAccount}
              </Link>
            </VStack>
          </form>
        )}
      </Box>
    </Flex>
  );
};

export default Login;
