import React, { useState } from 'react';
import {
  Box,
  InputGroup,
  Input,
  InputRightElement,
  List,
  ListItem,
  Portal,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

const SymbolSearch = ({ onSelect, tickers=[], initialSymbol='', largura="300px"}) => {
  const [searchTerm, setSearchTerm] = useState(initialSymbol || ''); // Configura o valor inicial
  const [filteredStocks, setFilteredStocks] = useState([]);
  const [inputRef, setInputRef] = useState(null);

  const searchBgColor = useColorModeValue("white", "gray.700");
  const searchHoverBgColor = useColorModeValue("gray.100", "gray.600");

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.trim() !== '') {
      const filtered = tickers.filter((symbol) =>
        symbol.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredStocks(filtered);
    } else {
      setFilteredStocks([]);
    }
  };

  const handleSelectStock = (symbol) => {
    setSearchTerm(symbol);
    setFilteredStocks([]);
    onSelect(symbol);
  };

  return (
    <InputGroup maxW="100%" position="relative" width={largura} marginLeft={2} marginRight={2}>
      <Input
        placeholder="Buscar ativo..."
        value={searchTerm || ''}
        onChange={handleSearch}
        ref={(ref) => setInputRef(ref)} // Captura referência ao Input
      />
      <InputRightElement children={<FaSearch />} />
      {filteredStocks.length > 0 && inputRef && (
        <Portal>
          <Box
            position="absolute"
            top={`${inputRef.getBoundingClientRect().bottom + window.scrollY}px`}
            left={`${inputRef.getBoundingClientRect().left}px`}
            width={`${inputRef.offsetWidth}px`}
            bg={searchBgColor}
            boxShadow="md"
            borderRadius="md"
            maxH="200px"
            overflowY="auto"
            zIndex="9999" // Prioridade alta
            border="1px solid"
            borderColor="gray.300"
          >
            <List spacing={0}>
              {filteredStocks.map((symbol) => (
                <ListItem
                  key={symbol}
                  p={2}
                  _hover={{ bg: searchHoverBgColor }}
                  cursor="pointer"
                  onClick={() => handleSelectStock(symbol)}
                >
                  {symbol}
                </ListItem>
              ))}
            </List>
          </Box>
        </Portal>
      )}
    </InputGroup>
  );
};

export default SymbolSearch;
