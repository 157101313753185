import React from 'react';
import {
  Box,
  Text,
  Heading,
  Divider,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';

const AnalysisText = ({ content }) => {
  const bgHighlight = useColorModeValue('blue.50', 'blue.900');
  const textColor = useColorModeValue('gray.800', 'white');
  
  const formatText = (text) => {
    if (!text) return null;

    const sections = text.split(/(\*\*.*?\*\*)/g);
    
    return sections.map((section, index) => {
      if (section.startsWith('**') && section.endsWith('**')) {
        // Seção de título
        const title = section.replace(/\*\*/g, '');
        return (
          <Text
            key={index}
            fontWeight="bold"
            fontSize="lg"
            my={4}
            p={2}
            bg={bgHighlight}
            borderRadius="md"
            color={textColor}
          >
            {title}
          </Text>
        );
      }
      
      // Texto normal - divide em parágrafos
      return section.split('\n').map((line, lineIndex) => {
        if (!line.trim()) return null;
        return (
          <Text 
            key={`${index}-${lineIndex}`} 
            my={2}
            color={textColor}
          >
            {line}
          </Text>
        );
      });
    });
  };

  return (
    <Box p={4} borderRadius="md" borderWidth="1px">
      {formatText(content)}
    </Box>
  );
};

const AnalysisSection = ({ analysis, selectedStock }) => {
  const tableBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const getPOEColor = (poe) => {
    if (poe > 40) return "green";
    if (poe > 20) return "yellow";
    return "red";
  };

  return (
    <VStack spacing={6} align="stretch" width="100%">
      <Box>
        <Heading size="md" mb={2}>Informações Gerais</Heading>
        <Divider mb={4} />
        <Text mb={2}><strong>Ativo:</strong> {selectedStock}</Text>
        <Text>
          <strong>Recomendação:</strong>{" "}
          <Badge colorScheme={analysis.recommendation === "Compra" ? "green" : "red"}>
            {analysis.recommendation}
          </Badge>
        </Text>
      </Box>

      <Box>
        <Heading size="md" mb={2}>Melhores Opções</Heading>
        <Divider mb={4} />
        <Box overflowX="auto">
          <Table variant="simple" size="sm" bg={tableBg} borderWidth="1px" borderColor={borderColor}>
            <Thead>
              <Tr>
                <Th>Opção</Th>
                <Th isNumeric>Strike</Th>
                <Th isNumeric>Delta</Th>
                <Th isNumeric>Theta</Th>
                <Th isNumeric>Venc.</Th>
                <Th isNumeric>POE (%)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {analysis.best_options.map((option, index) => (
                <Tr key={index}>
                  <Td fontWeight="medium">{option.symbol}</Td>
                  <Td isNumeric>{option.strike}</Td>
                  <Td isNumeric>{option.delta.toFixed(4)}</Td>
                  <Td isNumeric>{option.theta.toFixed(4)}</Td>
                  <Td isNumeric>{option.days_to_maturity}</Td>
                  <Td isNumeric>
                    <Badge colorScheme={getPOEColor(option.poe)}>
                      {option.poe}
                    </Badge>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </Box>

      <Box>
        <Heading size="md" mb={2}>Análise da IA</Heading>
        <Divider mb={4} />
        <AnalysisText content={analysis.ai_analysis} />
      </Box>
    </VStack>
  );
};

export default AnalysisSection;