import axios from 'axios';

const API_URL = 'https://api.fatcat.app.br/suggestion/';

export const getSuggestions = async (userId) => {
  const response = await axios.get(`${API_URL}?userId=${userId}`);
  return response.data;
};

export const createSuggestion = async (suggestion) => {
  const response = await axios.post(API_URL + 'addSuggestion', suggestion);
  return response.data;
};

export const updateSuggestion = async (id, suggestion) => {
  const response = await axios.put(`${API_URL}${id}/`, suggestion);
  return response.data;
};

export const deleteSuggestion = async (id) => {
  const response = await axios.delete(`${API_URL}${id}/`);
  return response.data;
};
