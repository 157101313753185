import React, { useState, useEffect, useRef, forwardRef } from 'react';
import {
  Flex, HStack, IconButton, Avatar, Tooltip, Box,
  Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, DrawerFooter,
  Button, useDisclosure, Spinner, Popover, PopoverTrigger, PopoverContent, PopoverArrow,
  PopoverCloseButton, PopoverHeader, PopoverBody, AlertDialog, AlertDialogOverlay,
  AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter,
  useColorMode, useColorModeValue, Table, Thead, Text, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  VStack,
  Heading, Divider,
  ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Modal
} from '@chakra-ui/react';
import { FaMagic, FaBurn } from 'react-icons/fa';
import { MdAutoGraph, MdOutlineTableChart } from 'react-icons/md';
import { ArrowForwardIcon, SunIcon, MoonIcon, SettingsIcon, CalendarIcon, ViewIcon, ChevronDownIcon, Select } from '@chakra-ui/icons';
import FearGreedGauge from './FearGreedGauge';
import VixGauge from './VixGauge';
import Profile from './Profile';
import { getUser } from '../api/user';
import TickerBar from './TickerBar';
import SymbolSearch from './SymbolSearch';
import { generateAnalysisHTML } from './generateAnalysisHTML';  // Importando a função
import AnalysisSection from './AnalysisSection';

// Wrap IconButton components with forwardRef
const ForwardRefIconButton = forwardRef((props, ref) => (
  <IconButton ref={ref} {...props} />
));

const TopBar = ({
  userId,
  selectedStock,
  onTabChange,
  onLogout,
  tickers,
  stockData,
  handleSelectStock,
  hasOption,
  cliente,
  renewDate
}) => {
  const bgColor = useColorModeValue("gray.100", "gray.800");
  const textColor = useColorModeValue("black", "white");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const tableBgColor = useColorModeValue('#fff', '#1a202c');
  const tableTextColor = useColorModeValue('black', 'white');
  const aiAnalysisBgColor = useColorModeValue('#F6F6F1', '#2d3748');

  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isProfileOpen, onOpen: onProfileOpen, onClose: onProfileClose } = useDisclosure();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const { isOpen: isPopoverOpen, onOpen: onPopoverOpen, onClose: onPopoverClose } = useDisclosure();
  const searchBgColor = useColorModeValue("white", "gray.700");
  const searchHoverBgColor = useColorModeValue("gray.100", "gray.600");
  const cancelRef = useRef();
  const [analysis, setAnalysis] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [fearGreedValue, setFearGreedValue] = useState(0);
  const [vixValue, setVixValue] = useState(null);  // Novo estado para o VIX
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedStrategy, setSelectedStrategy] = useState(''); // Estado para a estratégia selecionada
  const [dialogSelectedStock, setDialogSelectedStock] = useState(selectedStock || '');

  const confirmAnalyze = () => {
    onAlertOpen();
  };


  const handleStrategyChange = (e) => {
    setSelectedStrategy(e.target.value);
  };

  const handleConfirmAnalyze = async () => {
    if (!selectedStrategy || !dialogSelectedStock) {
      alert('Por favor, selecione uma estratégia antes de confirmar.'); // Validação simples
      return;
    }
    onAlertClose();
    await handleAnalyze(selectedStrategy, dialogSelectedStock);
  };

  const handleAnalyze = async (strategy, symbol) => {
    if (symbol) {
      setIsAnalyzing(true);
  
      const description = userInfo?.description || 'No description available';
  
      try {
        const response = await fetch('https://data.fatcat.app.br/analyze', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            symbol: symbol,
            description: description,
            strategy: strategy,
          }),
        });
        const data = await response.json();
        setAnalysis(data);
        onOpen();
  
        // Preparando os dados para salvar
        const analysisData = {
          ticker: symbol,
          analysis: JSON.stringify(data),
          userId: userId,
          strategy: strategy
        };
  
        await fetch('https://api.fatcat.app.br/analyses/addAnalyze', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(analysisData)
        });
  
      } catch (error) {
        console.error('Error during analysis:', error);
      } finally {
        setIsAnalyzing(false);
      }
    }
  };

  useEffect(() => {
    setDialogSelectedStock(selectedStock || '');
  }, [selectedStock]);

  useEffect(() => {
    console.log(hasOption);
    const fetchFearGreedValue = async () => {
      try {
        const response = await fetch('https://data.fatcat.app.br/ifg');
        const data = await response.json();
        setFearGreedValue(data.ifg);
      } catch (error) {
        console.error('Failed to fetch fear greed value:', error);
      }
    };

    const fetchVixValue = async () => {
      try {
        const response = await fetch('https://data.fatcat.app.br/vix');
        const data = await response.json();
        setVixValue(data.vix);  // Armazenando o valor do VIX no estado
      } catch (error) {
        console.error('Failed to fetch VIX value:', error);
      }
    };

    fetchFearGreedValue();
    fetchVixValue();  // Chamando a nova função para obter o VIX
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const data = await getUser(userId);
        setUserInfo(data);
      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };
    if (userId) {
      fetchUserInfo();
    }
  }, [userId]);

  // Exibe o Popover automaticamente se o cliente for 0
  useEffect(() => {
    if (cliente === 0) {
      onPopoverOpen();
    }
  }, [cliente, onPopoverOpen]);

  return (
    <Flex
      as="header"
      w="100%"
      p={2}
      bg={bgColor}
      color={textColor}
      direction="column"
      boxShadow="sm"
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="10"
      borderBottom="2px solid"
      borderColor={borderColor}
    >
      <Flex justifyContent="space-between" alignItems="center">
        <HStack spacing={1}>
          <Tooltip label="Chart View">
            <ForwardRefIconButton icon={<MdAutoGraph />} onClick={() => onTabChange('chart')} />
          </Tooltip>
          <Tooltip label="Options">
            <ForwardRefIconButton
              icon={<CalendarIcon />}
              onClick={() => onTabChange('options')}
              isDisabled={hasOption !== 1} // Desabilita o botão se hasOption não for 1
            />
          </Tooltip>
          <Tooltip label="Table View">
            <ForwardRefIconButton icon={<MdOutlineTableChart />} onClick={() => onTabChange('table')} />
          </Tooltip>
          <Menu>
            <Tooltip label="Ações">

              <MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
                Actions
              </MenuButton>
            </Tooltip>
            <MenuList>
              <MenuItem onClick={() => onTabChange('jumba')}>Posição B3</MenuItem>
              <MenuItem onClick={() => onTabChange('ivRankList')}>IV Rank List</MenuItem>
              <MenuItem
                onClick={() => onTabChange('taxa')}
                isDisabled={cliente === 0} // Desabilita se cliente for 0
                _disabled={{ cursor: 'not-allowed', opacity: 0.6 }} // Estilo visual para desabilitado
              >
                Taxas
              </MenuItem>
              <MenuItem onClick={() => onTabChange('kairi')}>Kairi</MenuItem>
              <MenuItem onClick={() => onTabChange('scanner')}>Scanner Dashboard</MenuItem>
              <MenuItem onClick={() => onTabChange('aluguel')}>Aluguel de Ações</MenuItem>
              <MenuItem onClick={() => onTabChange('fundamentus')}>Mapa da Mina</MenuItem>
              <MenuItem onClick={() => onTabChange('backtest')}>Backtest</MenuItem>
              <MenuItem onClick={() => onTabChange('90Squeeze')}>90 Squeeze</MenuItem>


              {/* <MenuItem
                onClick={() => onTabChange('backtest')}
                isDisabled={true} // Exemplo fixo de item desabilitado
                _disabled={{ cursor: 'not-allowed', opacity: 0.6 }}
              >
                Backtest
              </MenuItem> */}
            </MenuList>

          </Menu>

          <Tooltip label="Analyze Stock">
            <ForwardRefIconButton
              icon={isAnalyzing ? <Spinner size="xs" /> : <FaMagic />}
              onClick={confirmAnalyze}
              isDisabled={!selectedStock}
            />
          </Tooltip>
        </HStack>

        <SymbolSearch
          tickers={tickers}

          stockData={stockData}
          onSelect={handleSelectStock}
        />

        <TickerBar
          tickers={tickers}
          stockData={stockData}
        />

        <HStack spacing={4} zIndex="10000" position="relative">
          {vixValue && <VixGauge value={vixValue} />}  {/* Substitui o selectedStock?.vix */}
          <FearGreedGauge value={fearGreedValue} />
          {userInfo && (
            <Popover>
              <PopoverTrigger>
                <Box>
                  <Avatar name={userInfo.username} src="https://bit.ly/broken-link" size="md" cursor="pointer" />
                </Box>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>User Info</PopoverHeader>
                <PopoverBody>
                  <div>
                    <div>{userInfo.username}</div>
                    <div>Credits: {userInfo.credits}</div>
                    <div>
                      Renova Em: {renewDate ? new Date(renewDate).toLocaleDateString() : 'No subscription date'}
                      <Button
                        as="a"
                        href="https://pay.kiwify.com.br/Z9etzab"
                        target="_blank"
                        rel="noopener noreferrer"
                        colorScheme="teal"
                        size="sm"
                        ml={2} // Margem à esquerda para dar espaço
                      >
                        Renovar
                      </Button>
                    </div>
                    <Button
                      colorScheme="red"
                      onClick={onLogout}
                      leftIcon={<ArrowForwardIcon />}
                      variant="solid"
                      size="sm"
                      mt={2}
                    >
                      Logout
                    </Button>
                  </div>
                </PopoverBody>
              </PopoverContent>
            </Popover>

          )}

          <HStack spacing={4} zIndex="10000" position="relative">
            {userInfo && (
              <>
                <Modal isOpen={isPopoverOpen} onClose={onPopoverClose} size="sm" isCentered>
                  <ModalOverlay />
                  <ModalContent
                    mt="10" // Ajusta a margem superior para aparecer no canto superior
                    position="absolute"
                    top="5%" // Posição no canto superior da tela
                    left="70%"
                    transform="translate(-50%, -10%)"
                  >
                    <ModalHeader>Importante</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <Text>
                        Você não possui uma assinatura ativa. Considere renovar para continuar acessando todas as funcionalidades.
                      </Text>
                      <Button
                        as="a"
                        href="https://pay.kiwify.com.br/Z9etzab"
                        target="_blank"
                        rel="noopener noreferrer"
                        colorScheme="teal"
                        size="sm"
                        mt={4}
                      >
                        Renovar Agora
                      </Button>
                    </ModalBody>
                  </ModalContent>
                </Modal>
              </>
            )}
          </HStack>

          <ForwardRefIconButton
            aria-label="Toggle Color Mode"
            icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            onClick={toggleColorMode}
          />
          <Tooltip label="Settings">
            <ForwardRefIconButton icon={<SettingsIcon />} onClick={onProfileOpen} />
          </Tooltip>
        </HStack>
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Análise de Ativo</DrawerHeader>

          <DrawerBody>
            {analysis ? (
              <VStack spacing={6} align="stretch">
                <AnalysisSection analysis={analysis} selectedStock={dialogSelectedStock} />
              </VStack>
            ) : (
              <Text>Carregando...</Text>
            )}
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>


      {/* Drawer para o perfil do usuário */}
      <Drawer isOpen={isProfileOpen} placement="right" onClose={onProfileClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Profile</DrawerHeader>

          <DrawerBody>
            <Profile userId={userId} onClose={onProfileClose} />
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onProfileClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* AlertDialog para confirmação de análise */}
      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirmar Análise
            </AlertDialogHeader>
            <AlertDialogBody>
              <VStack spacing={4} align="stretch">
                <Box>
                  <Text mb={2}>Selecione o ativo:</Text>
                  <SymbolSearch
                    tickers={tickers}
                    stockData={stockData}
                    onSelect={(symbol) => setDialogSelectedStock(symbol)}
                    defaultValue={selectedStock} // Adicione esta prop
                    initialValue={selectedStock} // E esta também, dependendo de como seu componente SymbolSearch está implementado
                    placeholder="Buscar ativo..."                  />
                </Box>
                <Box>
                  <Text mb={2}>Selecione a estratégia:</Text>
                  <Select
                    placeholder="Selecione uma estratégia"
                    value={selectedStrategy}
                    onChange={handleStrategyChange}
                  >
                    <option value="Trava de Alta com Put">Trava de Alta com Put</option>
                    <option value="Venda Coberta">Venda Coberta</option>
                    <option value="trava de alta com call">Trava de Alta com Call</option>
                    <option value="trava de baixa com put">Trava de Baixa com Put</option>
                    <option value="straddle">Straddle</option>
                    <option value="strangle">Strangle</option>
                    <option value="the wheel">The Wheel</option>
                    <option value="compra de call curta">Compra de call curta</option>
                    <option value="compra de call longa">Compra de Call Longa</option>
                    <option value="compra de put curta">Compra de Put Curta</option>
                    <option value="compra de put longa">Compra de Put Longa</option>
                    <option value="Borboleta">Borboleta</option>
                    <option value="Zebra">Zebra</option>
                    <option value="jade lizard">Jade Lizard</option>

                </Select>
              </Box>
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onAlertClose}>
              Cancelar
            </Button>
            <Button
              colorScheme="blue"
              onClick={handleConfirmAnalyze}
              ml={3}
              isDisabled={!dialogSelectedStock || !selectedStrategy}
            >
              Confirmar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
    </Flex >
  );
};

export default TopBar;
