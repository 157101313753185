import axios from 'axios';

export const getJumba = async (symbol, nearestDueDate) => {
  try {
    const response = await axios.get(`https://api.fatcat.app.br/jumba/dados/${symbol}/${nearestDueDate}`);
    return response.data;

  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

