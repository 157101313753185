import React, { useState, useEffect, useCallback } from 'react';
import StockOverview from './StockOverview';
import './Workspace.css';
import TradingViewChart from './TradingViewChart';
import SimulationTable from './SimulationTable';
import OptionsGrid from './OptionsGrid';
import DueDateSelector from "./DueDateSelector";
import SimulationFormModal from "./SimulationFormModal";
import Jumba from './Jumba'; // Componente do gráfico
import { useColorMode, useColorModeValue, useToast, IconButton, Menu, MenuButton, MenuList, MenuItem, Box, Spinner, Stack, Skeleton } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import IvRankList from './IvRankList';
import KairiList from './KairiList';
import Scanner from './Scanner';
import AluguelChart from './AluguelChart';
import Fundamentus from './Fundamentus';
import BacktestAnalysis from './BacktestAnalysis';
import SqueezeList from './SqueezeList';
import OptionScanner from './OptionScanner';


function Workspace({
    stock,
    selectedStock,
    selectedTab,
    setSelectTicker,
    setSelectedTab,
    userId,
    stockData = {},
    transactions,
    onAddOrUpdateTransaction,
    onDeleteTransaction,
    optionData,
    isCollapsed,
    loadingOptions,
    onDueDateChange,
    nearestDueDate,
    distinctDueDates,
    tickers,
    handleSelectStock,
    optionDataFull,
    tickersWithOptions,
    cliente,
    lastPrice,
    ...props
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenSeco, setIsModalOpenSeco] = useState(false);

    const [selectedStrategy, setSelectedStrategy] = useState("");
    const [nextCycle, setNextCycle] = useState(1);
    const [cycles, setCycles] = useState([]);
    const [editingTransaction, setEditingTransaction] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null); // Controla a data selecionada
    const [isLoading, setIsLoading] = useState(loadingOptions);
    const [filterOptions, setFilterOptions] = useState([]);

    const [selectedStockState, setSelectedStockState] = useState(selectedStock); // Novo estado



    const { colorMode } = useColorMode();
    const bgColor = useColorModeValue("gray.100", "gray.800");
    const textColor = useColorModeValue("black", "white");

    const toast = useToast();

    // Função para abrir o modal com os dados da opção selecionada
    const handleBidAskClick = (option, type, side) => {
        const strategy = side === 'bid' ? `sell_${type}` : `buy_${type}`;
        setEditingTransaction({
            type: strategy,
            price: side === 'bid' ? option.bid : option.ask,
            strike: option.strike,
            option: option.symbol,
            dueDate: option.due_date,
            quantity: option.contract_size,
        });
        setIsModalOpen(true);
    };

    const stockWithUpdatedPrice = stock ? {
        ...stock,
        price: stockData[stock.symbol]?.price || 'N/A',
        variation: stockData[stock.symbol]?.variation || 0
    } : null;

    const determineNextCycle = useCallback((data, stock) => {
        const buyStocks = data.filter(t => t.type === "buy_stock" && t.symbol === stock.symbol && parseFloat(t.buyback_price) > 0);
        const completedCycles = new Set(buyStocks.map(t => t.cycle));
        const maxCompletedCycle = Math.max(...Array.from(completedCycles), 0);
        setNextCycle(maxCompletedCycle + 1);
    }, []);

    const extractCycles = useCallback((data, stock) => {
        const uniqueCycles = [...new Set(data.filter(t => t.symbol === stock).map((t) => t.cycle))];
        setCycles(uniqueCycles);
    }, []);

    // Função para alterar a data selecionada
    const handleSelectDate = (date) => {
        setSelectedDate(date);  // Atualiza a data selecionada
        setFilterOptions([]);  // Limpa as opções filtradas inicialmente
        const filteredOptions = date ? optionData.filter(option => option.due_date === date) : optionData;
        if (selectedStock) {
            handleApplyFilter(selectedStock, filteredOptions);
        } else {
            setFilterOptions(filteredOptions);  // Se não houver símbolo selecionado, usa todos os dados
        }
    };

    useEffect(() => {
        if (transactions.length > 0 && selectedStock) {
            determineNextCycle(transactions, selectedStock);
            extractCycles(transactions, selectedStock);
        }
    }, [transactions, selectedStock, determineNextCycle, extractCycles]);

    useEffect(() => {
        if (loadingOptions) {
            setFilterOptions([]);  // Limpa as opções filtradas inicialmente
            setIsLoading(true);
        } else {
            setIsLoading(false);
            toast({
                title: "Opções carregadas",
                description: "As opções foram carregadas com sucesso!",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        }
    }, [loadingOptions, toast]);

    const handleOpenModal = (strategy) => {
        setSelectedStrategy(strategy);

        if (strategy === 1) {
            determineNextCycle(transactions, selectedStock);
            setIsModalOpen(true);
        }
        if (strategy === 2) {
            setIsModalOpen(true);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setEditingTransaction(null);
    };

    const handleApplyFilter = async (symbol, opcoes) => {
        const optionsWithDueDate = opcoes.filter(option => option.symbol.slice(0, 4) === symbol.slice(0, 4));
        if (optionsWithDueDate.length > 0) {
            const closestDueDate = optionsWithDueDate.reduce((closest, option) => {
                const optionDate = new Date(option.due_date);
                return optionDate < new Date(closest.due_date) ? option : closest;
            });
            const filteredData = optionsWithDueDate.filter(option => option.due_date === closestDueDate.due_date);
            setFilterOptions(filteredData);
        } else {
            console.log("No options with due_date found");
        }
    };

    const handleAddTransaction = async (transaction) => {
        await onAddOrUpdateTransaction(transaction);
        setIsModalOpen(false);
        setEditingTransaction(null);
    };

    const handleEditTransaction = (transaction) => {
        setEditingTransaction(transaction);
        setIsModalOpen(true);
    };

    if (!selectedStock) {
        return <div>Por favor, selecione um ativo para visualizar os detalhes.</div>;
    }


    const stockWithPrice = selectedStock ? {
        ...selectedStock,
        price: stockData[selectedStock.symbol]?.price || selectedStock.price || 'N/A',
        variation: stockData[selectedStock.symbol]?.variation || selectedStock.variation || 0
    } : null;

    return (
        <div className={`workspace ${isCollapsed ? 'expanded' : ''}`} bg="gray.900" >
            {selectedTab === 'options' ? (
                <>
                    <div className="options-container">
                        <div className="sticky-header">
                            {/* Passa o selectedDate e a função handleSelectDate */}
                            <DueDateSelector
                                selectedDate={selectedDate}  // Passa a data selecionada
                                optionData={optionData}
                                onSelectDate={handleSelectDate}  // Atualiza a data selecionada
                            />
                        </div>

                        {!loadingOptions ? (
                            <OptionsGrid
                                simbolo={selectedStock.symbol}
                                spotPrice={lastPrice}
                                lastPrice={lastPrice}
                                optionData={filterOptions}
                                stockData={stockData}  // Passa todo o stockData para o OptionsGrid
                                onBidAskClick={handleBidAskClick}  // Passa a função para capturar o clique no Bid/Ask
                            />
                        ) : (
                            <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                                {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                                <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                    <Skeleton height="60px" width="100%" />
                                    <Skeleton height="20px" width="100%" />
                                    <Skeleton height="20px" width="100%" />
                                    <Skeleton height="20px" width="100%" />

                                </Stack>
                            </Box>
                        )}
                    </div>
                </>
            ) : selectedTab === 'ivRankList' ? (
                !loadingOptions ? (
                    <IvRankList optionData={optionData} cliente={cliente}  setSelectTicker={setSelectTicker}/>
                ) : (
                    <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                        {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                        <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                            <Skeleton height="60px" width="100%" />
                            <Skeleton height="20px" width="100%" />
                            <Skeleton height="20px" width="100%" />
                            <Skeleton height="20px" width="100%" />

                        </Stack>
                    </Box>
                )
            ) :
                selectedTab === 'kairi' ? (
                    !loadingOptions ? (
                        <KairiList optionData={optionData} cliente={cliente} setSelectTicker={setSelectTicker} />
                    ) : (
                        <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                            {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                            <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                <Skeleton height="60px" width="100%" />
                                <Skeleton height="20px" width="100%" />
                                <Skeleton height="20px" width="100%" />
                                <Skeleton height="20px" width="100%" />

                            </Stack>
                        </Box>
                    )
                ) : selectedTab === 'taxa' ? (
                    !loadingOptions ? (
                        <OptionScanner cliente={cliente} />
                    ) : (
                        <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                            {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                            <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                <Skeleton height="60px" width="100%" />
                                <Skeleton height="20px" width="100%" />
                                <Skeleton height="20px" width="100%" />
                                <Skeleton height="20px" width="100%" />

                            </Stack>
                        </Box>
                    )
                ) : selectedTab === 'scanner' ? (
                    !loadingOptions ? (
                        <Scanner cliente={cliente}
                            onSelectTab={setSelectedTab}  // Agora passa a função do App.js
                            onSelectStock={handleSelectStock}  // Use a função que vem do App.js
                            setSelectTicker={setSelectTicker}
                        />
                    ) : (
                        <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                            {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                            <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                <Skeleton height="60px" width="100%" />
                                <Skeleton height="20px" width="100%" />
                                <Skeleton height="20px" width="100%" />
                                <Skeleton height="20px" width="100%" />

                            </Stack>
                        </Box>
                    )) : selectedTab === '90Squeeze' ? (
                        !loadingOptions ? (
                            <SqueezeList cliente={cliente} setSelectTicker={setSelectTicker}
                            />
                        ) : (
                            <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                                {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                                <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                    <Skeleton height="60px" width="100%" />
                                    <Skeleton height="20px" width="100%" />
                                    <Skeleton height="20px" width="100%" />
                                    <Skeleton height="20px" width="100%" />
                                </Stack>
                            </Box>
                        )) :

                    selectedTab === 'backtest' ? (
                        !loadingOptions ? (
                            <BacktestAnalysis
                                tickers={tickers} // Passe a lista de tickers

                                symbol={(() => {
                                    // Se for objeto E tiver a propriedade strategy, passa o objeto inteiro
                                    if (typeof selectedStock === 'object' && selectedStock.strategy) {
                                        return selectedStock;
                                    }
                                    // Caso contrário, cria um objeto apenas com o symbol
                                    return {
                                        symbol: typeof selectedStock === 'object' ? selectedStock.symbol : selectedStock
                                    };
                                })()}
                            />
                        ) : (
                            <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                                {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                                <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                    <Skeleton height="60px" width="100%" />
                                    <Skeleton height="20px" width="100%" />
                                    <Skeleton height="20px" width="100%" />
                                    <Skeleton height="20px" width="100%" />

                                </Stack>
                            </Box>
                        )) :

                        selectedTab === 'fundamentus' ? (
                            !loadingOptions ? (
                                <Fundamentus cliente={cliente}
                                    setSelectTicker={setSelectTicker}
                                    stockData={stockData}

                                />
                            ) : (
                                <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                                    {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                                    <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                        <Skeleton height="60px" width="100%" />
                                        <Skeleton height="20px" width="100%" />
                                        <Skeleton height="20px" width="100%" />
                                        <Skeleton height="20px" width="100%" />

                                    </Stack>
                                </Box>
                            )) :

                            selectedTab === 'aluguel' ? (
                                !loadingOptions ? (
                                    <AluguelChart cliente={cliente} />
                                ) : (
                                    <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                                        {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                                        <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                            <Skeleton height="60px" width="100%" />
                                            <Skeleton height="20px" width="100%" />
                                            <Skeleton height="20px" width="100%" />
                                            <Skeleton height="20px" width="100%" />

                                        </Stack>
                                    </Box>
                                )) :


                                selectedTab === 'jumba' ? (
                                    !loadingOptions ? (
                                        <Jumba
                                            selectedStock={selectedStock} // Certifique-se de passar o estado atualizado
                                            optionsValues={optionData}
                                            nearestDueDate={nearestDueDate}
                                            distinctDueDates={distinctDueDates}
                                            tickers={tickers}
                                            handleSelectStock={handleSelectStock}
                                            stockData={stockData}
                                            tickersWithOptions={tickersWithOptions}
                                            cliente={cliente}

                                        />
                                    ) : (
                                        <Box width="100%" height="50vh" display="flex" justifyContent="center" alignItems="center" marginTop={1}>
                                            {/* Exibir Skeleton enquanto os dados de opção estão sendo carregados */}
                                            <Stack spacing={4} marginRight={5} width="100%" maxWidth="700px">
                                                <Skeleton height="60px" width="100%" />
                                                <Skeleton height="20px" width="100%" />
                                                <Skeleton height="20px" width="100%" />
                                                <Skeleton height="20px" width="100%" />

                                            </Stack>
                                        </Box>
                                    )
                                ) : selectedTab === 'chart' ? (
                                    selectedStock ? (
                                        <TradingViewChart simbolo={selectedStock} />
                                    ) : (
                                        <Box width="100%"><p>Selecione uma ação na tabela para ver o gráfico.</p></Box>
                                    )
                                ) : (
                                    <>
                                        <StockOverview
                                            selectedStock={selectedStock}
                                            transactions={transactions}
                                            stockData={stockData}
                                        />
                                        <SimulationTable
                                            symbol={selectedStock}
                                            userId={userId}
                                            onEdit={handleEditTransaction}
                                            onDelete={onDeleteTransaction}
                                            transactions={transactions}
                                            stockData={stockData}  // Passa todo o stockData para o SimulationTable
                                            optionData={optionData}  // Passa as opções para o SimulationFormModal

                                        />

                                        <Menu>
                                            <MenuButton
                                                as={IconButton}
                                                icon={isLoading ? <Spinner /> : <AddIcon />}  // Exibe o spinner durante o carregamento
                                                size="lg"
                                                colorScheme="teal"
                                                position="fixed"
                                                bottom="20px"
                                                left="20px"
                                                zIndex="1000"
                                                boxShadow="lg"
                                                aria-label="Add new transaction"
                                                isDisabled={isLoading}  // Desabilita o botão enquanto carrega
                                            />
                                            <MenuList>
                                                <MenuItem onClick={() => handleOpenModal(1)}>MRP (The Wheel)</MenuItem>
                                                <MenuItem onClick={() => handleOpenModal(2)}>LEAPS Call/Put</MenuItem>
                                                <MenuItem isDisabled onClick={() => handleOpenModal('Short Straddle')}>Short Straddle</MenuItem>
                                                <MenuItem isDisabled onClick={() => handleOpenModal('Long Straddle')}>Long Straddle</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </>
                                )}

            {!loadingOptions && (
                <SimulationFormModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    onAddTransaction={handleAddTransaction}
                    editingTransaction={editingTransaction}
                    nextCycle={nextCycle}
                    symbol={selectedStock}
                    loadingOptions={loadingOptions}
                    userId={userId}
                    strategy={selectedStrategy}
                    optionData={optionData}  // Passa as opções para o SimulationFormModal
                />
            )}
        </div>
    );
}

export default Workspace;
