import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  useToast,
  Textarea,
  Switch
} from '@chakra-ui/react';
import { getUser, updateUser } from '../api/user';

const Profile = ({ onClose, userId }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser(userId);
        setUser(userData);
      } catch (error) {
        toast({
          title: 'Failed to load user data',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    };
    fetchUser();
  }, [toast]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setUser({ ...user, [name]: newValue });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateUser(user.id, user);
      toast({
        title: 'Profile updated',
        description: 'Your profile has been updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: 'Failed to update profile',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box maxW="md" mx="auto" mt="10%">
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl id="username" isRequired>
            <FormLabel>Username</FormLabel>
            <Input
              name="username"
              value={user.username}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="email"
              value={user.email}
              disabled={true}
            />
          </FormControl>

          <FormControl id="darkMode">
            <FormLabel>Dark Mode</FormLabel>
            <Switch
              name="darkMode"
              isChecked={user.darkMode}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl id="recebe_notificacao">
            <FormLabel>Recebe Notificação</FormLabel>
            <Switch
              name="recebe_notificacao"
              isChecked={user.recebe_notificacao}
              onChange={handleInputChange}
            />
          </FormControl>

          {user.recebe_notificacao && (
            <FormControl id="telegram_username">
              <FormLabel>Telegram Username</FormLabel>
              <Input
                name="telegram_username"
                value={user.telegram_username || ''}
                onChange={handleInputChange}
              />
            </FormControl>
          )}

          <FormControl id="language">
            <FormLabel>Language</FormLabel>
            <Input
              name="language"
              value={user.language}
              onChange={handleInputChange}
            />
          </FormControl>
          <FormControl id="description">
            <FormLabel>Description</FormLabel>
            <Textarea
              name="description"
              value={user.description}
              onChange={handleInputChange}
            />
          </FormControl>
          <Button type="submit" colorScheme="teal" width="full">
            Save
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default Profile;
