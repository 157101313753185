import axios from 'axios';

const API_URL = 'https://api.fatcat.app.br/user';

export const getAllUsersActive = async () => {
  const response = await axios.get(`${API_URL}/active`);
  return response.data;
};

export const getUser = async (userid) => {
  const response = await axios.get(`${API_URL}/${userid}`);
  return response.data;
};

export const createUser = async (dados) => {
  const response = await axios.post(API_URL, dados);
  return response.data;
};

export const updateUser = async (id, dados) => {
  const response = await axios.put(`${API_URL}/${id}`, dados);
  return response.data;
};

export const deleteUser = async (id) => {
  const response = await axios.delete(`${API_URL}${id}/`);
  return response.data;
};

export const firstAccess = async (email, newPassword) => {
  const response = await axios.post(`${API_URL}/first-access`, {
    email,
    newPassword,
  });
  return response.data;
};

