import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  FormErrorMessage,
  Spacer,
  Flex
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";

const SimulationFormModal = ({
  isOpen,
  onClose,
  onAddTransaction,
  editingTransaction,
  nextCycle,
  symbol,
  userId,
  loadingOptions,
  optionData,  // Recebendo as opções do Workspace
  strategy
}) => {
  const [formKey, setFormKey] = useState(0);
  const [dueDates, setDueDates] = useState([]);
  const [options, setOptions] = useState({ call: [], put: [] });


  // Função para formatar datas de forma segura
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      console.error("Invalid date:", dateString);
      return "";
    }
    return date.toISOString().split('T')[0];
  };

  // Função para obter os valores iniciais do formulário
  const getInitialValues = () => {
    if (editingTransaction) {
      return {
        type: editingTransaction.type || "sell_put",
        price: editingTransaction.price?.toString() || "",
        buyback_price: editingTransaction.buyback_price?.toString() || "",
        quantity: editingTransaction.quantity?.toString() || "",
        cycle: editingTransaction.cycle?.toString() || nextCycle.toString(),
        dueDate: editingTransaction.dueDate ? formatDate(editingTransaction.dueDate) : "",
        option: editingTransaction.option || "",
        contract_size: editingTransaction.contract_size || 100,
        strike: editingTransaction.strike?.toString() || "",
        userId: userId,
      };
    } else {
      return {
        type: "sell_put",
        price: "",
        buyback_price: "",
        quantity: "",
        cycle: nextCycle.toString(),
        dueDate: "",
        option: "",
        contract_size: 100,
        strike: "",
        userId: userId,
      };
    }
  };

  // Efeito para reinicializar o formulário quando é aberto ou editingTransaction muda
  useEffect(() => {
    if (isOpen) {
      setFormKey(prevKey => prevKey + 1);
    }
  }, [isOpen, editingTransaction]);

  // Efeito para buscar dados de opções quando o símbolo muda
  useEffect(() => {
    if (symbol) {
      setDueDates([]);
      setOptions({ call: [], put: [] });
      fetchOptionsData(symbol);
    }
  }, [symbol]);

  // Função para buscar dados de opções da API
  const fetchOptionsData = async (symbol) => {
    try {
      const callOptions = optionData.filter(option => option.category === "CALL");
      const putOptions = optionData.filter(option => option.category === "PUT");
      const uniqueDueDates = [...new Set(optionData.map(option => option.due_date))].map(dueDate => {
        const daysToMaturity = optionData.find(option => option.due_date === dueDate).days_to_maturity;
        return { dueDate, daysToMaturity };
      });
      setDueDates(uniqueDueDates);
      setOptions({ call: callOptions, put: putOptions });
    } catch (error) {
      console.error("Error fetching options data:", error);
    }
  };

  // Função para obter opções baseadas na data de vencimento e tipo
  const getOpcoes = (dueDate, type) => {
    if (!dueDate || !type) return [];
    
    if (type.includes("call")) {
      // Retorna opções de CALL com o dueDate correspondente
      return options.call.filter(option => option.due_date === dueDate);
    } else if (type.includes("put")) {
      // Retorna opções de PUT com o dueDate correspondente
      return options.put.filter(option => option.due_date === dueDate);
    }
    return [];
  };

  // Handlers para mudanças nos campos do formulário
  const handleDueDateChange = (e, setFieldValue, values) => {
    const dueDate = e.target.value;
    setFieldValue("dueDate", formatDate(dueDate));
    console.log("Selected Due Date:", dueDate);

    if (!editingTransaction) {
      setFieldValue("option", "");
      setFieldValue("price", "");
      setFieldValue("strike", "");
    }
  };

  const handleTypeChange = (e, setFieldValue, values) => {
    const type = e.target.value;
    setFieldValue("type", type);
    setFieldValue("option", "");
    setFieldValue("price", "");
    setFieldValue("strike", "");
  };
  
  const handleOptionChange = (e, setFieldValue, values) => {
    const optionSymbol = e.target.value;
    setFieldValue("option", optionSymbol);
    const selectedOption = getOpcoes(values.dueDate, values.type).find(
      option => option.symbol === optionSymbol
    );
    if (selectedOption) {
      setFieldValue("price", selectedOption.bid);
      setFieldValue("quantity", selectedOption.contract_size);
      setFieldValue("contract_size", selectedOption.contract_size);
      setFieldValue("strike", selectedOption.strike);
    }
  };

  // Função de validação para quantidade
  const validateQuantity = (value, contractSize) => {
    let error;
    if (value % contractSize !== 0) {
      error = `Quantity must be a multiple of ${contractSize}`;
    }
    return error;
  };

  if (loadingOptions) {
    return <div>Carregando opções...</div>; // Exibe uma mensagem ou um spinner de carregamento
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {editingTransaction ? "Edit Transaction" : "Add Transaction"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            key={formKey}
            initialValues={getInitialValues()}
            onSubmit={(values, actions) => {
              const transaction = {
                ...values,
                price: parseFloat(values.price),
                buyback_price: parseFloat(values.buyback_price),
                quantity: parseInt(values.quantity),
                cycle: parseInt(values.cycle),
                symbol: symbol,
                strike: values.strike,
                dueDate: values.dueDate ? formatDate(values.dueDate) : null,
                id: editingTransaction ? editingTransaction.id : null,
              };
              onAddTransaction(transaction);
              onClose();
            }}
            enableReinitialize
          >
            {({ setFieldValue, values }) => (
              <Form>
                <SimpleGrid columns={2} spacing={4}>
                  <Field name="cycle">
                    {({ field }) => (
                      <>
                        {strategy === 1 ? (
                          <FormControl>
                            <FormLabel>Cycle</FormLabel>
                            <Input {...field} type="number" readOnly />
                          </FormControl>
                        ) : null} {/* Se strategy não for 1, não renderiza nada */}
                      </>
                    )}
                  </Field>


                  <Field name="type">
                    {({ field }) => (
                      <FormControl>
                        <FormLabel>Transaction Type</FormLabel>
                        <Select {...field} onChange={(e) => handleTypeChange(e, setFieldValue, values)}>
                          {strategy === 2 ? (
                            <>
                              <option value="buy_call">Buy Call</option>
                              <option value="buy_put">Buy Put</option>
                              <option value="sell_put">Sell Put</option>
                              <option value="sell_call">Sell Call</option>
                            </>
                          ) : (
                            <>
                              <option value="sell_put">Sell Put</option>
                              <option value="buy_stock">Buy Stock</option>
                              <option value="sell_call">Sell Call</option>
                            </>
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </Field>

                  <Field name="price">
                    {({ field }) => (
                      <FormControl>
                        <FormLabel>Price</FormLabel>
                        <Input {...field} type="number" step="0.01" />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="buyback_price">
                    {({ field }) => (
                      <FormControl>
                        <FormLabel>Buyback Price</FormLabel>
                        <Input {...field} type="number" step="0.01" />
                      </FormControl>
                    )}
                  </Field>
                  <Field name="quantity" validate={(value) => validateQuantity(value, values.contract_size)}>
                    {({ field, form }) => (
                      <FormControl isInvalid={form.errors.quantity && form.touched.quantity}>
                        <FormLabel>Quantity</FormLabel>
                        <Input {...field} type="number" />
                        <FormErrorMessage>{form.errors.quantity}</FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  {(values.type === "sell_put" || values.type === "sell_call" || values.type === "buy_call" || values.type === "buy_put") && (
                    <>
                      <Field name="dueDate">
                        {({ field }) => (
                          <FormControl>
                            <FormLabel>Due Date</FormLabel>
                            <Select
                              {...field}
                              onChange={(e) => handleDueDateChange(e, setFieldValue, values)}
                            >
                              <option value="">Select Due Date</option>
                              {dueDates.map(({ dueDate, daysToMaturity }) => (
                                <option key={dueDate} value={dueDate}>
                                  {formatDate(dueDate)} ({daysToMaturity} days)
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                      <Field name="strike">
                        {({ field }) => (
                          <FormControl>
                            <FormLabel>Strike</FormLabel>
                            <Input {...field} type="number" readOnly />
                          </FormControl>
                        )}
                      </Field>
                      <Field name="option">
                        {({ field }) => (
                          <FormControl>
                            <FormLabel>Option</FormLabel>
                            <Select
                              {...field}
                              onChange={(e) => handleOptionChange(e, setFieldValue, values)}
                              value={values.option || ""}
                            >
                              <option value="">Select Option</option>
                              {getOpcoes(values.dueDate, values.type).map(option => (
                                <option key={option.symbol} value={option.symbol}>
                                  {option.symbol} - Strike: {option.strike}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </Field>
                    </>
                  )}
                </SimpleGrid>
                <Flex mt={4}>
                  <Button
                    type="submit"
                    colorScheme="teal"
                    isLoading={false}
                  >
                    {editingTransaction ? "Update Transaction" : "Add Transaction"}
                  </Button>
                  <Spacer />
                  <Button onClick={onClose} colorScheme="gray">
                    Cancel
                  </Button>
                </Flex>
              </Form>
            )}
          </Formik>
        </ModalBody>
        <ModalFooter>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SimulationFormModal;