import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tooltip,
  Circle,
  VStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";

const StockTable = ({ stocks, stockData = {}, onSelectStock }) => {

  const getCrossColor = (condition) =>
    condition === "none" ? "gray" : condition === "buy" ? "green" : "red";
  const getSignalColor = (signal) =>
    signal === "buy" ? "green" : signal === "sell" ? "red" : "gray";
  const getSqueezeColor = (condition) =>
    condition === "True" ? "green" : "gray";
  const getRsiColor = (condition) =>
    condition === "none" ? "gray" : condition === "buy" ? "green" : "red";

  const renderPopover = (title, content, color) => (
    <Popover trigger="hover" placement="auto">
      <PopoverTrigger>
        <Circle size="10px" bg={color} cursor="pointer" />
      </PopoverTrigger>
      <PopoverContent color="white" bg="blue.800" borderColor="blue.800" width="200px">
        <PopoverArrow bg="blue.800" />
        <PopoverCloseButton />
        <PopoverHeader pt={4} fontWeight="bold" border="0">
          {title}
        </PopoverHeader>
        <PopoverBody>{content}</PopoverBody>
      </PopoverContent>
    </Popover>
  );

  return (
    <VStack align="start" spacing={5} w="100%">
      <Table variant="simple" size="12px">
        <Thead>
          <Tr>
            <Th fontSize="10px">Ticker</Th>
            <Th fontSize="10px">Preço</Th>
            <Th fontSize="10px">Var</Th>
            {/* <Th fontSize="10px">S</Th>
            <Th fontSize="10px">C</Th>
            <Th fontSize="10px">S</Th>
            <Th fontSize="10px">R</Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {stocks.map((stock) => {
            if (!stock || !stock.ticker) return null;

            const cleanedTicker = stock.ticker?.replace(".SA", "");
            const stockInfo = stockData[cleanedTicker]; // Pegando os dados do stockData

            const price =  stockInfo?.price || stock.price;
            const variation = stockInfo?.variation;

            return (
              stock.ticker && (
                <Tr
                  key={stock.ticker}
                  _hover={{ bg: "gray.200", cursor: "pointer" }}
                  onClick={() => onSelectStock(stock.symbol)}
                >
                  <Td fontSize="12px">{cleanedTicker}</Td>
                  <Td fontSize="12px">
                    {price !== undefined && price !== null
                      ? `${price.toFixed(2)}`
                      : "—"}
                  </Td>
                  <Td fontSize="12px">
                    {variation !== undefined && variation !== null
                      ? `${variation.toFixed(2)}%`
                      : "—"}
                  </Td>
               
                </Tr>
              )
            );
          })}
        </Tbody>
      </Table>
    </VStack>
  );
};

export default StockTable;
