class EventBus {
  constructor() {
    this.events = {};
  }

  on(event, listener) {
    if (!this.events[event]) {
      this.events[event] = [];
    }
    this.events[event].push(listener);
  }

  off(event, listener) {
    if (!this.events[event]) return;

    this.events[event] = this.events[event].filter(l => l !== listener);
  }

  emit(event, data) {
    if (!this.events[event]) return;

    this.events[event].forEach(listener => listener(data));
  }
}

class WebSocketManager {
  constructor(onMessage, onError) {
    this.endpoints = [
      { url: 'wss://data.fatcat.app.br/ws/quote', checkUrl: 'https://data.fatcat.app.br/ws/capacity', load: 0 }
    ];
    this.currentEndpointIndex = 0;
    this.socket = null;
    this.onMessage = onMessage;
    this.onError = onError;
    this.isConnecting = false;
    this.messageQueue = [];
    this.subscribedSymbols = new Map();
    this.eventBus = new EventBus();
    this.eventBus.on('message', onMessage);
    this.eventBus.on('error', onError);
    this.lastSequence = -1;
    this.retryCount = 0;
    this.pingInterval = null;
    this.checkMarketInterval = null; // Temporizador para verificar o horário de abertura

    this.initializeMarketCheck(); // Iniciar o temporizador de verificação de horário
  }

  // Função para verificar se o mercado está aberto com o horário de São Paulo
  isMarketOpen() {
    const now = new Date();
    const saoPauloTime = new Intl.DateTimeFormat("pt-BR", {
      hour: 'numeric',
      timeZone: 'America/Sao_Paulo'
    }).format(now);

    const hour = parseInt(saoPauloTime, 10);
    return hour >= 10 && hour < 17; // 10:00 às 17:00 BRT
  }

  initializeMarketCheck() {
    this.checkMarketInterval = setInterval(() => {
      if (this.isMarketOpen() && !this.isConnected()) {
        console.log('Mercado abriu. Tentando conectar o WebSocket...');
        this.connect();
      }
    }, 60000); // Verifica a cada minuto
  }


  async checkEndpointLoad(endpoint) {
    try {
      const response = await fetch(endpoint.checkUrl, { method: 'GET' });
      const maxConnections = parseInt(response.headers.get('x-maximum-connections'), 10);
      const activeConnections = parseInt(response.headers.get('x-active-connections'), 10);
      endpoint.load = (activeConnections / maxConnections) * 100;
    } catch (error) {
      console.error(`Error checking endpoint load: ${error}`);
      endpoint.load = 100;
    }
  }

  async selectBestEndpoint() {
    await Promise.all(this.endpoints.map(endpoint => this.checkEndpointLoad(endpoint)));
    const leastLoaded = this.endpoints.reduce((a, b) => (a.load < b.load ? a : b));
    return leastLoaded.url;
  }

  isConnected() {
    return this.socket && this.socket.readyState === WebSocket.OPEN;
  }

  async connect() {
    if (!this.isMarketOpen()) {
      console.log('Mercado fechado.');
      this.marketOpen = false;
      return;
    }

    this.marketOpen = true;

    if (this.isConnected()) {
      console.log('WebSocket connection already active');
      return;
    }

    if (this.isConnecting) {
      console.log('Connection attempt already in progress');
      return;
    }

    this.isConnecting = true;
    const endpoint = await this.selectBestEndpoint();
    this.socket = new WebSocket(endpoint);

    this.socket.onopen = () => {
      this.isConnecting = false;
      console.log('WebSocket connected');
    
      // Limpa o intervalo de verificação de horário após conectar
      clearInterval(this.checkMarketInterval);
    
      // Envia mensagens pendentes e símbolos inscritos
      this.flushMessageQueue();
      this.sendSubscribedSymbols();
      this.retryCount = 0;
    
      // Configura o ping para manter a conexão ativa
      this.pingInterval = setInterval(() => {
        if (this.isConnected()) {
          this.socket.send('ping');  // Envia ping a cada 30 segundos
        }
      }, 30000);  // Ping a cada 30 segundos
    };
    



    this.socket.onmessage = (event) => {
      const dataLines = event.data.split('\n');

      // Mapeia as linhas recebidas
      const parsedData = dataLines.map(line => {
        const fields = line.split('|');
        const type = fields[0];  // Tipo de mensagem ("S" ou "O")

        if (type === 'S') {
          // Parsing para mensagens do tipo "S"
          const [_, symbol, value, variation] = fields;
          return {
            type: 'S',
            ticker: symbol,
            value: parseFloat(value),
            variation: parseFloat(variation)
          };
        } else if (type === 'O') {
          // Parsing para mensagens do tipo "O" (opções)
          const [_, symbol, value, variation, bid, ask, mid, financialVol, due_date, category, strike, days_to_maturity] = fields;
          return {
            type: 'O',
            ticker: symbol,
            value: parseFloat(value),
            variation: parseFloat(variation),
            bid: parseFloat(bid),
            ask: parseFloat(ask),
            mid: parseFloat(mid),
            financialVol: parseFloat(financialVol),
            due_date: due_date,
            category: category,
            strike: strike,
            days_to_maturity: days_to_maturity,

          };
        } else {
          // console.error("Unknown message type:", type);
          return null;
        }
      });

      // Remove possíveis entradas nulas (se houver erro no parsing)
      const validParsedData = parsedData.filter(data => data !== null);

      // Emitir os dados processados
      this.eventBus.emit('message', validParsedData);
    };


    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error.message);
      this.eventBus.emit('error', error);
      this.reconnect();
    };

    this.socket.onclose = (event) => {
      console.log('WebSocket closed with code:', event.code, 'reason:', event.reason);
      
      // Limpa o intervalo de ping
      clearInterval(this.pingInterval);
      this.socket = null;
      this.isConnecting = false;
    
      // Reinicia a verificação de mercado para reconectar na próxima abertura
      this.initializeMarketCheck();
    
      // Reconecta apenas se o mercado estiver aberto e a desconexão foi inesperada
      if (event.code !== 1000 && event.code !== 1001 && this.isMarketOpen()) {
        this.reconnect();
      }
    };
    
  }

  reconnect() {
    if (!this.isMarketOpen()) {
      console.log('Mercado fechado. WebSocket não reconectado.');
      return;
    }

    const delay = Math.min(1000 * (2 ** this.retryCount), 30000);
    console.log(`WebSocketManager: Reconnecting in ${delay / 1000} seconds`);
    setTimeout(() => {
      this.connect();
    }, delay);
    this.retryCount++;
  }

  // Método para mudar as assinaturas (unsubscribe e depois subscribe)
  switchSubscription(newSymbols) {
    this.unsubscribeFromAllSymbols(); // Desinscreve de todos os símbolos antigos
    this.subscribeToSymbols(newSymbols); // Inscreve os novos símbolos
  }

  subscribeToSymbols(symbols) {
    symbols.forEach(symbol => {
      if (!this.subscribedSymbols.has(symbol)) {
        this.subscribedSymbols.set(symbol, { count: 1 });
      } else {
        this.subscribedSymbols.get(symbol).count++;
      }
    });
    this.sendSubscribedSymbols();
  }

  unsubscribeFromSymbols(symbols) {
    const symbolsToUnsubscribe = [];

    symbols.forEach(symbol => {
      if (this.subscribedSymbols.has(symbol)) {
        const subscription = this.subscribedSymbols.get(symbol);
        subscription.count--;
        if (subscription.count <= 0) {
          this.subscribedSymbols.delete(symbol);
          symbolsToUnsubscribe.push(symbol);  // Adiciona o símbolo à lista de unsubscribe
        }
      }
    });

    // Envia a mensagem de unsubscribe se houver símbolos a desinscrever
    if (symbolsToUnsubscribe.length > 0) {
      const message = JSON.stringify({ type: "tick", unsubscribe: symbolsToUnsubscribe });
      this.sendMessage(message);  // Envia a mensagem via WebSocket
    }
  }

  unsubscribeFromAllSymbols(symbolsToUnsubscribe) {
    // const symbolsToUnsubscribe = Array.from(this.subscribedSymbols.keys());
    if (symbolsToUnsubscribe.length > 0) {
      const message = JSON.stringify({ type: "tick", unsubscribe: symbolsToUnsubscribe });
      this.sendMessage(message);
    }
    this.subscribedSymbols.clear();
  }

  sendSubscribedSymbols() {
    console.log('WebSocketManager: Sending subscribed symbols');
    const symbols = Array.from(this.subscribedSymbols.keys());
    if (symbols.length > 0) {
      const message = JSON.stringify({ type: "tick", subscribe: symbols });
      this.sendMessage(message);
    }
  }

  sendMessage(message) {
    if (this.isConnected()) {
      try {
        // Se a mensagem não for uma string, converte para JSON
        const formattedMessage = typeof message === 'string' ? message : JSON.stringify(message);
        this.socket.send(formattedMessage);
      } catch (error) {
        console.error('Error sending message:', error);
      }
    } else {
      console.log('WebSocketManager: Not connected, queueing message');
      this.messageQueue.push(message);
      this.connect();
    }
  }


  flushMessageQueue() {
    while (this.messageQueue.length > 0) {
      const message = this.messageQueue.shift();
      this.sendMessage(message);
    }
  }

  close() {
    if (this.socket) {
      console.log('WebSocketManager: Closing connection');
      clearInterval(this.pingInterval);
      this.socket.onclose = null;
      this.socket.onerror = null;
      this.socket.onmessage = null;
      this.socket.close();
      this.socket = null;
    }
    this.subscribedSymbols.clear();
  }
}

export default WebSocketManager;
