import React from 'react';
import { Box, Text, Flex, HStack } from '@chakra-ui/react';
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';

// Mantém as funções de formatação existentes
const formatarPercentual = (percentual) => {
  if (percentual === null || percentual === undefined || isNaN(percentual)) {
    return 'N/A';
  }
  const numero = parseFloat(percentual);
  return numero.toLocaleString('pt-BR', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2,
    style: 'decimal'
  });
};

const formatarValorInteiro = (valor) => {
  if (valor === null || valor === undefined || isNaN(valor)) {
    return 'N/A';
  }
  const numero = parseFloat(valor);
  return Math.round(numero).toLocaleString('pt-BR', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'decimal'
  });
};

const formatarComDuasCasasDecimais = (valor) => {
  if (valor === null || valor === undefined || isNaN(valor)) {
    return 'N/A';
  }
  const numero = parseFloat(valor);
  return numero.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'decimal'
  });
};

const CaixaResumo = ({ titulo, valor, percentual, subtitulo, formatarComoInteiro = false, selectedOptionType }) => {
  const tipoOpcao = selectedOptionType === '70' ? 'CALLs' : 'PUTs';
  const isPositive = parseFloat(percentual) >= 0;
  const arrowIcon = isPositive ? <TriangleUpIcon /> : <TriangleDownIcon />;
  const arrowColor = isPositive ? 'green.400' : 'red.400';

  return (
    <Box
    bg="#2f3340"
    p={4}
      borderRadius="lg"
      _hover={{ bg: 'gray.750' }}
      transition="background 0.2s"
    >
      <Flex direction="column" height="100%">
        {/* Título */}
        <Text 
          color="gray.400"
          fontSize="sm"
          fontWeight="medium"
          mb={2}
        >
          {titulo}
        </Text>

        {/* Valor Principal e Percentual */}
        <Flex justify="space-between" align="baseline" mb={2}>
          <Text 
            color="white"
            fontSize="2xl"
            fontWeight="bold"
            lineHeight="1"
          >
            {formatarComoInteiro 
              ? formatarValorInteiro(valor) 
              : formatarComDuasCasasDecimais(valor)
            }
          </Text>
          
          <HStack spacing={1} align="center">
            <Box color={arrowColor}>
              {arrowIcon}
            </Box>
            <Text 
              color={arrowColor}
              fontSize="sm"
              fontWeight="medium"
            >
              {formatarPercentual(percentual)}
            </Text>
          </HStack>
        </Flex>

        {/* Subtítulo */}
        {subtitulo && (
          <Text 
            color="gray.500"
            fontSize="xs"
            mt="auto"
          >
            {subtitulo === 'Total de CALLs' || subtitulo === 'Total de PUTs' 
              ? `Total de ${tipoOpcao}` 
              : subtitulo
            }
          </Text>
        )}
      </Flex>
    </Box>
  );
};

export default CaixaResumo;