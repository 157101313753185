import React, { useMemo } from 'react';
import GaugeChart from 'react-gauge-chart';
import { Box, Tooltip, useColorMode } from '@chakra-ui/react';

const FearGreedGauge = ({ value }) => {
  const { colorMode } = useColorMode(); // Hook para detectar o tema atual (claro ou escuro)

  const gaugeValue = value ? value : 0;

  // Memoriza o valor do tooltipLabel
  const tooltipLabel = useMemo(() => `Fear & Greed Index: ${gaugeValue}`, [gaugeValue]);

  // Defina as cores e estilos com base no tema
  const needleColor = colorMode === 'dark' ? '#FF6347' : 'red'; // Ponteiro vermelho no modo escuro, preto no claro
  const gaugeColors = colorMode === 'dark' ? ["#FF0000", "#008000"] : ["#008000", "#FF0000"]; // Cores para temas diferentes
  const textColor = colorMode === 'dark' ? '#ffffff' : '#000000'; // Branco no modo escuro, preto no claro

  return (
    <Tooltip label={tooltipLabel} aria-label={tooltipLabel}>
      <Box textAlign="center" color={textColor}>
        <GaugeChart
          id="gauge-chart3"
          nrOfLevels={15}
          colors={gaugeColors}
          arcWidth={0.4}
          percent={gaugeValue / 100}
          style={{ width: '110px', height: '50px' }} // Ajuste o tamanho aqui
          needleColor={needleColor} // Cor do ponteiro
          formatTextValue={(value) => `${value}%`}
          textColor={textColor} // Esta é uma tentativa para forçar a cor do texto se o suporte existir
        />
      </Box>
    </Tooltip>
  );
};

export default FearGreedGauge;
