import axios from 'axios';

const API_URL = 'https://api.fatcat.app.br/default/';

export const getDefault = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

export const getAtivosOptions = async () => {
  const response = await axios.get(`${API_URL}/ativos-com-opcoes`);
  return response.data;
};


export const createDefault = async (ativos) => {
  const response = await axios.post(API_URL, ativos);
  return response.data;
};

export const updateDefault = async (id, transaction) => {
  const response = await axios.put(`${API_URL}${id}/`, transaction);
  return response.data;
};

export const deleteDefault = async (id) => {
  const response = await axios.delete(`${API_URL}${id}/`);
  return response.data;
};
