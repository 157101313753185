import React, { useEffect, useRef } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import './TickerBar.css';

const TickerBar = ({ tickers, stockData }) => {
  const tickerRef = useRef(null);
  const gradientStartColor = useColorModeValue('#f4f4f4', '#172736');
  const gradientEndColor = useColorModeValue('rgba(244, 244, 244, 0)', 'rgba(23, 39, 54, 0)');

  useEffect(() => {
    const ticker = tickerRef.current;
    if (ticker) {
      const contentWidth = ticker.scrollWidth;
      const duration = contentWidth / 4;
      ticker.style.animationDuration = `${duration}s`;
    }
  }, [tickers]);

  // Limitar a exibição de 10 tickers
  const displayedTickers = tickers && Array.isArray(tickers) ? tickers.slice(0, 10) : [];

  return (
    <Box className="ticker-container">
      <Box 
        className="ticker-gradient-left" 
        style={{ backgroundImage: `linear-gradient(to right, ${gradientStartColor}, ${gradientEndColor})` }}
      />
      <Box 
        className="ticker-gradient-right" 
        style={{ backgroundImage: `linear-gradient(to right, ${gradientEndColor}, ${gradientStartColor})` }}
      />
      <Box className="ticker-content" ref={tickerRef}>
        {displayedTickers.map((ticker, index) => {
          // Certifique-se de que `ticker` seja uma string válida e exista no `stockData`
          const symbol = typeof ticker === 'string' ? ticker : '';
          const stockInfo = stockData[symbol];

          // Verifique se existe dados de estoque para o símbolo
          if (stockInfo) {
            const price = stockInfo.price ? stockInfo.price.toFixed(2) : '0.00';
            const change = stockInfo.variation ? stockInfo.variation.toFixed(2) : '0.00';

            return (
              <span key={index} className="ticker-item">
                {symbol} R${price}{' '}
                <span className={parseFloat(change) >= 0 ? 'positive-change' : 'negative-change'}>
                  {parseFloat(change) >= 0 
                    ? `(+${change}%)` 
                    : `(-${Math.abs(change)}%)`}
                </span>
              </span>
            );
          }

          // Retorna null caso o símbolo não tenha dados
          return null;
        })}
      </Box>
    </Box>
  );
};

export default TickerBar;
