import axios from 'axios';

const API_URL = 'https://api.fatcat.app.br/analyses';

export const getAnalises = async (symbol, userId) => {
  const response = await axios.get(`${API_URL}/symbol?ticker=${symbol}&userId=${userId}`);
  return response.data;
};

export const createAnalises = async (ativos) => {
  const response = await axios.post(API_URL, ativos);
  return response.data;
};

export const updateAnalises = async (id, transaction) => {
  const response = await axios.put(`${API_URL}${id}/`, transaction);
  return response.data;
};

export const deleteAnalises = async (id) => {
  const response = await axios.delete(`${API_URL}${id}/`);
  return response.data;
};
