import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Box,
  Select,
  HStack,
  Skeleton,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  useDisclosure
} from '@chakra-ui/react'; import { Bar } from 'react-chartjs-2';
import { CategoryScale, Chart as ChartJS, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import OptionsTable from './OptionsTable';
import CaixaResumo from './CaixaResumo';
import axios from 'axios';
import { getJumba } from '../api/jumba';
import annotationPlugin from 'chartjs-plugin-annotation';
import debounce from 'lodash/debounce';

// Registre os componentes Chart.js se ainda não estiverem registrados
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const Jumba = ({ selectedStock, stockData, optionsValues, nearestDueDate, distinctDueDates = [], tickers = [], handleSelectStock, tickersWithOptions = [], cliente = 0 }) => {
  const [optionsData, setOptionsData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(nearestDueDate || '');
  const [selectedOptionType, setSelectedOptionType] = useState('80');
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCargaDate, setSelectedCargaDate] = useState('');
  const [lastPrice, setLastPrice] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [modalMessage, setModalMessage] = useState('');

  const [summaryData, setSummaryData] = useState({
    cobertas: { value: 0, percent: '0%' },
    travadas: { value: 0, percent: '0%' },
    descobertas: { value: 0, percent: '0%' },
    iqCalls: { value: 0, percent: '0%' },
    putCallRatio: { value: 0, percent: '0%' }
  });

  // Formatadores memoizados
  const formatarComDuasCasasDecimais = useCallback((valor) => {
    if (valor === null || valor === undefined || isNaN(valor)) return '-';
    return parseFloat(valor).toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      style: 'decimal'
    });
  }, []);

  const formatDate = useCallback((date) => {
    if (!date || isNaN(new Date(date))) return 'Data inválida';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year.slice(-2)}`;
  }, []);

  const formatYAxis = useCallback((value) => {
    if (value >= 1000000000) return (value / 1000000000).toFixed(1) + 'B';
    if (value >= 1000000) return (value / 1000000).toFixed(1) + 'M';
    if (value >= 1000) return (value / 1000).toFixed(1) + 'K';
    return value;
  }, []);

  const handleJumba = useCallback(async () => {
    try {
      const dtx = selectedDate || nearestDueDate;
      if (!selectedStock) {
        console.warn('Ativo não selecionado.');
        return;
      }
      if (!dtx) {
        const isEnabled =
          cliente === 1 || selectedStock === 'BOVA11';
        handleTickerClick(selectedStock, isEnabled);
      }

      setOptionsData([]); // Limpa os dados anteriores enquanto carrega

      const data = await getJumba(selectedStock.slice(0, 4), dtx);

      if (!data || data.length === 0) { // Verifica se data é null, undefined ou array vazio
        console.warn('Nenhum dado encontrado para o vencimento selecionado.');
        setOptionsData([]); // Garante que optionsData será definido como vazio
        return;
      }

      setOptionsData(data);

    } catch (error) {
      console.error('Erro ao buscar dados do Jumba:', error.message || error);
      setOptionsData([]); // Define como vazio em caso de erro
    }
  }, [selectedDate, nearestDueDate, selectedStock]);



  const debouncedHandleJumba = useMemo(
    () => debounce(handleJumba, 300),
    [handleJumba]
  );

  const handleSymbolChange = useCallback((symbol) => {
    handleSelectStock(symbol);
  }, [handleSelectStock]);


  useEffect(() => {

    if (!selectedDate && nearestDueDate) {
      setSelectedDate(nearestDueDate);
    }
    debouncedHandleJumba();

    return () => debouncedHandleJumba.cancel();
  }, [selectedStock, selectedDate, nearestDueDate]);

  // Processamento de dados memoizado
  const addIQToOptions = useCallback((options) => {
    return options.map(option => {
      const iq = option.qtdClLan !== 0 ? (option.qtdClTit / option.qtdClLan).toFixed(2) : '-';
      return { ...option, iq: iq === '-' ? undefined : parseFloat(iq) };
    });
  }, []);

  const optionsWithIQ = useMemo(() => addIQToOptions(filteredData), [filteredData, addIQToOptions]);

  const filteredStrikesData = useMemo(() => {
    return optionsWithIQ
      .filter(option =>
        option.posTo > 0 &&
        Number(option.prEx) >= (lastPrice * 0.5) &&
        Number(option.prEx) <= (lastPrice * 1.5)
      )
      .sort((a, b) => Number(a.prEx) - Number(b.prEx));
  }, [optionsWithIQ, lastPrice]);

  // Configurações do gráfico memoizadas
  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const option = filteredStrikesData[context.dataIndex];
            return [
              `Opção: ${option.ser}`,
              `Strike: R$ ${Number(option.prEx).toFixed(2)}`,
              `Coberto: ${option.poCob.toLocaleString()}`,
              `Trava: ${option.posTr.toLocaleString()}`,
              `Descoberto: ${option.posDe.toLocaleString()}`,
              `Total: ${option.posTo.toLocaleString()}`,
              `IQ: ${option.iq}`,
            ];
          },
        },
      },
      annotation: {
        annotations: {
          referenceLine: {
            type: 'line',
            xMin: lastPrice,
            xMax: lastPrice,
            yMin: 0,
            yMax: 'max',
            borderColor: 'white',
            borderWidth: 2,
            label: {
              display: true,
              content: `R$ ${lastPrice.toFixed(2)}`,
              enabled: true,
              position: 'top',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              color: 'white',
              font: { size: 12, weight: 'bold' },
              padding: { top: 4, bottom: 4, left: 8, right: 8 },
              borderRadius: 4,
            }
          }
        }
      },
      legend: {
        position: 'top',
        labels: {
          color: '#FFFFFF'
        }
      }
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Strike',
          color: '#FFFFFF'
        },
        ticks: {
          callback: value => parseFloat(value).toFixed(2),
          color: '#FFFFFF'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Quantidade',
          color: '#FFFFFF'
        },
        ticks: {
          callback: formatYAxis,
          color: '#FFFFFF'
        },
        grid: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        stacked: true
      }
    },
    barPercentage: 0.9,
    categoryPercentage: 0.9,
    barThickness: 20,
    maxBarThickness: 25
  }), [lastPrice, filteredStrikesData, formatYAxis]);

  const chartData = useMemo(() => ({
    labels: filteredStrikesData.map(option => option.prEx),
    datasets: [
      {
        label: 'Descoberta',
        data: filteredStrikesData.map(option => option.posDe),
        backgroundColor: '#f56565',
        stack: 'stack1',
        barThickness: 'flex',
        maxBarThickness: 50
      },
      {
        label: 'Travada',
        data: filteredStrikesData.map(option => option.posTr),
        backgroundColor: '#ecc94b',
        stack: 'stack1',
        barThickness: 'flex',
        maxBarThickness: 50
      },
      {
        label: 'Coberta',
        data: filteredStrikesData.map(option => option.poCob),
        backgroundColor: '#4FD1C5',
        stack: 'stack1',
        barThickness: 'flex',
        maxBarThickness: 50
      }
    ]
  }), [filteredStrikesData]);

  // API calls
  const fetchSummaryFromAPI = useCallback(async (filteredData) => {
    if (filteredData.length === 0) return;

    const mer = filteredData[0]?.mer;
    if (!selectedDate || !mer) return;

    try {
      // Adiciona `selectedCargaDate` na URL da chamada à API
      const response = await axios.get(
        `https://api.fatcat.app.br/jumba/resumo/${mer}/${selectedOptionType}/${selectedDate}/${selectedCargaDate}`
      );

      setLastPrice(parseFloat(response.data.price));

      setSummaryData({
        cobertas: {
          value: parseFloat(response.data.poCoberta),
          percent: parseFloat(response.data.var_poCob)
        },
        travadas: {
          value: parseFloat(response.data.poTravada),
          percent: parseFloat(response.data.var_posTr)
        },
        descobertas: {
          value: parseFloat(response.data.poDescoberta),
          percent: parseFloat(response.data.var_posDe)
        },
        iqCalls: {
          value: parseFloat(response.data.iq),
          percent: parseFloat(response.data.var_iq)
        },
        putCallRatio: {
          value: parseFloat(response.data.put_call_ratio),
          percent: parseFloat(response.data.var_put_call_ratio)
        }
      });
    } catch (error) {
      console.error('Erro ao buscar o resumo da API:', error);
    }
  }, [selectedDate, selectedOptionType, selectedCargaDate]);


  useEffect(() => {

    const cargaDates = Array.from(new Set(optionsData.map(option => option.dtCarga)));
    const mostRecentCargaDate = new Date(Math.max(...cargaDates
      .filter(date => date && !isNaN(new Date(date)))
      .map(date => new Date(date))
    ));

    if (!selectedCargaDate && mostRecentCargaDate && !isNaN(mostRecentCargaDate)) {
      setSelectedCargaDate(mostRecentCargaDate.toISOString().split('T')[0]);
    }

    const filtered = optionsData.filter(
      option =>
        (selectedDate ? option.dtVen === selectedDate : true) &&
        (selectedCargaDate ? option.dtCarga === selectedCargaDate : true) &&
        (selectedOptionType ? option.tMerc === selectedOptionType : true)
    );
    setFilteredData(filtered);
    fetchSummaryFromAPI(filtered);

  }, [selectedCargaDate, selectedOptionType, optionsData, selectedDate, fetchSummaryFromAPI]);

  const handleTickerClick = (ticker, isEnabled) => {
    if (!isEnabled) {
      setModalMessage(
        `O ativo "${ticker}" está disponível apenas para assinantes do Fatcat Pro. Assine agora por R$ 47/mês e tenha acesso a tudo, com garantia de 14 dias.`
      );
      onOpen();
    } else {
      handleSelectStock(ticker); // Atualiza o estado do selectedStock
    }
  };


  const CustomSelect = ({ value, onChange, options, placeholder }) => (
    <select
      value={value}
      onChange={onChange}
      className="
        bg-[#1a1d24] 
        text-gray-100 
        text-sm
        px-3 
        py-1.5
        border
        border-gray-700
        rounded
        focus:outline-none
        focus:border-gray-600
        min-w-[120px]
        appearance-auto
      "
    >
      {placeholder && (
        <option value="" disabled>
          {placeholder}
        </option>
      )}
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
          className={`
            bg-[#1a1d24]
            ${option.disabled ? 'text-gray-500' : 'text-gray-100'}
          `}
        >
          {option.label}
        </option>
      ))}
    </select>
  );

  return (
    <div className="w-full px-4 md:px-8 bg-gray-900 py-6">
      <Box p={4}>
        <Box mb={4}>
          <Text fontSize="lg" color="white">Mapa das Opções B3</Text>
        </Box>

        <HStack spacing={2} mb={4}>
          <Select
            value={selectedStock || ''}
            onChange={(e) => {
              const selectedTicker = e.target.value;
              const isEnabled = cliente === 1 || ['bova11', 'smal11', 'azul4', 'agro3', 'cbav3', 'mglu3', 'usim5'].includes(selectedTicker.toLowerCase());
              handleTickerClick(selectedTicker, isEnabled);
            }}
            bg="#1a1d24"
            borderColor="gray.700"
            color="white"
            size="sm"
            width="auto"
          >
            {tickersWithOptions
              .sort((a, b) => a.localeCompare(b))
              .map((ticker) => {
                const isEnabled = cliente === 1 || ['bova11', 'smal11', 'azul4', 'agro3', 'cbav3', 'mglu3', 'usim5'].includes(ticker.toLowerCase());
                return (
                  <option
                    key={ticker}
                    value={ticker}
                    style={{
                      backgroundColor: '#1a1d24',
                      color: isEnabled ? 'white' : 'gray',
                      fontStyle: isEnabled ? 'normal' : 'italic',
                    }}
                  >
                    {ticker}
                  </option>
                );
              })}
          </Select>

          <Select
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            bg="#1a1d24"
            borderColor="gray.700"
            color="white"
            size="sm"
            width="auto"
          >
            {Array.isArray(distinctDueDates) && distinctDueDates.length > 0 ?
              distinctDueDates
                .sort((a, b) => new Date(a) - new Date(b))
                .map(date => (
                  <option key={date} value={date} style={{ backgroundColor: '#1a1d24' }}>
                    {formatDate(date)}
                  </option>
                ))
              : <option value="">Sem dados</option>
            }
          </Select>

          <Select
            value={selectedOptionType}
            onChange={(e) => setSelectedOptionType(e.target.value)}
            bg="#1a1d24"
            borderColor="gray.700"
            color="white"
            size="sm"
            width="auto"
          >
            <option value="70" style={{ backgroundColor: '#1a1d24' }}>CALL</option>
            <option value="80" style={{ backgroundColor: '#1a1d24' }}>PUT</option>
          </Select>

          <Select
            value={selectedCargaDate}
            onChange={(e) => setSelectedCargaDate(e.target.value)}
            bg="#1a1d24"
            borderColor="gray.700"
            color="white"
            size="sm"
            width="auto"
          >
            {Array.from(new Set(optionsData.map(option => option.dtCarga)))
              .sort((a, b) => new Date(b) - new Date(a))
              .map(date => (
                <option key={date} value={date} style={{ backgroundColor: '#1a1d24' }}>
                  {formatDate(date)}
                </option>
              ))}
          </Select>
        </HStack>

        {/* Resto do componente ... */}
      </Box>

      {filteredStrikesData.length > 0 ? (
        <Box height="500px" width="100%">
          <Bar
            data={chartData}
            options={chartOptions}
            redraw={false}
          />
        </Box>
      ) : (
        <Box width="100%" height="50vh" display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={1}>
          <Skeleton height="60px" width="100%" isLoaded={false} />
          <Box textAlign="center" color="white" mt={4}>
            Nenhum dado encontrado para o vencimento selecionado.
          </Box>
        </Box>
      )}


      <SimpleGrid columns={5} spacing={4} mt={6}>
        <CaixaResumo
          titulo="Cobertas"
          valor={summaryData.cobertas.value}
          percentual={summaryData.cobertas.percent}
          subtitulo="Total de CALLs"
          formatarComoInteiro={true}
          selectedOptionType={selectedOptionType}
        />
        <CaixaResumo
          titulo="Travadas"
          valor={summaryData.travadas.value}
          percentual={summaryData.travadas.percent}
          subtitulo="Total de CALLs"
          formatarComoInteiro={true}
          selectedOptionType={selectedOptionType}
        />
        <CaixaResumo
          titulo="Descobertas"
          valor={summaryData.descobertas.value}
          percentual={summaryData.descobertas.percent}
          subtitulo="Total de CALLs"
          formatarComoInteiro={true}
          selectedOptionType={selectedOptionType}
        />
        <CaixaResumo
          titulo="IQ das Calls"
          valor={summaryData.iqCalls.value}
          percentual={summaryData.iqCalls.percent}
          selectedOptionType={selectedOptionType}
        />
        <CaixaResumo
          titulo="PCR Ativo"
          valor={summaryData.putCallRatio.value}
          percentual={summaryData.putCallRatio.percent}
          selectedOptionType={selectedOptionType}
        />
      </SimpleGrid>

      {filteredStrikesData.length > 0 ? (
        <OptionsTable tableData={filteredStrikesData} optionsValues={optionsValues} />
      ) : (
        <Box textAlign="center" color="white" mt={4}>
          Nenhum dado encontrado para o vencimento selecionado.
        </Box>
      )}

      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Acesso Restrito</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{modalMessage}</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={() => {
                window.open('https://pay.kiwify.com.br/Z9etzab', '_blank');
              }}
            >
              Assinar Agora
            </Button>
            <Button variant="ghost" onClick={onClose} ml={3}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default React.memo(Jumba);