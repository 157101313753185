import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  Heading,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Badge,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend, Tooltip } from 'recharts';
import ReactMarkdown from 'react-markdown';

const Fundamentus = ({ onStockSelect, cliente, setSelectTicker, stockData }) => {
  const [fundamentusStocks, setFundamentusStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchFundamentusStocks = async () => {
    try {
      const response = await fetch('https://api.fatcat.app.br/fundamentus');
      const data = await response.json();
      const sortedData = data.sort((a, b) => a.rank - b.rank);
      setFundamentusStocks(sortedData);

      if (sortedData.length > 0) {
        setSelectedStock(sortedData[0]);
        if (typeof onStockSelect === 'function') {
          onStockSelect(sortedData[0].papel);
        }
      }
    } catch (error) {
      console.error('Erro ao buscar dados Fundamentus:', error);
    }
  };

  useEffect(() => {
    if (stockData && typeof stockData === 'object') {
      setFundamentusStocks(prevStocks =>
        prevStocks.map(stock => {
          const updatedStock = stockData[stock.papel];
          if (updatedStock) {
            return {
              ...stock,
              cotacao: updatedStock.price,
              variation: updatedStock.variation,
            };
          }
          return stock;
        })
      );
    }
  }, [stockData]);

  useEffect(() => {
    fetchFundamentusStocks();
    const intervalId = setInterval(fetchFundamentusStocks, 15 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleStockClick = (stock, isAccessible) => {
    setSelectTicker(stock.papel);
    if (isAccessible) {
      setSelectedStock(stock);
      if (typeof onStockSelect === 'function') {
        onStockSelect(stock.papel);
      }
    } else {
      onOpen();
    }
  };

  const prepareChartData = () => {
    if (!selectedStock) return [];
    
    const metrics = ['ROI', 'DY', 'P/L', 'P/VP'];
    return metrics.map(metric => {
      const dataPoint = { metric };
      switch (metric) {
        case 'ROI':
          dataPoint[selectedStock.papel] = parseFloat(selectedStock.roe) || 0;
          break;
        case 'DY':
          dataPoint[selectedStock.papel] = parseFloat(selectedStock.div_yield) || 0;
          break;
        case 'P/L':
          dataPoint[selectedStock.papel] = parseFloat(selectedStock.pl) || 0;
          break;
        case 'P/VP':
          dataPoint[selectedStock.papel] = parseFloat(selectedStock.pvp) || 0;
          break;
      }
      return dataPoint;
    });
  };

  return (
    <Box bg="gray.900" p={6} borderRadius="lg">
      <VStack spacing={6} align="stretch">
        <Heading as="h3" size="md" color="white">Ações Fundamentus</Heading>

        <Flex gap={6}>
          {/* Tabela */}
          <Box flex="1.5" bg="gray.800" borderRadius="lg" overflow="hidden">
            <Box maxH="600px" overflowY="auto">
              <Table variant="unstyled" size="sm">
                <Thead position="sticky" top={0} bg="gray.800" zIndex="1">
                  <Tr borderBottom="1px" borderColor="gray.700">
                    <Th color="gray.400" fontSize="xs" py={4}>Código</Th>
                    <Th color="gray.400" fontSize="xs" isNumeric>Cotação</Th>
                    <Th color="gray.400" fontSize="xs" isNumeric>P/L</Th>
                    <Th color="gray.400" fontSize="xs" isNumeric>P/VP</Th>
                    <Th color="gray.400" fontSize="xs" isNumeric>DY</Th>
                    <Th color="gray.400" fontSize="xs" isNumeric>ROI</Th>
                    <Th color="gray.400" fontSize="xs" isNumeric>Rank</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {fundamentusStocks.map(stock => {
                    const isAccessible = cliente === 1 || stock.rank >= 7;
                    const price = stock.cotacao || '---';
                    const isSelected = selectedStock && selectedStock.papel === stock.papel;

                    return (
                      <Tr
                        key={stock.papel}
                        onClick={() => handleStockClick(stock, isAccessible)}
                        bg={isSelected ? "blue.900/30" : "transparent"}
                        _hover={{ bg: "gray.700" }}
                        cursor="pointer"
                        transition="all 0.2s"
                        opacity={isAccessible ? 1 : 0.4}
                        style={{ filter: isAccessible ? 'none' : 'blur(1px)' }}
                      >
                        <Td color="white" fontWeight={isSelected ? "bold" : "normal"}>
                          {isAccessible ? stock.papel : '---'}
                        </Td>
                        <Td color="gray.100" isNumeric>{isAccessible ? price : '---'}</Td>
                        <Td color="gray.100" isNumeric>{isAccessible ? stock.pl : '---'}</Td>
                        <Td color="gray.100" isNumeric>{isAccessible ? stock.pvp : '---'}</Td>
                        <Td color="green.400" isNumeric>{isAccessible ? `${stock.div_yield}%` : '---'}</Td>
                        <Td color="blue.400" isNumeric>{isAccessible ? `${stock.roe}%` : '---'}</Td>
                        <Td isNumeric>
                          <Badge
                            colorScheme={stock.rank <= 3 ? "green" : "gray"}
                            variant="subtle"
                            px={2}
                            borderRadius="full"
                          >
                            #{stock.rank}
                          </Badge>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </Box>

          {/* Gráfico e Análise */}
          <Box flex="1" bg="gray.800" borderRadius="lg" p={6}>
            {selectedStock ? (
              <VStack spacing={6} align="stretch">
                <Text color="white" fontSize="xl" fontWeight="bold">
                  {selectedStock.papel} - Indicadores
                </Text>
                
                <Box>
                  <RadarChart 
                    width={350} 
                    height={350} 
                    data={prepareChartData()}
                    style={{ margin: '0 auto' }}
                  >
                    <PolarGrid stroke="#2D3748" />
                    <PolarAngleAxis dataKey="metric" tick={{ fill: '#A0AEC0' }} />
                    <PolarRadiusAxis angle={30} domain={[0, 'auto']} tick={{ fill: '#A0AEC0' }} />
                    <Radar
                      name={selectedStock.papel}
                      dataKey={selectedStock.papel}
                      stroke="#3182CE"
                      fill="#3182CE"
                      fillOpacity={0.6}
                    />
                    <Legend />
                    <Tooltip contentStyle={{ backgroundColor: '#2D3748', border: 'none' }} />
                  </RadarChart>
                </Box>

                <Box bg="gray.700" p={4} borderRadius="md">
                  <Text color="white" fontSize="lg" fontWeight="bold" mb={3}>
                    Análise
                  </Text>
                  <Box color="gray.100">
                    <ReactMarkdown>
                      {selectedStock.analise || "Nenhuma análise disponível."}
                    </ReactMarkdown>
                  </Box>
                </Box>
              </VStack>
            ) : (
              <Text color="gray.400" textAlign="center">
                Selecione um ativo para ver os detalhes
              </Text>
            )}
          </Box>
        </Flex>
      </VStack>

      {/* Modal de Acesso Restrito */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="gray.800">
          <ModalHeader color="white">Acesso Restrito</ModalHeader>
          <ModalCloseButton color="white" />
          <ModalBody>
            <Text color="gray.100">
              Este ativo está disponível apenas para assinantes do Fatcat Pro.
              Assine agora por R$ 47/mês e tenha acesso a tudo, com garantia de 14 dias.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => window.open('https://pay.kiwify.com.br/Z9etzab', '_blank')}
            >
              Assinar Agora
            </Button>
            <Button variant="ghost" color="white" onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Fundamentus;