import React from 'react';
import { Box, Table, Thead, Tbody, Tr, Th, Td, Text } from '@chakra-ui/react';

const OptionsTable = ({ tableData = [], optionsValues = [] }) => {
  const sortedTableData = Array.isArray(tableData) 
    ? [...tableData].sort((a, b) => a.prEx - b.prEx) 
    : [];

  const formatCurrency = (value) => {
    return value
      ? `R$ ${parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`
      : '-';
  };

  const formatNumber = (value) => {
    return value
      ? parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 0 })
      : '0';
  };

  const formatIQ = (value) => {
    return value !== '-' && value !== undefined
      ? value.toString().replace('.', ',')
      : '0';
  };

  return (
    <Box 
      mt={6} 
      bg="gray.800" 
      borderRadius="lg" 
      overflow="hidden"
      boxShadow="xl"
    >
      <Box overflowX="auto">
        <Table variant="unstyled" size="sm">
          <Thead>
            <Tr>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                borderBottom="1px"
                borderColor="gray.600"
                py={3}
              >
                Opção
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                Strike
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                Fechamento
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                Coberto
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                Trava
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                Descoberto
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                Total
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                Titular
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                Lançador
              </Th>
              <Th 
                bg="gray.700" 
                color="gray.300" 
                fontSize="xs" 
                fontWeight="medium"
                textAlign="right"
                borderBottom="1px"
                borderColor="gray.600"
              >
                IQ
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedTableData.length > 0 ? (
              sortedTableData.map((option, index) => {
                const iq = option.qtdClLan !== 0 ? (option.qtdClTit / option.qtdClLan).toFixed(2) : '-';
                const matchedOption = optionsValues.find(opt => opt.symbol === option.ser);
                const close = matchedOption ? matchedOption.value : '-';

                return (
                  <Tr 
                    key={index}
                    _hover={{ bg: 'gray.700' }}
                    transition="background 0.2s"
                  >
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      color="gray.100"
                    >
                      <Text fontWeight="medium">{option.ser}</Text>
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      color="gray.100"
                    >
                      {formatCurrency(option.prEx)}
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      color="gray.100"
                    >
                      {formatCurrency(close)}
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      color="cyan.300"
                    >
                      {formatNumber(option.poCob)}
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      color="yellow.300"
                    >
                      {formatNumber(option.posTr)}
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      color="red.300"
                    >
                      {formatNumber(option.posDe)}
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      fontWeight="medium"
                      color="gray.100"
                    >
                      {formatNumber(option.posTo)}
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      color="gray.100"
                    >
                      {formatNumber(option.qtdClTit)}
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      color="gray.100"
                    >
                      {formatNumber(option.qtdClLan)}
                    </Td>
                    <Td 
                      borderBottom="1px" 
                      borderColor="gray.700"
                      isNumeric
                      color="gray.100"
                    >
                      {formatIQ(iq)}
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <Tr>
                <Td 
                  colSpan="10" 
                  textAlign="center"
                  color="gray.400"
                  py={8}
                >
                  Sem dados disponíveis.
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
};

export default OptionsTable;