import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import NavigationBar from './NavigationBar';
import StockTable from './StockTable';
import {
  Box,
  Heading,
  HStack,
  Text,
  Tooltip,
  VStack,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
  useColorModeValue,
  ModalCloseButton,
  useDisclosure,
  Stack,
  Skeleton,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  FormControl,
  FormLabel,
  SimpleGrid,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Select,
  Textarea, Flex, ChakraProvider
} from "@chakra-ui/react";
import { Formik, Field, Form } from 'formik';
import { ViewIcon, DeleteIcon, AddIcon, ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import './RightSidebar.css';
import { createAtivos } from '../api/ativos';
import { createSuggestion, getSuggestions, deleteSuggestion } from '../api/suggestion';
import { getAnalises } from '../api/analysis';
import PutCallRatio from './PutCallRatioGauge';
import {
  calculateCurrentQuantity,
  calculateAveragePrice,
  calculatePaidAmount,
  calculateReceivedAmount,
  calculateIntrinsicValue,
  calculatePriceCeiling,
  calculateUpsideValue
} from '../util/calculation';

import AnalysisSection from './AnalysisSection';


function RightSidebar({ onOpenAddStock, onOpenSuggestion, userId, selectedStock, handleSelectStock, stockData, tickers, selectTicker }) {
  const [stocks, setStocks] = useState([]);
  const [infoStock, setInfoStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState('quotes');
  const [analyses, setAnalyses] = useState([]);
  const { isOpen: isAnalysisOpen, onOpen: onAnalysisOpen, onClose: onAnalysisClose } = useDisclosure();
  const { isOpen: isAddListOpen, onOpen: onAddListOpen, onClose: onAddListClose } = useDisclosure();
  const { isOpen: isSuggestionOpen, onOpen: onSuggestionOpen, onClose: onSuggestionClose } = useDisclosure();
  const [selectedAnalysis, setSelectedAnalysis] = useState(null);
  const [deleteAnalysisId, setDeleteAnalysisId] = useState(null);
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const [symbols, setSymbols] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const { isOpen: isAddSuggestionOpen, onOpen: onAddSuggestionOpen, onClose: onAddSuggestionClose } = useDisclosure();
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const { isOpen: isViewSuggestionOpen, onOpen: onViewSuggestionOpen, onClose: onViewSuggestionClose } = useDisclosure();
  const [deleteSuggestionId, setDeleteSuggestionId] = useState(null);
  const [autoTabSwitchEnabled, setAutoTabSwitchEnabled] = useState(true);
  const [indicepcr, setIndicepcr] = useState(0);

  const {
    isOpen: isDeleteDialogOpen,
    onOpen: onDeleteDialogOpen,
    onClose: onDeleteDialogClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteSuggestionDialogOpen,
    onOpen: onDeleteSuggestionDialogOpen,
    onClose: onDeleteSuggestionDialogClose,
  } = useDisclosure();

  const [isCollapsed, setIsCollapsed] = useState(false); // Estado para controlar se o sidebar está recolhido ou expandido

  const toast = useToast();
  const sidebarBgColor = useColorModeValue('gray.100', 'gray.800');
  const [intrinsicValue, setIntrinsicValue] = useState(0);
  const [currentVlteto, setCurrentVlteto] = useState(6.05);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [averagePrice, setAveragePrice] = useState(0);
  const [infoData, setInfData] = useState([]);
  const [newTicker, setNewTicker] = useState(null);
  const formatNumber = (num) => {
    return (typeof num === 'number' && !isNaN(num)) ? num.toFixed(2) : '-';
  };

  const upsideValue = useMemo(() => calculateUpsideValue(infoData.price, intrinsicValue));

  const formatNumberMil = (num) => {
    if (isNaN(num)) {
      return '-'; // ou qualquer valor de fallback que você prefira
    }
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num / 1e6) + 'mi';
  };


  // Função fetchStocks para obter a lista de ativos atualizada
  const fetchStocks = async () => {
    try {
      const response = await fetch(`https://data.fatcat.app.br/signal?user_id=${userId}`);

      const data = await response.json();
      setStocks(Array.isArray(data) ? data : []); // Atualiza o estado com os novos dados
    } catch (error) {
      console.error("Error fetching stocks:", error);
      setStocks([]); // Em caso de erro, defina um array vazio
    } finally {
      setLoading(false);
    }
  };

  // Função fetchStocks para obter a lista de ativos atualizada
  const fetchPutCallRatio = async () => {
    try {
      const response = await fetch(`https://api.fatcat.app.br/jumba/put-call-ratio/${selectTicker.slice(0, 4) || selectedStock.slice(0, 4)}`);
      const data = await response.json();

      if (data && data.put_call_ratio) {
        // Converte para número
        const ratio = parseFloat(data.put_call_ratio);
        if (!isNaN(ratio)) {
          setIndicepcr(ratio); // Atualiza o estado com o número
        } else {
          console.warn("put_call_ratio is not a valid number:", data.put_call_ratio);
          setIndicepcr(0); // Valor padrão em caso de erro
        }
      } else {
        console.warn("put_call_ratio not found in API response:", data);
        setIndicepcr(0); // Valor padrão em caso de erro
      }
    } catch (error) {
      console.error("Error fetching put call ratio:", error);
      setIndicepcr(0); // Em caso de erro, defina como 0
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      await fetchPutCallRatio();
    };
    fetchData();
  }, [selectedStock, selectedTab, userId, selectTicker]);



  useEffect(() => {
    fetchStocks();
    fetchPutCallRatio();
  }, [userId]);

  const fetchInfo = async () => {
    setNewTicker(selectTicker !== null ? selectTicker : selectedStock);

    if (newTicker && selectedTab === 'infoStock') {
      try {
        const response = await fetch(`https://data.fatcat.app.br/getInfo/${selectTicker || selectedStock}`);
        const data = await response.json();
        setInfData(data); // Zera a lista se houver erro

        const intrinsicVal = calculateIntrinsicValue(infoData.LPA, infoData.VPA);
        const priceCeiling = calculatePriceCeiling(infoData.totalDividendo);

        setIntrinsicValue(intrinsicVal);
        setCurrentVlteto(priceCeiling);



      } catch (error) {
        console.error("Error fetching analyses:", error);
        setInfData([]); // Em caso de erro, zera a lista de análises
      }
    }
  };

  useEffect(() => {
    const fetchAnalyses = async () => {
      if (newTicker && selectedTab === 'analysis') {
        try {
          const data = await getAnalises(newTicker, userId);
          setAnalyses(data.message ? [] : data); // Zera a lista se houver erro
        } catch (error) {
          console.error("Error fetching analyses:", error);
          setAnalyses([]); // Em caso de erro, zera a lista de análises
        }
      }
    };

    fetchInfo();
    fetchAnalyses();
  }, [selectedStock, selectedTab, userId, selectTicker]);

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (selectedTab === 'Suggestion') {
        try {
          const data = await getSuggestions(userId);
          setSuggestions(data);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      }
    };

    fetchSuggestions();
  }, [selectedTab, userId]);

  // Função para recolher/expandir o sidebar
  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleAddStock = async (values) => {
    try {
      await createAtivos(values); // Chame a função de criação do ativo, mas sem esperar o retorno dela diretamente para atualizar
      await fetchStocks(); // Chama o fetchStocks para garantir que a lista seja recarregada com os dados mais recentes
      toast({
        title: "Ativo adicionado.",
        description: `O ativo ${values.symbol} foi adicionado com sucesso.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onAddListClose();
    } catch (error) {
      toast({
        title: "Erro ao adicionar ativo.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };


  const handleAddSuggestion = async (values) => {
    try {
      const suggestionData = { ...values, userId };
      const data = await createSuggestion(suggestionData);
      setSuggestions((prevSuggestions) => [...prevSuggestions, data]);
      toast({
        title: "Sugestão adicionada.",
        description: "Sua sugestão foi adicionada com sucesso.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onAddSuggestionClose();
    } catch (error) {
      toast({
        title: "Erro ao adicionar sugestão.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteSuggestion = async () => {
    try {
      await deleteSuggestion(deleteSuggestionId);
      setSuggestions((prevSuggestions) =>
        prevSuggestions.filter((suggestion) => suggestion.id !== deleteSuggestionId)
      );
      toast({
        title: "Sugestão excluída.",
        description: "A sugestão foi excluída com sucesso.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onDeleteSuggestionDialogClose();
    } catch (error) {
      toast({
        title: "Erro ao excluir sugestão.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleViewSuggestion = (suggestion) => {
    setSelectedSuggestion(suggestion);
    onViewSuggestionOpen();
  };

  const confirmDeleteSuggestion = (id) => {
    setDeleteSuggestionId(id);
    onDeleteSuggestionDialogOpen();
  };

  const handleSelectAnalysis = (analysis) => {
    setSelectedAnalysis(analysis);
    onAnalysisOpen();
  };

  const handleIvRank = (ivAtual, ivMinimo, ivMaximo) => {
    const ivRank = ((ivAtual - ivMinimo) / (ivMaximo - ivMinimo)) * 100;
    return ivRank;
  }

  const confirmDeleteAnalysis = (id) => {
    setDeleteAnalysisId(id);
    onDeleteDialogOpen();
  };

  const handleTabChange = (tab) => {
    setAutoTabSwitchEnabled(false); // Desativa a mudança automática
    setSelectedTab(tab); // Atualiza a aba para a escolha do usuário
  };

  const handleDeleteAnalysis = async () => {
    try {
      await fetch(`https://api.fatcat.app.br/analyses/${deleteAnalysisId}`, {
        method: "DELETE",
      });
      setAnalyses((prevAnalyses) => prevAnalyses.filter((analysis) => analysis.id !== deleteAnalysisId));
      toast({
        title: "Análise excluída.",
        description: "A análise foi excluída com sucesso.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro ao excluir análise.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      onDeleteDialogClose();
    }
  };

  useEffect(() => {
    if (newTicker !== null && autoTabSwitchEnabled) {
      setSelectedTab("infoStock");
    }
  }, [selectTicker, autoTabSwitchEnabled]);

  // const selectedStockData = useMemo(() => {
  //   const symbol = (selectTicker || selectedStock)?.replace(/['"]/g, ""); // Remover aspas, se necessário
  //   console.log(symbol);
  //   return stocks.find(stock => stock.symbol === symbol);
  // }, [selectTicker]); // Dependências para recalcular


  useEffect(() => {
    setNewTicker(selectTicker !== null ? selectTicker : selectedStock);

    const fetchAnalyses = async () => {
      if (newTicker && selectedTab === 'infoStock') {
        try {
          const response = await fetch(`https://data.fatcat.app.br/stocks?symbol=${selectTicker || selectedStock}`);
          const data = await response.json();
          setInfoStock(data);
          // Verifica se o retorno contém a mensagem de "Nenhuma análise encontrada"
          if (data.message === "Nenhuma análise encontrada para o usuário e ativo especificados.") {
            setInfoStock([]); // Zera a lista de análises
          } else {
            setInfoStock(data); // Define as análises recebidas
          }
        } catch (error) {
          console.error("Error fetching analyses:", error);
          setInfoStock([]); // Em caso de erro, zera a lista de análises
        }
      }
    };

    fetchAnalyses();
  }, [selectedStock, selectedTab, userId, selectTicker]);


  if (loading) {
    return (
      <Stack marginTop={100} marginRight={10}>
        <Skeleton height="20px" width={200} />
        <Skeleton height="20px" width={200} />
        <Skeleton height="20px" width={200} />
        <Skeleton height="20px" width={200} />
      </Stack>
    );
  }

  return (
    <Box
      className={`right-sidebar ${isCollapsed ? 'collapsed' : ''}`}
      bg={sidebarBgColor}
      w={isCollapsed ? "60px" : "300px"} // Ajusta a largura com base no estado de colapso
      transition="width 0.3s ease-in-out"
    >
      <Flex justifyContent="end" alignItems="center" p={2} marginTop={2}>
        <IconButton
          aria-label="Toggle sidebar" width={5}
          icon={isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          onClick={toggleSidebar}
        />
      </Flex>

      {!isCollapsed && (
        <NavigationBar
          onOpenAddStock={onOpenAddStock}
          onOpenSuggestion={onOpenSuggestion}
          onTabChange={handleTabChange} // Use a função modificada
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}

      {!isCollapsed && (
        <Box mt={4} w="100%">
          {selectedTab === 'quotes' && (
            <>
              {Array.isArray(stocks) && stocks.length > 0 ? (
                <StockTable
                  stocks={stocks}
                  stockData={stockData} // Passando o stockData diretamente
                  onSelectStock={handleSelectStock}
                />
              ) : (
                <Text>No stocks available.</Text>
              )}

              <IconButton
                icon={<AddIcon />}
                colorScheme="teal"
                position="fixed"
                bottom={20}
                right={4}
                borderRadius="full"
                boxSize="50px"
                onClick={onDrawerOpen}
              />
            </>
          )}

          {/* {selectedTab === 'alerts' && (
            <StockTable
              stocks={stocks.filter(stock =>
                stock.signal !== 'none' ||
                stock.sma_cross !== 'none' ||
                stock.rsi !== 'none' ||
                stock.squeeze === 'True')}
              stockData={stockData} // Passando o stockData diretamente
              onSelectStock={handleSelectStock}
            />
          )} */}

          {selectedTab === 'analysis' && newTicker && (
            <>
              <Heading size="md" mb={4}>Analysis</Heading>
              {analyses.length === 0 ? (
                <Text>No analyses available for this stock.</Text>
              ) : (
                <VStack align="start" spacing={2}>
                  {analyses.map((analysis) => (
                    <HStack
                      key={analysis.id}
                      p={2}
                      borderWidth="1px"
                      borderRadius="md"
                      width="280px"
                      justifyContent="space-between"
                    >
                      <VStack align="start" spacing={1} width="100%">
                        <Text fontSize="12px" mb={2}>{analysis.ticker}</Text>
                        <Box
                          fontSize="10px"
                          noOfLines={2}
                        >
                          {(() => {
                            try {
                              const parsedAnalysis = JSON.parse(analysis.analysis);
                              return parsedAnalysis.ai_analysis.substring(0, 300) + '...';
                            } catch (error) {
                              console.error('Erro ao parsear análise:', error);
                              return 'Erro ao carregar análise';
                            }
                          })()}
                        </Box>
                        <Text fontSize="9px" noOfLines={2}>
                          {format(new Date(analysis.created_at), "dd/MM/yyyy 'às' HH:mm:ss", { locale: ptBR })}
                        </Text>
                      </VStack>
                      <Box mb={2}>
                        <Tooltip label="View" aria-label="View Analysis">
                          <IconButton
                            aria-label="View Analysis"
                            marginBottom={2}
                            icon={<ViewIcon />}
                            onClick={() => handleSelectAnalysis(analysis)}
                          />
                        </Tooltip>
                        <Tooltip label="Remove" aria-label="Delete Analysis">
                          <IconButton
                            aria-label="Delete Analysis"
                            icon={<DeleteIcon />}
                            onClick={() => confirmDeleteAnalysis(analysis.id)}
                          />
                        </Tooltip>
                      </Box>  </HStack>
                  ))}
                </VStack>
              )}
            </>
          )}

          {selectedTab === 'Suggestion' && (
            <>
              <VStack align="start" spacing={2}>
                {suggestions.length === 0 ? (
                  <Text>No suggestions available.</Text>
                ) : (
                  suggestions.map((suggestion) => (
                    <HStack
                      key={suggestion.id}
                      p={2}
                      borderWidth="1px"
                      borderRadius="md"
                      width="280px"
                      justifyContent="space-between"
                    >
                      <VStack align="start" spacing={1} width="100%">
                        <Text fontSize="12px" mb={2}>Suggestion ID: {suggestion.id}</Text>
                        <Text fontSize="11px" noOfLines={2} mb={2}>{suggestion.suggestion}</Text>
                        <Text fontSize="9px" noOfLines={2}>Status: {suggestion.status || "Pending"}</Text>
                        <Text fontSize="9px" noOfLines={2}>Message: {suggestion.message || "No message"}</Text>
                        <Text fontSize="9px" noOfLines={2}>
                          {format(new Date(suggestion.createdAt), "dd/MM/yyyy 'às' HH:mm:ss", { locale: ptBR })}
                        </Text>
                      </VStack>
                      <Box mb={2}>
                        <Tooltip label="View" aria-label="View Suggestion">
                          <IconButton
                            aria-label="View Suggestion"
                            marginBottom={2}
                            icon={<ViewIcon />}
                            onClick={() => handleViewSuggestion(suggestion)}
                          />
                        </Tooltip>
                        <Tooltip label="Remove" aria-label="Delete Suggestion">
                          <IconButton
                            aria-label="Delete Suggestion"
                            icon={<DeleteIcon />}
                            onClick={() => confirmDeleteSuggestion(suggestion.id)}
                          />
                        </Tooltip>
                      </Box>
                    </HStack>
                  ))
                )}
              </VStack>

              <IconButton
                icon={<AddIcon />}
                colorScheme="teal"
                position="fixed"
                bottom={20}
                right={4}
                borderRadius="full"
                boxSize="50px"
                onClick={onAddSuggestionOpen}
              />
            </>
          )}

          {selectedTab === 'infoStock' && (
            <>
              <Box bg="gray.800" p={4} borderRadius="md" boxShadow="lg">
                <Text fontSize="lg" fontWeight="bold" mb={4} color="white">
                  <Tooltip label="Informações do Ativo" aria-label="Informação">
                    <span>Informações - {selectTicker || selectedStock}
                    </span>
                  </Tooltip>
                </Text>

                <Flex direction="column" gap={2}>
                  <Box bgGradient="linear(to-r, red.700, yellow.500)" p={4} color="white" borderRadius="md">

                    <Flex justify="space-between" align="center" >
                      <Text color="gray.300" fontSize={14}>IV Atual</Text>
                      <Text color="white" fontSize={14}>{infoStock.iv_current}%</Text>
                    </Flex>
                    <Flex justify="space-between" align="center" >
                      <Text color="gray.300" fontSize={14}>IV Maximo</Text>
                      <Text color="white" fontSize={14}>{formatNumber(infoStock.iv_1y_max)}%</Text>
                    </Flex>
                    <Flex justify="space-between" align="center" >
                      <Text color="gray.300" fontSize={14}>IV Mínimo</Text>
                      <Text color="white" fontSize={14}>{formatNumber(infoStock.iv_1y_min)}%</Text>
                    </Flex>
                    <Flex justify="space-between" align="center" >
                      <Text color="gray.300" fontSize={14}>IV Rank</Text>
                      <Text color="white" fontSize={14}>{formatNumber(handleIvRank(infoStock.iv_current, infoStock.iv_1y_min, infoStock.iv_1y_max))}%</Text>
                    </Flex>
                    <Flex justify="space-between" align="center" >
                      <Text color="gray.300" fontSize={14}>IV Percentil</Text>
                      <Text color="white" fontSize={14}>{formatNumber(infoStock.ewma_1y_percentile)}%</Text>
                    </Flex>
                    <Flex justify="space-between" align="center" >
                      <Text color="gray.300" fontSize={14}>Volume Financeiro</Text>
                      <Text color="white" fontSize={14}>{formatNumberMil(infoStock.financial_volume)}</Text>
                    </Flex>
                    <Flex justify="space-between" align="center" >
                      <Text color="gray.300" fontSize={14}>Beta</Text>
                      <Text color="white" fontSize={14}>{formatNumber(infoStock.beta_ibov * 100)}%</Text>
                    </Flex>
                    <Flex justify="space-between" align="center" >
                      <Text color="gray.300" fontSize={14}>Correlação Bovespa</Text>
                      <Text color="white" fontSize={14}>{formatNumber(infoStock.correl_ibov * 100)}%</Text>
                    </Flex>
                  </Box>

                  <Box bgGradient="linear(to-r, green.700, blue.700)" p={4} color="white" borderRadius="md">
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold" fontSize="sm">Valor Atual</Text>
                      <Text fontSize="sm">
                        {formatNumber(infoData.price) || "N/A"}
                      </Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold" fontSize="sm">Valor Intrínseco</Text>
                      <Text fontSize="sm">{formatNumber(intrinsicValue)}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold" fontSize="sm">Valor Teto</Text>
                      <Text fontSize="sm">{formatNumber(currentVlteto)}</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold" fontSize="sm">Up Side</Text>
                      <Text fontSize="sm">{formatNumber(upsideValue)} %</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold" fontSize="sm">LPA</Text>
                      <Text fontSize="sm">{formatNumber(infoData.LPA)} %</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold" fontSize="sm">VPA</Text>
                      <Text fontSize="sm">{formatNumber(infoData.VPA)} %</Text>
                    </Flex>
                    <Flex justifyContent="space-between">
                      <Text fontWeight="bold" fontSize="sm">Dividend Yield</Text>
                      <Text fontSize="sm">{formatNumber(infoData.DividendYield * 100)} %</Text>
                    </Flex>
                  </Box>

                  <PutCallRatio value={indicepcr || 0} />


                </Flex>
              </Box>
            </>
          )}
        </Box>
      )}

      {/* Drawer para adicionar ativo */}
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add New Stock</DrawerHeader>
          <DrawerBody h={200}>
            <Formik
              initialValues={{
                symbol: "",
                bolsa: "STOCK",
                vpa: "",
                lpa: "",
                vl_intrinseco: "",
                vl_teto: "",
                dividend_yeld: "",
                idUser: userId,
                listId: 0
              }}
              onSubmit={(values, actions) => {
                handleAddStock(values);
                actions.setSubmitting(false);
              }}
            >
              {({ setFieldValue, values }) => (
                <Form>
                  <SimpleGrid>
                    <Field name="symbol">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel>Symbol</FormLabel>
                          <Select {...field} placeholder="Select symbol">
                            {Array.isArray(tickers) && tickers.length > 0 ? (
                              tickers.map((ticker, index) => (
                                ticker && ticker ? (
                                  <option key={ticker || `ticker-${index}`} value={ticker}>
                                    {ticker}
                                  </option>
                                ) : null
                              ))
                            ) : (
                              <option key="no-symbols" disabled>No symbols available</option>
                            )}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </SimpleGrid>
                  <DrawerFooter mb={2} mt={20}>
                    <HStack spacing={6}>
                      <Button colorScheme="teal" type="submit">Add Stock</Button>
                      <Button variant="outline" mr={3} onClick={onDrawerClose}>Cancel</Button>
                    </HStack>
                  </DrawerFooter>
                </Form>
              )}
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      {/* Drawer para adicionar sugestão */}
      <Drawer isOpen={isAddSuggestionOpen} placement="right" onClose={onAddSuggestionClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Suggestion</DrawerHeader>
          <DrawerBody>
            <Formik
              initialValues={{ suggestion: "" }}
              onSubmit={(values, actions) => {
                handleAddSuggestion(values);
                actions.setSubmitting(false);
              }}
            >
              {({ handleChange, values }) => (
                <Form>
                  <FormControl>
                    <FormLabel>Suggestion</FormLabel>
                    <Textarea
                      name="suggestion"
                      value={values.suggestion}
                      onChange={handleChange}
                      placeholder="Enter your suggestion"
                    />
                  </FormControl>
                  <DrawerFooter>
                    <Button colorScheme="teal" type="submit">Add Suggestion</Button>
                  </DrawerFooter>
                </Form>
              )}
            </Formik>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Drawer isOpen={isAnalysisOpen} placement="right" onClose={onAnalysisClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Detalhes da Análise</DrawerHeader>
          <DrawerBody>
            {selectedAnalysis && (
              <AnalysisSection
                analysis={JSON.parse(selectedAnalysis.analysis)}  // Removido o .analysis extra
                selectedStock={selectedAnalysis.ticker}
              />
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onAnalysisClose}>
              Fechar
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      {/* Modal for viewing suggestion */}
      <Modal isOpen={isViewSuggestionOpen} onClose={onViewSuggestionClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Suggestion Detail</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedSuggestion && (
              <Box
                dangerouslySetInnerHTML={{ __html: selectedSuggestion.suggestion }}
                whiteSpace="pre-wrap"
                lineHeight="1.5"
              />
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onViewSuggestionClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* AlertDialog for delete confirmation */}
      <AlertDialog isOpen={isDeleteDialogOpen} leastDestructiveRef={null} onClose={onDeleteDialogClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar Análise
            </AlertDialogHeader>
            <AlertDialogBody>
              Tem certeza que deseja deletar esta análise? Esta ação não pode ser desfeita.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onDeleteDialogClose}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAnalysis} ml={3}>
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* AlertDialog for suggestion delete confirmation */}
      <AlertDialog isOpen={isDeleteSuggestionDialogOpen} leastDestructiveRef={null} onClose={onDeleteSuggestionDialogClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Deletar Sugestão
            </AlertDialogHeader>
            <AlertDialogBody>
              Tem certeza que deseja deletar esta sugestão? Esta ação não pode ser desfeita.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onDeleteSuggestionDialogClose}>
                Cancelar
              </Button>
              <Button colorScheme="red" onClick={handleDeleteSuggestion} ml={3}>
                Deletar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default RightSidebar;