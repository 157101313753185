import React, { useState, useEffect, memo } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { 
  BarChart2, 
  RefreshCw, 
  Filter,
  TrendingUp,
  Trophy,
  Search,
  Clock 
} from 'lucide-react';
import {
  Box,
  Container,
  Heading,
  Text,
  Select,
  Grid,
  IconButton,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Card,
  CardBody,
  Flex,
  HStack,
  VStack,
  Badge,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';

const FilterDrawerContent = memo(({ 
  isOpen, 
  onClose, 
  data,
  filters,
  setFilters
}) => (
  <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
    <DrawerOverlay />
    <DrawerContent bg="gray.800">
      <DrawerCloseButton color="white" />
      <DrawerHeader borderBottomWidth="1px" borderColor="gray.700" color="white">
        Filtros Avançados
      </DrawerHeader>
      <DrawerBody>
        <VStack spacing={6} py={4}>
          <Box w="full">
            <Text mb={2} fontWeight="medium" color="white">Ativo Subjacente</Text>
            <Select
              value={filters.underlying}
              onChange={(e) => setFilters(prev => ({ ...prev, underlying: e.target.value }))}
              bg="gray.900"
              borderColor="gray.700"
              color="white"
              _hover={{ borderColor: 'gray.600' }}
            >
              <option value="">Todos os Ativos</option>
              {[...new Set(data.map(item => item.underlying))].map(underlying => (
                <option key={underlying} value={underlying}>{underlying}</option>
              ))}
            </Select>
          </Box>

          <Box w="full">
            <Text mb={2} fontWeight="medium" color="white">Tipo de Opção</Text>
            <Select
              value={filters.type}
              onChange={(e) => setFilters(prev => ({ ...prev, type: e.target.value }))}
              bg="gray.900"
              borderColor="gray.700"
              color="white"
              _hover={{ borderColor: 'gray.600' }}
            >
              <option value="">Todos os Tipos</option>
              <option value="CALL">CALL</option>
              <option value="PUT">PUT</option>
            </Select>
          </Box>

          <Box w="full">
            <Text mb={2} fontWeight="medium" color="white">Vencimento</Text>
            <Select
              value={filters.maturity}
              onChange={(e) => setFilters(prev => ({ ...prev, maturity: e.target.value }))}
              bg="gray.900"
              borderColor="gray.700"
              color="white"
              _hover={{ borderColor: 'gray.600' }}
            >
              <option value="">Todos os Vencimentos</option>
              {[...new Set(data.map(item => item.days_to_maturity))]
                .sort((a, b) => a - b)
                .map(dtm => (
                  <option key={dtm} value={dtm}>{dtm} dias</option>
                ))}
            </Select>
          </Box>

          <Box w="full">
            <Flex align="center" mb={2}>
              <TrendingUp size={16} color="white" />
              <Text ml={2} fontWeight="medium" color="white">
                VE Mínimo: {filters.veMin}%
              </Text>
            </Flex>
            <Slider
              value={filters.veMin}
              onChange={(value) => setFilters(prev => ({ ...prev, veMin: value }))}
              min={0}
              max={100}
              colorScheme="blue"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>

          <Box w="full">
            <Flex align="center" mb={2}>
              <Trophy size={16} color="white" />
              <Text ml={2} fontWeight="medium" color="white">
                POP Mínimo: {filters.popMin}%
              </Text>
            </Flex>
            <Slider
              value={filters.popMin}
              onChange={(value) => setFilters(prev => ({ ...prev, popMin: value }))}
              min={0}
              max={100}
              colorScheme="blue"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>

          <Box w="full">
            <Flex align="center" mb={2}>
              <Clock size={16} color="white" />
              <Text ml={2} fontWeight="medium" color="white">
                DTM Mínimo: {filters.dtmMin}d
              </Text>
            </Flex>
            <Slider
              value={filters.dtmMin}
              onChange={(value) => setFilters(prev => ({ ...prev, dtmMin: value }))}
              min={0}
              max={90}
              colorScheme="blue"
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
          </Box>
        </VStack>
      </DrawerBody>
    </DrawerContent>
  </Drawer>
));

const StatCard = ({ icon: Icon, title, value, subtitle, trend, trendUp }) => (
  <Card bg="gray.800" border="1px solid" borderColor="gray.700">
    <CardBody>
      <VStack align="start" spacing={1}>
        <HStack color="gray.400" spacing={2}>
          <Icon size={16} />
          <Text fontSize="sm">{title}</Text>
        </HStack>
        <HStack spacing={2} align="baseline">
          <Text fontSize="2xl" fontWeight="bold" color="white">
            {value}
          </Text>
          {trend && (
            <Badge 
              colorScheme={trendUp ? 'green' : 'red'} 
              fontSize="xs"
              variant="subtle"
            >
              {trendUp ? '↑' : '↓'} {trend}
            </Badge>
          )}
        </HStack>
        <Text fontSize="xs" color="blue.200">{subtitle}</Text>
      </VStack>
    </CardBody>
  </Card>
);

const OptionScanner = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  
  const [filters, setFilters] = useState({
    underlying: '',
    type: '',
    maturity: '',
    veMin: 13,
    popMin: 33,
    dtmMin: 23
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.fatcat.app.br/opoptions');
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const item = payload[0].payload;
      return (
        <Box bg="gray.800" p={3} border="1px solid" borderColor="gray.700" borderRadius="md">
          <VStack align="start" spacing={2}>
            <HStack justify="space-between" w="full">
              <Text color="white" fontWeight="bold">{item.symbol}</Text>
              <Badge colorScheme={item.type === 'CALL' ? 'green' : 'red'}>
                {item.type}
              </Badge>
            </HStack>
            <Grid templateColumns="repeat(2, 1fr)" gap={3}>
              <Box>
                <Text color="gray.400" fontSize="xs">VE / Strike</Text>
                <Text color="white">{Number(item.ve_over_strike).toFixed(2)}%</Text>
              </Box>
              <Box>
                <Text color="gray.400" fontSize="xs">POP</Text>
                <Text color="white">{Number(item.pop).toFixed(2)}%</Text>
              </Box>
              <Box>
                <Text color="gray.400" fontSize="xs">Strike</Text>
                <Text color="white">{Number(item.strike).toFixed(2)}</Text>
              </Box>
              <Box>
                <Text color="gray.400" fontSize="xs">Volume</Text>
                <Text color="white">{Number(item.financial_volume).toLocaleString()}</Text>
              </Box>
            </Grid>
          </VStack>
        </Box>
      );
    }
    return null;
  };

  const filteredData = data
    .filter(item => item.symbol.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter(item => (!filters.underlying || item.underlying === filters.underlying))
    .filter(item => (!filters.type || item.type === filters.type))
    .filter(item => (!filters.maturity || item.days_to_maturity === Number(filters.maturity)))
    .filter(item => Number(item.ve_over_strike) >= filters.veMin)
    .filter(item => Number(item.pop) >= filters.popMin)
    .filter(item => item.days_to_maturity >= filters.dtmMin);

  return (
    <Box bg="gray.900" minH="100vh">
      <Container maxW="8xl" py={8}>
        <Flex justify="space-between" align="center" mb={8}>
          <Box>
            <Heading size="lg" color="white">Scanner de Opções</Heading>
            <Text color="gray.400">Análise de Volatilidade e Oportunidades</Text>
          </Box>
          <HStack spacing={2}>
            <IconButton
              icon={<Filter size={18} />}
              aria-label="Filtros"
              onClick={onOpen}
              variant="outline"
              colorScheme="gray"
              borderColor="gray.600"
              _hover={{ bg: 'gray.700' }}
            />
            <IconButton
              icon={<RefreshCw size={18} />}
              aria-label="Atualizar"
              onClick={() => {
                setFilters({
                  underlying: '',
                  type: '',
                  maturity: '',
                  veMin: 13,
                  popMin: 33,
                  dtmMin: 23
                });
                setSearchTerm('');
              }}
              variant="outline"
              colorScheme="gray"
              borderColor="gray.600"
              _hover={{ bg: 'gray.700' }}
            />
          </HStack>
        </Flex>

        <Grid templateColumns="repeat(3, 1fr)" gap={6} mb={6}>
          <StatCard
            icon={BarChart2}
            title="Total de Opções"
            value={filteredData.length}
            subtitle="Após filtros aplicados"
          />
          <StatCard
            icon={TrendingUp}
            title="VE Médio"
            value={`${(filteredData.reduce((acc, item) => acc + Number(item.ve_over_strike), 0) / filteredData.length || 0).toFixed(2)}%`}
            subtitle="Das opções filtradas"
            trend="2.5%"
            trendUp={true}
          />
          <StatCard
            icon={Trophy}
            title="POP Médio"
            value={`${(filteredData.reduce((acc, item) => acc + Number(item.pop), 0) / filteredData.length || 0).toFixed(2)}%`}
            subtitle="Das opções filtradas"
            trend="1.2%"
            trendUp={false}
          />
        </Grid>

        <Card bg="gray.800" mb={6} border="1px solid" borderColor="gray.700">
          <CardBody>
            <VStack spacing={4}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Search size={18} color="gray" />
                </InputLeftElement>
                <Input
                  placeholder="Buscar opções..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  bg="gray.900"
                  border="1px solid"
                  borderColor="gray.700"
                  _hover={{ borderColor: 'gray.600' }}
                  _focus={{ borderColor: 'blue.400', boxShadow: 'none' }}
                  color="white"
                />
              </InputGroup>
              
              <Box h="400px" w="full">
                <ResponsiveContainer>
                  <BarChart data={filteredData}>
                    <CartesianGrid 
                      strokeDasharray="3 3" 
                      stroke="rgba(255,255,255,0.1)"
                      vertical={false}
                    />
                    <XAxis 
                      dataKey="symbol" 
                      stroke="gray"
                      tick={{ fill: 'gray' }}
                    />
                    <YAxis 
                      stroke="gray"
                      tick={{ fill: 'gray' }}
                      tickFormatter={(value) => `${value}%`}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar 
                      dataKey="ve_over_strike" 
                      fill="#3182CE"
                      radius={[4, 4, 0, 0]}
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </VStack>
          </CardBody>
        </Card>

        <Card bg="gray.800" border="1px solid" borderColor="gray.700">
          <CardBody p={0}>
            <Box overflowX="auto">
              <Table variant="simple">
                <Thead bg="gray.900">
                  <Tr>
                    <Th color="gray.400">SYMBOL</Th>
                    <Th isNumeric color="gray.400">SPOT</Th>
                    <Th isNumeric color="gray.400">VOLUME</Th>
                    <Th color="gray.400">TIPO</Th>
                    <Th isNumeric color="gray.400">STRIKE</Th>
                    <Th isNumeric color="gray.400">PROTEÇÃO</Th>
                    <Th isNumeric color="gray.400">BREAKEVEN</Th>
                    <Th isNumeric color="gray.400">POP</Th>
                    <Th isNumeric color="gray.400">VE</Th>
                    <Th isNumeric color="gray.400">DTM</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredData.map((item) => (
                    <Tr 
                      key={item.id} 
                      _hover={{ bg: 'gray.700' }}
                      transition="background 0.2s"
                    >
                      <Td color="white">{item.symbol}</Td>
                      <Td isNumeric color="white">{Number(item.spot_price).toFixed(2)}</Td>
                      <Td isNumeric color="white">{Number(item.financial_volume).toLocaleString()}</Td>
                      <Td>
                        <Badge 
                          colorScheme={item.type === 'CALL' ? 'green' : 'red'}
                          variant="solid"
                        >
                          {item.type}
                        </Badge>
                      </Td>
                      <Td isNumeric color="white">{Number(item.strike).toFixed(2)}</Td>
                      <Td isNumeric color={Number(item.protection) > 0 ? 'green.300' : 'red.300'}>
                        {Number(item.protection).toFixed(2)}%
                      </Td>
                      <Td isNumeric color="white">{Number(item.breakeven).toFixed(2)}%</Td>
                      <Td isNumeric color={Number(item.pop) >= 50 ? 'green.300' : 'white'}>
                        {Number(item.pop).toFixed(2)}%
                      </Td>
                      <Td isNumeric color={Number(item.ve_over_strike) >= filters.veMin ? 'green.300' : 'white'}>
                        {Number(item.ve_over_strike).toFixed(2)}%
                      </Td>
                      <Td isNumeric color="white">{item.days_to_maturity}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </CardBody>
        </Card>

        <FilterDrawerContent
          isOpen={isOpen}
          onClose={onClose}
          data={data}
          filters={filters}
          setFilters={setFilters}
        />
      </Container>
    </Box>
  );
};

export default OptionScanner;