import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  Grid,
  Heading,
  Select,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  IconButton,
  HStack,
  Image,
  useDisclosure,
  Spinner,
  Center,
  Text,
  Flex,
  VStack,
  Icon,

} from '@chakra-ui/react';
import TradingViewChart from './TradingViewChart';
import WebSocketManager from './WebSocketManager';
import { ViewIcon, TimeIcon, TriangleUpIcon, TriangleDownIcon, RepeatIcon, BellIcon } from '@chakra-ui/icons';

const Scanner = ({ cliente, onSelectTab, onSelectStock, setSelectTicker }) => {
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [increasedStocks, setIncreasedStocks] = useState([]);
  const [decreasedStocks, setDecreasedStocks] = useState([]);
  const [webSocketManager, setWebSocketManager] = useState(null);
  const [previousSymbols, setPreviousSymbols] = useState([]);
  const [signalStocks, setSignalStocks] = useState([]);
  const [volumeStocks, setVolumeStocks] = useState([]);
  const [selectedSignalType, setSelectedSignalType] = useState('');
  const [currentImage, setCurrentImage] = useState(null);
  const [currentImageTicker, setCurrentImageTicker] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isImageLoading, setIsImageLoading] = useState(false);


  const fetchSignalStocks = async () => {
    try {
      const response = await fetch('https://api.fatcat.app.br/signal');
      const data = await response.json();
      setSignalStocks(data);
    } catch (error) {
      console.error('Erro ao buscar dados de sinais:', error);
    }
  };

  const fetchStocks = async () => {
    try {
      const response = await fetch('https://api.fatcat.app.br/outlier');
      const data = await response.json();
      setStocks(data);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    }
  };

  const fetchVolumeStocks = async () => {
    try {
      const response = await fetch('https://api.fatcat.app.br/volume');
      const data = await response.json();
      setVolumeStocks(data);
    } catch (error) {
      console.error('Erro ao buscar dados de volume:', error);
    }
  };

  const handleViewImage = async (signalId, e) => {
    e.stopPropagation();
    // Primeiro limpa a imagem atual e abre o modal
    setCurrentImage(null);
    setCurrentImageTicker(signalId);
    setIsImageLoading(true);
    onOpen(); // Abre o modal antes de começar a carregar

    try {
      const response = await fetch(`https://api.fatcat.app.br/signal/image/${signalId}`);
      if (!response.ok) {
        throw new Error('Erro ao buscar imagem');
      }

      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setCurrentImage(imageUrl);
    } catch (error) {
      console.error('Erro ao buscar imagem:', error);
    } finally {
      setIsImageLoading(false);
    }
  };




  // Limpa a URL do objeto quando o modal é fechado
  const handleCloseModal = () => {
    onClose();
    setIsImageLoading(false); // Reseta o estado de loading
    if (currentImage) {
      URL.revokeObjectURL(currentImage);
      setCurrentImage(null);
    }
  };

  const handleStockSelect = (symbol) => {
    setSelectedStock(symbol); // Isso apenas atualiza o gráfico local
    setSelectTicker(symbol);

  };

  const strategyMapping = {
    'BreakoutS': 'breakout',
    'BreakoutL': 'breakout',
    'TurtleS': 'turtle',
    'TurtleL': 'turtle',
    'Squeeze': 'squeeze',
    'SqueezeS': 'squeeze',
    'SqueezeL': 'squeeze',
    'IFR': 'ifr',
    'Kairi': 'kairi',
    'Darvas': 'darvas'
  };

  // Mantenha o handleBacktestClick separado para lidar com o backtest
  const handleBacktestClick = (symbol, signalType, e) => {
    e.stopPropagation(); // Previne a propagação do clique para a linha
    const mappedStrategy = strategyMapping[signalType];
    const stockData = {
      symbol: symbol,
      strategy: `${mappedStrategy}_strategy` // Adiciona _strategy ao nome
    };
    onSelectStock(stockData); // Passa o objeto completo
    setSelectTicker(symbol);
    onSelectTab('backtest');
  };

  const handleWebSocketMessage = useCallback((priceData) => {
    setStocks((prevStocks) => {
      const updatedStocks = [...prevStocks];

      priceData.forEach(({ ticker, value, variation }) => {
        const cleanTicker = ticker.replace('.SA', '');

        const stockIndex = updatedStocks.findIndex(stock => stock.symbol === cleanTicker);
        if (stockIndex !== -1) {
          updatedStocks[stockIndex] = {
            ...updatedStocks[stockIndex],
            price_change: variation,
            last_price: value,
          };
        }
      });

      return updatedStocks;
    });
  }, []);

  const handleWebSocketError = useCallback((error) => {
    console.error('Scanner: WebSocket error:', error);
  }, []);

  useEffect(() => {
    fetchStocks();
    fetchVolumeStocks();
    fetchSignalStocks();

    const newWebSocketManager = new WebSocketManager(handleWebSocketMessage, handleWebSocketError);
    newWebSocketManager.connect();
    setWebSocketManager(newWebSocketManager);

    return () => {
      if (newWebSocketManager) {
        newWebSocketManager.close();
      }
    };
  }, [handleWebSocketMessage, handleWebSocketError]);

  useEffect(() => {
    const updateStocksAndWebSocket = async () => {
      await fetchStocks();
      await fetchVolumeStocks();
      await fetchSignalStocks();

      if (webSocketManager) {
        const currentSymbols = stocks.map(stock => `${stock.symbol}|S`);
        const newSymbols = currentSymbols.filter(symbol => !previousSymbols.includes(symbol));
        const removedSymbols = previousSymbols.filter(symbol => !currentSymbols.includes(symbol));

        if (newSymbols.length > 0) {
          webSocketManager.subscribeToSymbols(newSymbols);
        }

        if (removedSymbols.length > 0) {
          webSocketManager.unsubscribeFromSymbols(removedSymbols);
        }

        setPreviousSymbols(currentSymbols);
      }
    };

    const intervalId = setInterval(updateStocksAndWebSocket, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [stocks, webSocketManager, previousSymbols]);

  useEffect(() => {
    const increased = stocks.filter(stock => parseFloat(stock.price_change) > 3);
    const decreased = stocks.filter(stock => parseFloat(stock.price_change) < -3);
    setIncreasedStocks(increased);
    setDecreasedStocks(decreased);
  }, [stocks]);

  const handleSignalTypeChange = (event) => {
    setSelectedSignalType(event.target.value);
  };

  const filteredSignalStocks = signalStocks.filter(stock =>
    selectedSignalType === '' || stock.typeSignal === selectedSignalType
  );

  return (
    <Box p={4}>
      <Grid templateColumns="repeat(5, 1fr)" gap={6}  >

        <Box>
          <Box
            bg="#2f3340"
            rounded="lg"
            p={6}
            minW="280px"
            h="full"
          >
            <Flex alignItems="center" justifyContent="space-between" mb={4}>
              <HStack spacing={2}>
                <Icon as={TriangleUpIcon} color="green.500" />
                <Text fontSize="lg" fontWeight="semibold" color="white">
                  Subindo + 3%
                </Text>
              </HStack>
              <Text fontSize="xs" color="gray.400">
                {increasedStocks.length} ativos
              </Text>
            </Flex>
            <Box maxH="400px" overflowY="auto" pr={2}>

              <VStack overflowY="auto" pr={2} spacing={2}>
                {increasedStocks.map(stock => (
                  <Flex
                    key={`increased-${stock.symbol}`}
                    w="100%"
                    bg="gray.700"
                    p={3}
                    rounded="md"
                    justify="space-between"
                    align="center"
                    cursor="pointer"
                    onClick={() => handleStockSelect(stock.symbol)}
                    _hover={{ bg: 'gray.600' }}
                  >
                    <Text fontWeight="medium" color="white">
                      {stock.symbol}
                    </Text>
                    <HStack spacing={4}>
                      <Text color="green.400">
                        +{parseFloat(stock.price_change).toFixed(2)}%
                      </Text>
                      <Text fontSize="xs" color="gray.400">
                        #{stock.rank}
                      </Text>
                    </HStack>
                  </Flex>
                ))}
              </VStack>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box
              bg="#2f3340"
              rounded="lg"
              p={6}
              minW="280px"
              h="full"
            >
              <Flex alignItems="center" justifyContent="space-between" mb={6}>
                <HStack spacing={3}>
                  <Icon as={TriangleDownIcon} color="red.500" w={4} h={4} />
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    Caíram + 3%
                  </Text>
                </HStack>
                <Text fontSize="sm" color="gray.400">
                  {decreasedStocks.length} ativos
                </Text>
              </Flex>

              {/* Container com scroll */}
              <Box maxH="400px" overflowY="auto" pr={2}>
                <VStack spacing={3}>
                  {decreasedStocks.map(stock => (
                    <Flex
                      key={`decreased-${stock.symbol}`}
                      w="100%"
                      bg="gray.700"
                      px={4}
                      py={3}
                      rounded="md"
                      justify="space-between"
                      align="center"
                      cursor="pointer"
                      onClick={() => handleStockSelect(stock.symbol)}
                      _hover={{ bg: 'gray.600' }}
                      transition="all 0.2s"
                    >
                      <Text fontWeight="medium" color="white" fontSize="sm">
                        {stock.symbol}
                      </Text>
                      <HStack spacing={4}>
                        <Text color="red.400" fontSize="sm">
                          {parseFloat(stock.price_change).toFixed(2)}%
                        </Text>
                        <Text fontSize="sm" color="gray.400">
                          #{stock.rank}
                        </Text>
                      </HStack>
                    </Flex>
                  ))}
                </VStack>
              </Box>
            </Box>
          </Box>





          <Box>
            <Box
              bg="#2f3340"
              rounded="lg"
              p={6}
              minW="280px"
              h="full"
            >
              <Flex alignItems="center" justifyContent="space-between" mb={6}>
                <HStack spacing={3}>
                  <Icon as={RepeatIcon} color="purple.500" w={4} h={4} />
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    Volume Aumentado
                  </Text>
                </HStack>
                <Text fontSize="sm" color="gray.400">
                  {volumeStocks.length} ativos
                </Text>
              </Flex>

              <Box maxH="400px" overflowY="auto" pr={2}>
                <VStack spacing={3}>
                  {volumeStocks.map(stock => (
                    <Flex
                      key={`volume-${stock.symbol}`}
                      w="100%"
                      bg="gray.700"
                      px={4}
                      py={3}
                      rounded="md"
                      justify="space-between"
                      align="center"
                      cursor="pointer"
                      onClick={() => handleStockSelect(stock.symbol)}
                      _hover={{ bg: 'gray.600' }}
                      transition="all 0.2s"
                    >
                      <Text fontWeight="medium" color="white" fontSize="sm">
                        {stock.symbol}
                      </Text>
                      <HStack spacing={4} align="center">
                        <Text color="gray.300" fontSize="sm">
                          {stock.volume.toLocaleString()}
                        </Text>
                        <Text color="purple.400" fontSize="sm">
                          +{stock.volume_increase.toFixed(2)}%
                        </Text>
                        <Text fontSize="sm" color="gray.400">
                          #{stock.rank}
                        </Text>
                      </HStack>
                    </Flex>
                  ))}
                </VStack>
              </Box>
            </Box>
          </Box>

          <Box>
            <Box
              bg="#2f3340"
              rounded="lg"
              p={6}
              minW="320px"
              h="full"
            >
              <Flex alignItems="center" justifyContent="space-between" mb={6}>
                <HStack spacing={3}>
                  <Icon as={BellIcon} color="green.500" w={4} h={4} />
                  <Text fontSize="md" fontWeight="semibold" color="white">
                    Sinais
                  </Text>
                </HStack>
                <Select
                  placeholder="Todos Sinais"
                  onChange={handleSignalTypeChange}
                  w="160px"
                  size="sm"
                  bg="gray.700"
                  borderColor="gray.600"
                  color="gray.100"
                  _hover={{ borderColor: 'gray.500' }}
                  _focus={{ borderColor: 'green.500', boxShadow: 'none' }}
                >
                  <option value="BreakoutS">BreakoutS</option>
                  <option value="BreakoutL">BreakoutL</option>
                  <option value="Darvas">Darvas</option>
                  <option value="TurtleS">TurtleS</option>
                  <option value="TurtleL">TurtleL</option>
                  <option value="Squeeze">Squeeze</option>
                  <option value="SqueezeS">SqueezeS</option>
                  <option value="SqueezeL">SqueezeL</option>
                  <option value="IFR">IFR</option>
                  <option value="Kairi">Kairi</option>
                </Select>
              </Flex>

              <Box maxH="400px" overflowY="auto" pr={2}>
                <VStack spacing={3}>
                  {filteredSignalStocks.map(stock => (
                    <Flex
                      key={`signal-${stock.ticker}-${stock.typeSignal}`}
                      w="100%"
                      bg="gray.700"
                      px={4}
                      py={3}
                      rounded="md"
                      justify="space-between"
                      align="center"
                      _hover={{ bg: 'gray.600' }}
                      transition="all 0.2s"
                    >
                      <Text
                        fontWeight="medium"
                        color="white"
                        fontSize="sm"
                        cursor="pointer"
                        onClick={() => handleStockSelect(stock.ticker)}
                      >
                        {stock.ticker}
                      </Text>
                      <HStack spacing={4} align="center">
                        <Text color="gray.300" fontSize="sm">
                          {stock.typeSignal}
                        </Text>
                        <Text fontSize="sm" color="gray.400">
                          #{stock.rank}
                        </Text>
                        <HStack spacing={1}>
                          <IconButton
                            size="xs"
                            icon={<TimeIcon />}
                            aria-label="Backtest"
                            onClick={(e) => handleBacktestClick(stock.ticker, stock.typeSignal, e)}
                            color="blue.400"
                            variant="ghost"
                            _hover={{ bg: 'gray.600', color: 'blue.300' }}
                          />
                          <IconButton
                            size="xs"
                            icon={<ViewIcon />}
                            aria-label="Ver imagem"
                            onClick={(e) => handleViewImage(stock.id, e)}
                            color="green.400"
                            variant="ghost"
                            _hover={{ bg: 'gray.600', color: 'green.300' }}
                          />
                        </HStack>
                      </HStack>
                    </Flex>
                  ))}
                </VStack>
              </Box>
            </Box>
          </Box>
      </Grid>

      <Box mt={10}>
        {selectedStock ? (
          <TradingViewChart simbolo={selectedStock} />
        ) : (
          <Box>
            <Heading as="h3" size="md">Clique em um código para visualizar o gráfico</Heading>
          </Box>
        )}
      </Box>

      <Modal isOpen={isOpen} onClose={handleCloseModal} size="xl">
        <ModalOverlay />
        <ModalContent maxW="90vw">
          <ModalHeader>Sinal - {currentImageTicker}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Center py={8}>
              {isImageLoading ? (
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              ) : currentImage ? (
                <Image
                  src={currentImage}
                  alt={`Sinal ${currentImageTicker}`}
                  maxW="100%"
                  maxH="80vh"
                  mx="auto"
                />
              ) : (
                <Text>Nenhuma imagem carregada</Text>
              )}
            </Center>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Scanner;