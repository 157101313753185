// Traduções para múltiplos idiomas
const translations = {
    en: {
      connectionError: "Connection error",
      connectionErrorDescription: "There was an error with the WebSocket connection. Try reloading the page.",
      stockError: "Error loading stocks",
      stockErrorDescription: "Unable to load available stock list.",
      transactionDeleted: "Transaction deleted",
      transactionDeletedDescription: "The transaction was successfully deleted.",
      transactionError: "Error deleting transaction",
      transactionErrorDescription: "There was an error trying to delete the transaction.",
      transactionAdded: "Transaction added",
      transactionUpdated: "Transaction updated",
      transactionSuccessDescription: "The transaction was successfully processed.",
      loginSuccess: "Login successful",
      loginSuccessDescription: "You have successfully logged in.",
      logout: "Logging out",
      logoutMessage: "You have successfully logged out.",
      register: "Register",
      welcome: "Welcome Fatcat",
      username: "Username",
      email: "Email",
      password: "Password",
      description: "Description",
      whatsapp: "WhatsApp",
      registerButton: "Register",
      loginSuccess: "Login successful",
      loginSuccessDescription: "You have successfully logged in",
      loginFailed: "Login failed",
      loginFailedDescription: "Login failed. Please check your credentials.",
      userCreated: "User created",
      userCreatedDescription: "The user has been created successfully.",
      errorOccurred: "An error occurred",
      errorOccurredDescription: "Unable to create user.",
      alreadyHaveAccount: "Already have an account? Login here",
      dontHaveAccount: "Don't have an account? Register here",
      addNewStock: "Add New Stock",
      cancel: "Cancel",
      stockAdded: "Stock Added",
      stockAddedDescription: "The stock has been added successfully.",
      stockAddError: "Error Adding Stock",
      invalidData: "Invalid data received.",
      suggestionAdded: "Suggestion Added",
      suggestionAddedDescription: "Your suggestion has been added successfully.",
      suggestionAddError: "Error Adding Suggestion",
      suggestionDeleted: "Suggestion Deleted",
      suggestionDeletedDescription: "The suggestion has been deleted successfully.",
      suggestionDeleteError: "Error Deleting Suggestion",
      noSuggestions: "No suggestions available.",
      suggestionId: "Suggestion ID",
      status: "Status",
      message: "Message",
      noStocks: "No stocks available.",
      noSymbolsAvailable: "No symbols available.",
      selectSymbol: "Select symbol",
      deleteAnalysis: "Delete Analysis",
      confirmDeleteAnalysis: "Are you sure you want to delete this analysis? This action cannot be undone.",
      analysisDeleted: "Analysis Deleted",
      analysisDeletedDescription: "The analysis has been deleted successfully.",
      analysisDeleteError: "Error Deleting Analysis",
      analysis: "Analysis",
      noAnalyses: "No analyses available for this stock.",
      view: "View",
      delete: "Delete",
      close: "Close",
      analysisDetails: "Analysis Details",
      suggestionDetail: "Suggestion Detail",
      confirmDeleteSuggestion: "Are you sure you want to delete this suggestion? This action cannot be undone.",
      deleteSuggestion: "Delete Suggestion",
      addSuggestion: "Add Suggestion",
      enterSuggestion: "Enter your suggestion",
      infoStock: "Stock Information",
      relevantInfo: "Relevant Information",
      ivRank: "IV Rank",
      correlationBovespa: "Bovespa Correlation",
      financialVolume: "Financial Volume",
      beta: "Beta",
      ivPercentile: "IV Percentile",
      ivMax: "IV Maximum",
      ivMin: "IV Minimum",      
    },
    pt: {
      connectionError: "Erro de conexão",
      connectionErrorDescription: "Ocorreu um erro na conexão WebSocket. Tente recarregar a página.",
      stockError: "Erro ao carregar ações",
      stockErrorDescription: "Não foi possível carregar a lista de ações disponíveis.",
      transactionDeleted: "Transação excluída",
      transactionDeletedDescription: "A transação foi excluída com sucesso.",
      transactionError: "Erro ao excluir transação",
      transactionErrorDescription: "Ocorreu um erro ao tentar excluir a transação.",
      transactionAdded: "Transação adicionada",
      transactionUpdated: "Transação atualizada",
      transactionSuccessDescription: "A transação foi processada com sucesso.",
      loginSuccess: "Login bem-sucedido",
      loginSuccessDescription: "Você entrou com sucesso.",
      logout: "Saindo",
      logoutMessage: "Você saiu com sucesso.",
      register: "Registrar",
      welcome: "Bem-vindo ao Fatcat",
      username: "Nome de usuário",
      email: "E-mail",
      password: "Senha",
      description: "Descrição",
      whatsapp: "WhatsApp",
      registerButton: "Registrar",
      loginSuccess: "Login bem-sucedido",
      loginSuccessDescription: "Você entrou com sucesso",
      loginFailed: "Falha no login",
      loginFailedDescription: "Falha no login. Verifique suas credenciais.",
      userCreated: "Usuário criado",
      userCreatedDescription: "O usuário foi criado com sucesso.",
      errorOccurred: "Ocorreu um erro",
      errorOccurredDescription: "Não foi possível criar o usuário.",
      alreadyHaveAccount: "Já tem uma conta? Entre aqui",
      dontHaveAccount: "Não tem uma conta? Registre-se aqui",      
      addNewStock: "Adicionar Novo Ativo",
      cancel: "Cancelar",
      stockAdded: "Ativo Adicionado",
      stockAddedDescription: "O ativo foi adicionado com sucesso.",
      stockAddError: "Erro ao Adicionar Ativo",
      invalidData: "Dados inválidos recebidos.",
      suggestionAdded: "Sugestão Adicionada",
      suggestionAddedDescription: "Sua sugestão foi adicionada com sucesso.",
      suggestionAddError: "Erro ao Adicionar Sugestão",
      suggestionDeleted: "Sugestão Excluída",
      suggestionDeletedDescription: "A sugestão foi excluída com sucesso.",
      suggestionDeleteError: "Erro ao Excluir Sugestão",
      noSuggestions: "Nenhuma sugestão disponível.",
      suggestionId: "ID da Sugestão",
      status: "Status",
      message: "Mensagem",
      noStocks: "Nenhum ativo disponível.",
      noSymbolsAvailable: "Nenhum símbolo disponível.",
      selectSymbol: "Selecione o símbolo",
      deleteAnalysis: "Deletar Análise",
      confirmDeleteAnalysis: "Tem certeza de que deseja deletar esta análise? Esta ação não pode ser desfeita.",
      analysisDeleted: "Análise Excluída",
      analysisDeletedDescription: "A análise foi excluída com sucesso.",
      analysisDeleteError: "Erro ao Excluir Análise",
      analysis: "Análise",
      noAnalyses: "Nenhuma análise disponível para este ativo.",
      view: "Visualizar",
      delete: "Excluir",
      close: "Fechar",
      analysisDetails: "Detalhes da Análise",
      suggestionDetail: "Detalhes da Sugestão",
      confirmDeleteSuggestion: "Tem certeza de que deseja excluir esta sugestão? Esta ação não pode ser desfeita.",
      deleteSuggestion: "Excluir Sugestão",
      addSuggestion: "Adicionar Sugestão",
      enterSuggestion: "Digite sua sugestão",
      infoStock: "Informações do Ativo",
      relevantInfo: "Informações Relevantes",
      ivRank: "IV Rank",
      correlationBovespa: "Correlação com o Bovespa",
      financialVolume: "Volume Financeiro",
      beta: "Beta",
      ivPercentile: "Percentil IV",
      ivMax: "IV Máximo",
      ivMin: "IV Mínimo",

    }
  };
  
  export default translations;
  