import React from 'react';
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';

const HorizontalIndicator = ({ value }) => {
  // Define o intervalo esperado para o put_call_ratio
  const minValue = 0;
  const maxValue = 2; // Máximo esperado para o put_call_ratio
  const normalizedValue = Math.min(Math.max(value, minValue), maxValue);
  const percentage = ((normalizedValue - minValue) / (maxValue - minValue)) * 100;

  return (
    <Box width="100%" textAlign="center">
      {/* Tooltip com valor dinâmico */}
      <Tooltip 
        label={`Put Call Ratio: ${value.toFixed(2)}`} 
        aria-label={`Put Call Ratio Tooltip`}
        hasArrow
      >
        <Box
          position="relative"
          height="10px"
          width="80%"
          margin="20px auto"
          background="linear-gradient(to right, #008000, #ffff00, #ffa500, #ff0000)" // Gradiente invertido
          borderRadius="5px"
          boxShadow="0 0 8px rgba(0, 0, 0, 0.2)"
          cursor="pointer" // Adiciona um cursor interativo
        >
          {/* Marcas no Indicador */}
          <Flex
            position="absolute"
            top="15px"
            left="0"
            width="100%"
            justifyContent="space-between"
          >
            {['0', '0.5', '1', '1.5', '2'].map((label, index) => (
              <Text
                key={index}
                fontSize="12px"
                color="gray.400" // Texto mais claro
                fontWeight="bold"
                position="absolute"
                left={`${index * (100 / 4)}%`} // Divide proporcionalmente entre 5 marcas
                transform="translateX(-50%)"
              >
                {label}
              </Text>
            ))}
          </Flex>

          {/* Triângulo Indicador */}
          <Box
            position="absolute"
            top="-20px" // Coloca o triângulo acima da barra
            left={`${percentage}%`} // Posição horizontal baseada no valor
            transform="translateX(-50%)"
            width="0"
            height="0"
            borderLeft="10px solid transparent"
            borderRight="10px solid transparent"
            borderBottom="15px solid #666" // Ponteiro mais claro
            filter="drop-shadow(0 2px 3px rgba(0,0,0,0.4))" // Sombra do triângulo
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default HorizontalIndicator;
