import React, { useState, useEffect } from 'react';
import {
    Box,
    Skeleton,
    Heading,
    Text,
    SimpleGrid,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Badge
} from '@chakra-ui/react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    ReferenceLine,
    Cell,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid, // Adicionado
    Legend,        // Adicionado
    LabelList      // Adicionado
} from 'recharts';


const IvRankChart = ({setSelectTicker}) => {
    const [ivData, setIvData] = useState([]);
    const [loading, setLoading] = useState(true);
    const toast = useToast();

    useEffect(() => {
        const fetchIvData = async () => {
            try {
                const response = await fetch('https://api.fatcat.app.br/ivrank');
                const data = await response.json();
                const processedData = data
                    .map(item => ({
                        ...item,
                        ivrank: parseFloat(item.ivrank),
                        ivhistorical: parseFloat(item.ivhistorical),
                        dif: parseFloat(item.dif),
                        isSignificant: Math.abs(parseFloat(item.dif)) > 20
                    }))
                    .sort((a, b) => b.dif - a.dif);

                setIvData(processedData);
                setLoading(false);
            } catch (error) {
                console.error('Erro ao buscar dados do IV Rank:', error);
                setLoading(false);
                toast({
                    title: "Erro ao carregar dados",
                    description: "Não foi possível carregar os dados do IV Rank",
                    status: "error",
                    duration: 5000,
                    isClosable: true
                });
            }
        };

        fetchIvData();
        const interval = setInterval(fetchIvData, 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, [toast]);

    const getMetrics = () => {
        if (!ivData.length) return { max: 0, maxSymbol: '', average: 0, total: 0 };

        const max = Math.max(...ivData.map(item => item.dif));
        const maxItem = ivData.find(item => item.dif === max);
        const average = ivData.reduce((acc, item) => acc + item.dif, 0) / ivData.length;

        return {
            max,
            maxSymbol: maxItem?.symbol || '',
            average,
            total: ivData.length
        };
    };

    const handleStockSelect = (symbol) => {
        setSelectTicker(symbol);

    };

    const metrics = getMetrics();
    const topData = ivData.slice(0, 30); // Limita o gráfico aos 30 primeiros

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <Box bg="gray.800" p={3} borderRadius="md" border="1px" borderColor="gray.700">
                    <Text fontWeight="bold" color="white" mb={2}>{data.symbol}</Text>
                    <Text fontSize="sm" color="gray.300">
                        IV Histórico: {data.ivhistorical.toFixed(2)}%
                    </Text>
                    <Text fontSize="sm" color="blue.400">
                        IV Rank: {data.ivrank.toFixed(2)}%
                    </Text>
                    <Text
                        fontSize="sm"
                        fontWeight="medium"
                        color={data.dif > 0 ? 'green.400' : 'red.400'}
                    >
                        Diferença: {data.dif > 0 ? '+' : ''}{data.dif.toFixed(2)}%
                    </Text>
                </Box>
            );
        }
        return null;
    };

    const getStatusColor = (dif) => {
        if (dif > 20) return 'green';
        if (dif > 0) return 'teal';
        if (dif < -20) return 'red';
        return 'orange';
    };

    const getStatusText = (dif) => {
        if (dif > 20) return 'FORTE ALTA';
        if (dif > 0) return 'ALTA';
        if (dif < -20) return 'FORTE BAIXA';
        return 'BAIXA';
    };

    return (
        <Box p={6} bg="gray.900" borderRadius="lg">
            <Heading as="h2" size="lg" mb={6} color="white">
                IV Rank e Histórico
            </Heading>

            <SimpleGrid columns={3} spacing={4} mb={6}>
                <Box bg="gray.800" p={4} borderRadius="lg">
                    <Text fontSize="sm" color="gray.400">Maiores Diferenças</Text>
                    <Text fontSize="3xl" fontWeight="bold" color="green.500" mt={2}>
                        {metrics.max.toFixed(2)}%
                    </Text>
                    <Text fontSize="sm" color="gray.400" mt={1}>{metrics.maxSymbol}</Text>
                </Box>

                <Box bg="gray.800" p={4} borderRadius="lg">
                    <Text fontSize="sm" color="gray.400">Média do Mercado</Text>
                    <Text fontSize="3xl" fontWeight="bold" color="blue.500" mt={2}>
                        {metrics.average.toFixed(2)}%
                    </Text>
                    <Text fontSize="sm" color="gray.400" mt={1}>Diferença IV Rank/Hist</Text>
                </Box>

                <Box bg="gray.800" p={4} borderRadius="lg">
                    <Text fontSize="sm" color="gray.400">Ativos Monitorados</Text>
                    <Text fontSize="3xl" fontWeight="bold" color="purple.500" mt={2}>
                        {metrics.total}
                    </Text>
                    <Text fontSize="sm" color="gray.400" mt={1}>Total</Text>
                </Box>
            </SimpleGrid>

            {loading ? (
                <Skeleton height="500px" />
            ) : (
                <>
                    <Box height="500px">
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                data={topData}
                                margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
                                layout="horizontal" // Mudando para horizontal para melhor visualização
                                onClick={(data) => handleStockSelect(data.ticker)}
                                cursor="pointer"

                            >
                                <CartesianGrid strokeDasharray="3 3" stroke="#2D3748" opacity={0.3} />
                                <XAxis
                                    dataKey="symbol"
                                    tick={{ fill: '#E2E8F0', fontSize: "12px"}} 
                                />
                                <YAxis
                                    tick={{ fill: '#E2E8F0', fontSize: "12px" }}
                                    tickFormatter={(value) => `${value}%`}
                                />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />
                                <Bar dataKey="ivhistorical" name="IV Histórico" stackId="a" fill="#4A5568">
                                    <LabelList
                                        dataKey="ivhistorical"
                                        position="inside" // Posiciona no meio da barra
                                        fill="#E2E8F0"
                                        fontSize="10px"  // Define o tamanho da fonte
                                        angle={-90}      // Rotaciona os rótulos para vertical
                                        formatter={(value) => `${value.toFixed(1)}%`}
                                    />
                                </Bar>
                                <Bar dataKey="ivrank" name="IV Rank" stackId="a" fill="#3182CE">
                                    <LabelList
                                        dataKey="ivrank"
                                        position="inside" // Posiciona no meio da barra
                                        fill="#E2E8F0"
                                        fontSize="10px"  // Define o tamanho da fonte
                                        angle={-90}      // Rotaciona os rótulos para vertical
                                        formatter={(value) => `${value.toFixed(1)}%`}
                                    />
                                </Bar>
                                <Bar dataKey="dif" name="Diferença" stackId="a">
                                    {topData.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.dif > 0
                                                ? entry.isSignificant ? '#38A169' : '#68D391'
                                                : entry.isSignificant ? '#E53E3E' : '#FC8181'
                                            }
                                        />
                                    ))}
                                    <LabelList
                                        dataKey="dif"
                                        position="inside" // Posiciona no meio da barra
                                        fill="#E2E8F0"
                                        fontSize="10px"  // Define o tamanho da fonte
                                        angle={-90}      // Rotaciona os rótulos para vertical
                                        formatter={(value) => `${value > 0 ? '+' : ''}${value.toFixed(1)}%`}
                                    />
                                </Bar>


                            </BarChart>
                        </ResponsiveContainer>
                    </Box>


                    <Box bg="gray.800" p={6} borderRadius="lg" mt={6}>
                        <Heading as="h3" size="md" mb={4} color="white">
                            Detalhes dos Ativos
                        </Heading>

                        <Box overflowX="auto">
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th color="gray.400">Ativo</Th>
                                        <Th isNumeric color="gray.400">IV Rank</Th>
                                        <Th isNumeric color="gray.400">IV Histórico</Th>
                                        <Th isNumeric color="gray.400">Diferença</Th>
                                        <Th color="gray.400">Status</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {ivData.map((item) => (
                                        <Tr key={`increased-${item.symbol}`} onClick={() => handleStockSelect(item.symbol)} style={{ cursor: 'pointer' }} _hover={{ bg: 'gray.700' }}>
                                        
                                            <Td color="white">{item.symbol}</Td>
                                            <Td isNumeric color="white">{item.ivrank.toFixed(2)}%</Td>
                                            <Td isNumeric color="white">{item.ivhistorical.toFixed(2)}%</Td>
                                            <Td
                                                isNumeric
                                                color={item.dif > 0 ? 'green.400' : 'red.400'}
                                                fontWeight="medium"
                                            >
                                                {item.dif > 0 ? '+' : ''}{item.dif.toFixed(2)}%
                                            </Td>
                                            <Td>
                                                <Badge
                                                    colorScheme={getStatusColor(item.dif)}
                                                    variant="subtle"
                                                    px={2}
                                                    borderRadius="full"
                                                >
                                                    {getStatusText(item.dif)}
                                                </Badge>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    );
};

export default IvRankChart;