import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Flex,
  useColorMode,
  useColorModeValue,
  Text,
  Avatar,
  Input,
  IconButton,
} from '@chakra-ui/react';
import { ArrowForwardIcon, RepeatIcon } from '@chakra-ui/icons';
import { marked } from 'marked';
import SymbolSearch from './SymbolSearch';

const AIChat = ({ onClose, description, tickers }) => {
  // Movendo todos os useColorModeValue para o topo do componente
  const backgroundColorValue = useColorModeValue('gray.100', 'gray.900');
  const textColorValue = useColorModeValue('gray.800', 'white');
  const messageBackgroundUser = useColorModeValue('blue.500', 'blue.300');
  const messageBackgroundAI = useColorModeValue('gray.200', 'gray.700');
  const messageTextColorUser = useColorModeValue('white', 'gray.800');
  const messageTextColorAI = useColorModeValue('gray.800', 'white');
  const headerBackgroundColor = useColorModeValue('white', 'gray.800');
  const borderColorValue = useColorModeValue('gray.200', 'gray.600');
  const inputBackgroundColor = useColorModeValue('white', 'gray.700');

  const initialMessages = [
    {
      id: 1,
      text: "Olá! Eu sou Professor. Espero que esteja bem!",
      sender: 'ai',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    },
    {
      id: 2,
      text: "Para qual o ativo é a sua pergunta?",
      sender: 'ai',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    },
  ];

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    return savedMessages ? JSON.parse(savedMessages) : initialMessages;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [symbol, setSymbol] = useState('');
  const [isWaitingForSymbol, setIsWaitingForSymbol] = useState(true);

  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const { colorMode } = useColorMode();

  const LoadingMessage = () => (
    <Flex
      justify="flex-start"
      marginBottom={4}
      paddingX={4}
    >
      <Avatar size="sm" src="/ai-avatar.png" marginRight={2} />
      <Box
        maxWidth="70%"
        backgroundColor={messageBackgroundAI}
        color={messageTextColorAI}
        borderRadius="lg"
        paddingX={4}
        paddingY={2}
      >
        <Flex align="center">
          <Text fontSize="sm" fontStyle="italic">...</Text>
          <Flex marginLeft={2}>
            <Box
              width="2px"
              height="2px"
              borderRadius="full"
              backgroundColor="blue.500"
              marginX="1px"
              animation="bounce 0.8s infinite"
              style={{ animationDelay: '0s' }}
            />
            <Box
              width="2px"
              height="2px"
              borderRadius="full"
              backgroundColor="blue.500"
              marginX="1px"
              animation="bounce 0.8s infinite"
              style={{ animationDelay: '0.2s' }}
            />
            <Box
              width="2px"
              height="2px"
              borderRadius="full"
              backgroundColor="blue.500"
              marginX="1px"
              animation="bounce 0.8s infinite"
              style={{ animationDelay: '0.4s' }}
            />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  useEffect(() => {
    const messageContainer = messagesEndRef.current?.parentElement;
    if (messageContainer) {
      const isScrolledToBottom = messageContainer.scrollHeight - messageContainer.scrollTop === messageContainer.clientHeight;
      if (isScrolledToBottom) {
        scrollToBottom();
      }
    }
  }, [messages]);

  useEffect(() => {
    localStorage.setItem('chatMessages', JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages]);

  const handleResetConversation = () => {
    setMessages(initialMessages);
    setSymbol('');
    setMessage('');
    setIsWaitingForSymbol(true);
    localStorage.removeItem('chatMessages');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleSymbolSelection = (selectedSymbol) => {
    const userMessage = {
      id: messages.length + 1,
      text: selectedSymbol,
      sender: 'user',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };

    const aiResponseMessage = {
      id: messages.length + 2,
      text: "Ótimo! Agora pode fazer sua pergunta sobre o ativo.",
      sender: 'ai',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };

    setMessages((previousMessages) => [...previousMessages, userMessage, aiResponseMessage]);
    setSymbol(selectedSymbol);
    setIsWaitingForSymbol(false);
  };

  const handleSendMessage = async () => {
    if (!message.trim() || isWaitingForSymbol) {
      return;
    }

    const userMessage = {
      id: messages.length + 1,
      text: message,
      sender: 'user',
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };

    setMessages((previousMessages) => [...previousMessages, userMessage]);
    setMessage('');
    setIsLoading(true);

    try {
      const requestPayload = {
        symbol: symbol,
        message: message,
        description: description,
      };

      const response = await fetch('https://data.fatcat.app.br/chat-ai', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestPayload),
      });

      const aiResponse = await response.json();

      const aiMessage = {
        id: messages.length + 2,
        text: aiResponse,
        sender: 'ai',
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMessages((previousMessages) => [...previousMessages, aiMessage]);
    } catch (error) {
      console.error('Erro ao obter resposta da IA:', error);
      
      const errorMessage = {
        id: messages.length + 2,
        text: 'Desculpe, ocorreu um erro ao processar sua mensagem. Por favor, tente novamente.',
        sender: 'ai',
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMessages((previousMessages) => [...previousMessages, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <Flex
      width="100%"
      height="90vh"
      backgroundColor={backgroundColorValue}
      zIndex={2000}
      padding={2}
      position="relative"
      flexDirection="column"
    >
      <Flex
        align="center"
        justify="flex-end"
        padding={2}
        backgroundColor={headerBackgroundColor}
        borderBottom="1px solid"
        borderColor={borderColorValue}
      >
        <IconButton
          icon={<RepeatIcon />}
          onClick={handleResetConversation}
          aria-label="Reiniciar conversa"
          colorScheme="blue"
          variant="ghost"
          size="md"
          title="Reiniciar conversa"
          tabIndex={-1}
        />
      </Flex>

      <Flex direction="column" padding={4} overflowY="auto" flex={1}>
        {messages.map((messageItem) => (
          <Flex
            key={messageItem.id}
            justify={messageItem.sender === 'user' ? 'flex-end' : 'flex-start'}
            marginBottom={4}
            paddingX={4}
          >
            {messageItem.sender === 'ai' && (
              <Avatar size="sm" src="/ai-avatar.png" marginRight={2} />
            )}
            <Box
              maxWidth="70%"
              backgroundColor={messageItem.sender === 'user' ? messageBackgroundUser : messageBackgroundAI}
              color={messageItem.sender === 'user' ? messageTextColorUser : messageTextColorAI}
              borderRadius="lg"
              paddingX={4}
              paddingY={2}
            >
              {messageItem.sender === 'ai' ? (
                <Box
                  dangerouslySetInnerHTML={{ __html: marked(messageItem.text) }}
                  fontSize="sm"
                />
              ) : (
                <Text>{messageItem.text}</Text>
              )}
              <Text fontSize="xs" opacity={0.7} marginTop={1}>
                {messageItem.time}
              </Text>
            </Box>
          </Flex>
        ))}
        {isLoading && <LoadingMessage />}
        <div ref={messagesEndRef} />
      </Flex>

      <Flex 
        align="center" 
        padding={4} 
        backgroundColor={headerBackgroundColor}
        borderTop="1px solid" 
        borderColor={borderColorValue}
      >
        {isWaitingForSymbol ? (
          <SymbolSearch
            onSelect={handleSymbolSelection}
            tickers={tickers}
            initialSymbol=""
          />
        ) : (
          <Input
            ref={inputRef}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Digite sua mensagem..."
            backgroundColor={inputBackgroundColor}
            color={textColorValue}
            borderRadius="full"
            marginRight={2}
            size="md"
            _focus={{
              borderColor: 'blue.500',
              boxShadow: 'outline',
            }}
          />
        )}
        <IconButton
          icon={<ArrowForwardIcon />}
          onClick={handleSendMessage}
          aria-label="Enviar mensagem"
          colorScheme="blue"
          borderRadius="full"
          size="md"
          tabIndex={-1}
        />
      </Flex>
    </Flex>
  );
};

export default AIChat;