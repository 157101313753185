import React, { useState, useEffect, useCallback } from 'react';
import Workspace from './components/Workspace';
import TopBar from './components/TopBar';
import RightSidebar from './components/RightSidebar';
import Login from './components/Login';
import './App.css';
import {
  ChakraProvider,
  Box,
  useToast,
  extendTheme,
  Flex,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Spinner,
  ModalCloseButton,
  Drawer,        // Novo import
  DrawerBody,    // Novo import
  DrawerHeader,  // Novo import
  DrawerOverlay, // Novo import
  DrawerContent, // Novo import
  DrawerCloseButton // Novo import
} from "@chakra-ui/react";import { jwtDecode } from 'jwt-decode';
import CustomIcon from './components/CustomIcon';
import { getAllTransactions, getTransactionsSymbol, createTransaction, updateTransaction, deleteTransaction } from './api/transactions';
import { getAtivosOptions } from './api/default';
import { getUser } from './api/user';
import WebSocketManager from './components/WebSocketManager';
import translations from './util/translations';
import Chat from "./components/ChatContainer";
// import ChatContainer from './components/ChatContainer';
import AIChat from './components/AiChat';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedStock, setSelectedStock] = useState(null);

  const [selectedTab, setSelectedTab] = useState('chart');
  const [stocksWithOptions, setStocksWithOptions] = useState([]);
  const [stockSymbols, setStockSymbols] = useState([]);
  const [tickers, setTickers] = useState([]);
  const [tickersWithOptions, setTickersWithOptions] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [webSocketManager, setWebSocketManager] = useState(null);
  const [language, setLanguage] = useState('en');
  const [optionData, setOptionData] = useState([]); // Novo estado para armazenar as opções
  const [optionDataFull, setOptionDataFull] = useState([]); // Novo estado para armazenar as opções
  const [dueDateFilter, setDueDateFilter] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [stockData, setStockData] = useState({});
  const [loadingOptions, setLoadingOptions] = useState(false); // Novo estado para controlar o carregamento das opções
  const [nearestDueDate, setNearestDueDate] = useState(null);
  const [distinctDueDates, setDistinctDueDates] = useState([]);
  const [cliente, setCliente] = useState(1);
  const [lastPrice, setLastPrice] = useState(0);
  const [hasOption, setHasOption] = useState(0);
  const [renewDate, setRenewDate] = useState(null);
  const [selectTicker, setSelectTicker] = useState(null);
  const [descriptionCliente, setDescriptionCliente] = useState(null);

  const toggleChat = () => setIsChatOpen(!isChatOpen);

  const toast = useToast();

  const config = {
    initialColorMode: "dark",
    useSystemColorMode: false,
  };

  const theme = extendTheme({ config });

  // const toggleChat = () => setIsChatOpen(!isChatOpen);

  const handleLogin = (token) => {
    setIsAuthenticated(true);
    setIsAuthChecked(true);
    const decodedToken = jwtDecode(token);
    localStorage.setItem('token', token);
    localStorage.setItem('userId', decodedToken.id);
    localStorage.setItem('firstAccess', decodedToken.firstAccess);
    localStorage.setItem('cliente', decodedToken.cliente);


    setUserId(decodedToken.id);
    if (decodedToken.idioma) {
      setLanguage(decodedToken.idioma);
    }
  };


  const handleWebSocketMessage = useCallback((priceData) => {
    setStockData((prevStockData) => {
      const updatedStockData = { ...prevStockData };

      priceData.forEach(({ ticker, value, variation }) => {
        if (ticker && typeof ticker === 'string') {
          const cleanTicker = ticker.replace('.SA', '');  // Remove o sufixo '.SA'

          // Verifica se o ativo já existe no stockData
          const existingData = updatedStockData[cleanTicker];

          // Se o ativo já existe, atualiza somente os campos de preço e volume
          if (existingData) {
            updatedStockData[cleanTicker] = {
              ...existingData, // Mantém os outros dados inalterados
              price: parseFloat(value),
              variation: parseFloat(variation)
            };
          } else {
            // Se o ativo ainda não existe, inicializa todos os campos
            updatedStockData[cleanTicker] = {
              symbol: cleanTicker,
              price: parseFloat(value),
              variation: parseFloat(variation)
            };
          }

        }
      });

      return updatedStockData;
    });
  }, []);


  const handleWebSocketError = useCallback((error) => {
    console.error('App: WebSocket error:', error);
    toast({
      title: translations[language].connectionError,
      description: translations[language].connectionErrorDescription,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  }, [toast, language]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUserId = localStorage.getItem('userId');
    const firstAccess = localStorage.getItem('firstAccess');

    setCliente(localStorage.getItem('cliente'));

    if (token && storedUserId) {
      setIsAuthenticated(true);
      setUserId(storedUserId);

      // Verifica o status do cliente
      verifyClientStatus(storedUserId);
    }
    setIsAuthChecked(true);

    const newWebSocketManager = new WebSocketManager(handleWebSocketMessage, handleWebSocketError);
    setWebSocketManager(newWebSocketManager);

    return () => {
      if (newWebSocketManager) {
        newWebSocketManager.close();
      }
    };
  }, [handleWebSocketMessage, handleWebSocketError]);

  useEffect(() => {
    if (cliente === 0 && isAuthenticated) {
      toast({
        title: 'Acesso limitado',
        description: 'Seu acesso ao Fatcat está limitado. Considere renovar sua assinatura.',
        status: 'warning',
        duration: 10000,
        isClosable: true,
      });
    }
  }, [cliente, isAuthenticated, toast]);


  useEffect(() => {
    const fetchStocksWithOptionsAndTransactions = async () => {
      try {
        const data = await getAtivosOptions();
        const symbols = data?.map(stock => `${stock.symbol}|S`) || []; // Adicionando |S aos símbolos
        const symbolsT = data?.map(stock => stock.symbol) || []; // Adicionando |S aos símbolos

        // Apenas os símbolos com has_options = 1
        const symbolsWithOptions = data
          ?.filter(stock => stock.has_options === 1)
          .map(stock => stock.symbol) || [];
        setTickersWithOptions(symbolsWithOptions);


        setStockSymbols(symbols);
        setStocksWithOptions(symbols);
        setTickers(symbolsT);

        // Nova lógica: busca única para todas as transações do cliente
        const allTransactions = await getAllTransactions(userId);
        setTransactions(allTransactions);

        // const transactionsPromises = symbols.map(symbol => getTransactionsSymbol(symbol.replace('|S', ''), userId)); // Removendo |S para buscar as transações
        // const transactionsResults = await Promise.all(transactionsPromises);

        // const allTransactions = transactionsResults.flat();

        setTransactions(allTransactions);

        if (webSocketManager) {
          webSocketManager.subscribeToSymbols(symbols); // Subscrição inicial com os símbolos marcados com |S
        }
      } catch (error) {
        toast({
          title: translations[language].stockError,
          description: translations[language].stockErrorDescription,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    if (webSocketManager && userId) {
      fetchStocksWithOptionsAndTransactions();
    }
  }, [toast, userId, webSocketManager, language]);


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userData = await getUser(userId);
        setUserInfo(userData);
      } catch (error) {
        toast({
          title: translations[language].stockError,
          description: translations[language].stockErrorDescription,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    if (userId) {
      fetchUser();
    }
  }, [userId, toast, language]);


  const handleSelectStock = async (stock) => {
    setSelectTicker(null);
    try {
      
      // Extrair o símbolo do stock, seja ele objeto ou string
      const stockSymbol = typeof stock === 'object' ? stock.symbol : stock;
      
      // Se for um objeto com strategy, mantém o objeto inteiro
      // Caso contrário, usa apenas o symbol
      setSelectedStock(typeof stock === 'object' && stock.strategy ? stock : stockSymbol);
  
      setLoadingOptions(true);
  
      // Usa stockSymbol para as chamadas de API
      await fetchPriceTicker(stockSymbol);
  
      if (hasOption === 1) {
        const optionsData = await fetchOptionsData(stockSymbol);
        setOptionData(optionsData || []);
      } else {
        setOptionData([]);
      }
  
      // Usa stockSymbol para buscar transações
      const transactionsData = await getTransactionsSymbol(stockSymbol, userId);
      setTransactions(transactionsData);
  
    } catch (error) {
      toast({
        title: translations[language].transactionError,
        description: translations[language].transactionErrorDescription,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoadingOptions(false);
    }
  };


  useEffect(() => {
    if (!selectedStock && tickersWithOptions.length > 0) {
      const initialStock = cliente === 0 ? 'BOVA11' : tickersWithOptions[0];
      setSelectedStock('BOVA11'); // Atualiza o estado
      handleSelectStock(initialStock); // Garante que as funções dependentes são chamadas
    }
  }, [selectedStock, tickersWithOptions, cliente, handleSelectStock]);


  const handleSelectTicker  = async (stock) => {

    setSelectTicker(stock);

  };

  
  const fetchPriceTicker = async (symbol) => {
    try {
      const response = await fetch(`https://api.fatcat.app.br/cotacao/${symbol}`);
      if (!response.ok) {
        throw new Error(`Erro na API: status ${response.status}`);
      }


      const data = await response.json();

      if (data && data.price) {
        setLastPrice(parseFloat(data.price)); // Atualiza o `lastPrice` com o preço retornado
        setHasOption(data.has_options);
      } else {
        console.error("Resposta inesperada da API:", data);
      }
    } catch (error) {
      console.error("Erro ao buscar cotação:", error.message);
    }
  };


  const fetchOptionsData = async (symbol) => {
    try {
      const response = await fetch(`https://data.fatcat.app.br/options?symbol=${symbol}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const text = await response.text();
      if (!text) {
        throw new Error('Response is empty');
      }

      // Converte o texto em um array de linhas e depois mapeia cada linha para um objeto
      const newOpcoes = text.split('\n').filter(Boolean).map(line => {
        const [
          symbol,
          value,
          variation,
          bid,
          ask,
          mid,
          financialVol,
          due_date,
          category,
          strike,
          days_to_maturity,
          contract_size,
          theoretical,
          delta
        ] = line.split('|'); // Separa os dados pelo delimitador "|"


        return {
          symbol,
          value: parseFloat(value),
          variation: parseFloat(variation),
          bid: parseFloat(bid),
          ask: parseFloat(ask),
          mid: parseFloat(mid),
          financialVol: parseFloat(financialVol),
          due_date,
          category,
          strike: parseFloat(strike),
          days_to_maturity: parseInt(days_to_maturity, 10),
          contract_size: parseFloat(contract_size),
          theoretical: parseFloat(theoretical),
          delta: parseFloat(delta),

        };
      });

      // Encontrar a data mais próxima de vencimento
      const sortedOptions = newOpcoes.sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
      const nearestDate = sortedOptions.length > 0 ? sortedOptions[0].due_date : null;
      setNearestDueDate(nearestDate);  // Atualiza o estado com a data mais próxima


      // Obter as datas de vencimento distintas
      const distinctDates = [...new Set(newOpcoes.map(option => option.due_date))];
      setDistinctDueDates(distinctDates);  // Atualiza o estado com as datas distintas

      return newOpcoes;


    } catch (error) {
      console.error("Error fetching options data:", error);
      setOptionData([]); // Define optionData como um array vazio em caso de erro

    }
  };


  const handleAddOrUpdateTransaction = useCallback(async (transaction) => {
    try {
      let updatedTransaction;
      if (transaction.id) {
        updatedTransaction = await updateTransaction(userId, transaction.id, transaction);
      } else {
        updatedTransaction = await createTransaction(transaction);
      }

      setTransactions(prevTransactions => {
        const newTransactions = transaction.id
          ? prevTransactions.map(t => t.id === updatedTransaction.id ? updatedTransaction : t)
          : [...prevTransactions, updatedTransaction];
        return newTransactions;
      });

      toast({
        title: transaction.id ? translations[language].transactionUpdated : translations[language].transactionAdded,
        description: translations[language].transactionSuccessDescription,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: translations[language].transactionError,
        description: translations[language].transactionErrorDescription,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [userId, toast, language]);


  const handleDeleteTransaction = async (transaction) => {
    try {
      await deleteTransaction(userId, transaction.id);
      setTransactions(prevTransactions => prevTransactions.filter(t => t.id !== transaction.id));
      toast({
        title: translations[language].transactionDeleted,
        description: translations[language].transactionDeletedDescription,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: translations[language].transactionError,
        description: translations[language].transactionErrorDescription,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const verifyClientStatus = async (userId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) return;

      const response = await fetch(`https://api.fatcat.app.br/user/verify-client/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to verify client status');
      }

      const data = await response.json();
      setCliente(data.cliente); // Atualiza o estado com o status do cliente
      setRenewDate(data.renewDate);
      setDescriptionCliente(data.description);
    } catch (error) {
      console.error('Error verifying client status:', error.message);
      setCliente(0); // Define como não cliente em caso de erro
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('cliente');

    setIsAuthenticated(false);
    setUserId(null);
    if (webSocketManager) {
      webSocketManager.close();
    }
    toast({
      title: translations[language].logout,
      description: translations[language].logoutMessage,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const handleDueDateChange = (dueDate) => {
    setDueDateFilter(dueDate);

    const filtered = optionData.filter(option => option.due_date === dueDate);

  };


  if (!isAuthChecked) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Login onLogin={handleLogin} />;
  }


  return (
    <ChakraProvider theme={theme}>
      <div className="app-container">
        <TopBar
          userId={userId}
          onLogout={handleLogout}
          onTabChange={setSelectedTab}
          selectedStock={selectedStock ?
            (typeof selectedStock === 'object' ? selectedStock : selectedStock)
            : 'PETR4'}
          stocksWithOptions={stocksWithOptions}
          tickers={tickers}
          stockData={stockData}
          handleSelectStock={handleSelectStock}  // <- Aqui está a passagem correta
          hasOption={hasOption}
          cliente={cliente}
          renewDate={renewDate}
        />

        <div className="main-content">
          <Workspace
            selectedStock={selectedStock || 'PETR4'} // Fornece um valor default
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}  // Adicione esta linha
            userId={userId}
            stockData={stockData}
            transactions={transactions}
            onAddOrUpdateTransaction={handleAddOrUpdateTransaction}
            onDeleteTransaction={handleDeleteTransaction}
            optionData={optionData}  // Passa os dados filtrados
            optionDataFull={optionDataFull}  // Passa os dados filtrados
            onDueDateChange={handleDueDateChange}
            loadingOptions={loadingOptions}
            nearestDueDate={nearestDueDate}
            distinctDueDates={distinctDueDates}
            tickers={tickers}
            handleSelectStock={handleSelectStock}  // <- Aqui está a passagem correta
            tickersWithOptions={tickersWithOptions}
            cliente={cliente}
            lastPrice={lastPrice}
            setSelectTicker={handleSelectTicker}
          />
          <RightSidebar
            stockData={stockData}
            userId={userId}
            handleSelectStock={handleSelectStock}
            selectedStock={selectedStock ? selectedStock : 'PETR4'}
            selectTicker={selectTicker}
            tickers={tickers}
          />
        </div>
        <Box position="fixed" bottom="10px" right="10px">
          <Tooltip label="Chat Fatcat" aria-label="Chat Fatcat Tooltip">
            <Box as="span">
              <CustomIcon fill="#1DA5CA" width="60px" height="60px" onClick={toggleChat} />
            </Box>
          </Tooltip>
        </Box>

        {isChatOpen && (
        <Drawer
        isOpen={isChatOpen}
        placement="right"
        onClose={toggleChat}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            The Professor
          </DrawerHeader>
          <DrawerBody padding={0}>
            <AIChat onClose={toggleChat} description={descriptionCliente} largura={"100%"} tickers={tickers}/>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
        )}
        {/* Modal para carregar enquanto loadingOptions for true */}
        <Modal isOpen={loadingOptions} onClose={() => { }} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Carregando Opções</ModalHeader>
            <ModalCloseButton />
            <ModalBody display="flex" justifyContent="center" alignItems="center">
              <Spinner size="xl" />
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </ChakraProvider>
  );
}

export default App;