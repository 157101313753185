import React, { useState, useEffect, useMemo } from 'react';
import { SimpleGrid, Box, Flex, Text, Spacer, Stat, StatArrow, Indicator } from '@chakra-ui/react';
import {
  calculateCurrentQuantity,
  calculateAveragePrice,
  calculatePaidAmount,
  calculateReceivedAmount,
  calculateIntrinsicValue,
  calculatePriceCeiling,
  calculateUpsideValue
} from '../util/calculation';

const StockOverview = ({ selectedStock, transactions = [], stockData }) => {
  const [intrinsicValue, setIntrinsicValue] = useState(0);
  const [currentVlteto, setCurrentVlteto] = useState(6.05);
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [currentQuantity, setCurrentQuantity] = useState(0);
  const [averagePrice, setAveragePrice] = useState(0);
  const [infoData, setInfData] = useState([]);

  const formatNumber = (num) => {
    return (typeof num === 'number' && !isNaN(num)) ? num.toFixed(2) : '-';
  };

  const fetchGetInfo = async () => {
    try {
      const response = await fetch(`https://data.fatcat.app.br/getInfo/${selectedStock}`);
      const data = await response.json();
      setInfData(data);
    } catch (error) {
      console.error('Erro ao buscar dados Fundamentalistas:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchGetInfo(); // Função assíncrona chamada corretamente
        if (selectedStock && Array.isArray(transactions)) {
          const intrinsicVal = calculateIntrinsicValue(infoData.LPA, infoData.VPA);
          const priceCeiling = calculatePriceCeiling(infoData.totalDividendo);
          const receivedAmt = calculateReceivedAmount(transactions, 1, selectedStock);
          const paidAmt = calculatePaidAmount(transactions, 1, selectedStock);
          const currentQty = calculateCurrentQuantity(transactions, selectedStock);
          const avgPrice = calculateAveragePrice(transactions, 1, selectedStock);
  
          setIntrinsicValue(intrinsicVal);
          setCurrentVlteto(priceCeiling);
          setReceivedAmount(receivedAmt);
          setPaidAmount(paidAmt);
          setCurrentQuantity(currentQty);
          setAveragePrice(avgPrice);
        }
      } catch (error) {
        console.error('Erro ao buscar dados ou calcular valores:', error);
      }
    };
  
    fetchData(); // Chamada da função assíncrona
  }, [selectedStock, transactions]);
  

  const upsideValue = useMemo(() => calculateUpsideValue(stockData?.[selectedStock]?.price, intrinsicValue), [stockData?.[selectedStock]?.price, intrinsicValue]);

  if (!selectedStock) {
    return <Box>Selecione uma ação para ver os detalhes.</Box>;
  }

  // Acessa preço e variação diretamente de stockData
  const stockPrice = stockData?.[selectedStock]?.price || 0;
  const stockVariation = stockData?.[selectedStock]?.variation || 0;

  return (
    <SimpleGrid columns={4} spacing={2} >
      <Box bgGradient="linear(to-r, blue.700, blue.700)" p={4} color="white" borderRadius="md">
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">ATIVO</Text>
          <Text fontSize="sm">{selectedStock}</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Meta</Text>
          <Text fontSize="sm">1.000,00</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Qtde Atual</Text>
          <Text fontSize="sm">{formatNumber(currentQuantity)}</Text>
        </Flex>
      </Box>

      <Box bgGradient="linear(to-r, blue.700, blue.700)" p={4} color="white" borderRadius="md">
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Lucro / Prejuízo atual</Text>
          <Text fontSize="sm">{formatNumber(receivedAmount - paidAmount)}</Text>
        </Flex>
        <Flex alignItems="center">
          <Text fontWeight="bold" fontSize="sm">Preço Atual</Text>
          <Spacer />
          <Text fontSize="sm">{formatNumber(stockPrice)}</Text>
          <Stat ml={2} fontSize="sm">
            <StatArrow type={stockVariation >= 0 ? 'increase' : 'decrease'} />
            {formatNumber(stockVariation)}%
          </Stat>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Preço Médio</Text>
          <Text fontSize="sm">{formatNumber(averagePrice)}</Text>
        </Flex>
      </Box>

      <Box bgGradient="linear(to-r, blue.700, blue.700)" p={4} color="white" borderRadius="md">
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Recebido</Text>
          <Text fontSize="sm">{formatNumber(receivedAmount)}</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Pago</Text>
          <Text color="red.500" fontSize="sm">{formatNumber(paidAmount)}</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Dividend Yield</Text>
          <Text fontSize="sm">{formatNumber((infoData.DividendYield || 0) * 100)}% (R$ {formatNumber(infoData.totalDividendo)} ano)</Text>
        </Flex>
      </Box>

      <Box bgGradient="linear(to-r, blue.700, blue.700)" p={4} color="white" borderRadius="md">
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Valor Intrínseco</Text>
          <Text fontSize="sm">{formatNumber(intrinsicValue)}</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Valor Teto</Text>
          <Text fontSize="sm">{formatNumber(currentVlteto)}</Text>
        </Flex>
        <Flex justifyContent="space-between">
          <Text fontWeight="bold" fontSize="sm">Up Side</Text>
          <Text fontSize="sm">{formatNumber(upsideValue)} %</Text>
        </Flex>
      </Box>
    </SimpleGrid>
  );
};

export default StockOverview;
