import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import { DataTable } from "./DataTable";
import {
  Box,
  IconButton,
  Stat,
  StatArrow,
  StatHelpText,
  useTheme,
  useMediaQuery,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";

const SimulationTable = ({
  userId,
  symbol,
  onEdit,
  onDelete,
  transactions = [],
  stockData = {},
  optionData ={}
}) => {
  const [lastUpdated, setLastUpdated] = useState({});
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const cancelRef = useRef();
  const [isConnected, setIsConnected] = useState(false);
  const [priceUpdated, setPriceUpdated] = useState({});

  const theme = useTheme();
  const [isMobile] = useMediaQuery("(max-width: 48em)");

  const cycleColors = [
    "yellow.300",  // Ciclo 1
    "blue.300",    // Ciclo 2
    "red.300",     // Ciclo 3
    "purple.300",  // Ciclo 4
    "green.300",   // Ciclo 5
    "yellow.400",  // Ciclo 6
    "blue.400",    // Ciclo 7
    "red.400",     // Ciclo 8
    "purple.400",  // Ciclo 9
    "green.400",   // Ciclo 10
    "yellow.500",  // Ciclo 11
    "blue.500",    // Ciclo 12
    "red.500",     // Ciclo 13
    "purple.500",  // Ciclo 14
    "green.500"    // Ciclo 15
  ];

// Função para formatar a data no formato dd/mm/aa

const formatDate = (date) => {
  if (!date) {
    return "-";
  }
  // Certifica-se de que a data seja tratada como local
  const [year, month, day] = date.split('-');  // Assumindo que 'due_date' está no formato 'YYYY-MM-DD'
  return `${day}/${month}/${year.slice(-2)}`;   // Retorna no formato 'dd/mm/aa'
};


  // console.log(stockData);

  // Função de formatação segura
  const formatNumber = (num) => {
    return typeof num === "number" && !isNaN(num) ? num.toFixed(2) : "-";
  };

  // Função para obter o preço atual de uma ação ou opção do stockData
  const getCurrentStockData = useCallback(
    (ticker) => stockData[ticker] || {},
    [stockData]
  );

  // Função para obter o preço atual de uma ação ou opção do stockData
  const getCurrentOptionsData = useCallback(
    (optionSymbol) => {
      // Verifica se a opção está presente e se o símbolo bate
      const option = optionData.find(opt => opt.symbol === optionSymbol);
      if (!option) {
        // console.log(`Opção ${optionSymbol} não encontrada no optionData`);
        return {};
      }
      return option;
    },
    [optionData]
  );
  

  const getProfitLoss = useCallback(
    (transaction) => {
      const currentOptionPrice = getCurrentOptionsData(transaction.option)?.value || 0; 
      const currentStockPrice = getCurrentStockData(transaction.symbol)?.price || 0;
  
      const transactionPrice = parseFloat(transaction.price);  // Preço de compra ou venda
      const quantity = parseInt(transaction.quantity);
      const buybackPrice = parseFloat(transaction.buyback_price);  // Preço de venda (se houver)
  
      let profitLossPercentage = 0;
  
      // Se for uma compra de ações (buy_stock)
      if (transaction.type === "buy_stock") {
        if (buybackPrice > 0) {
          // Usa o preço de venda (buybackPrice) se estiver definido
          profitLossPercentage =
            ((buybackPrice - transactionPrice) / transactionPrice) * 100;
        } else if (currentStockPrice !== undefined && currentStockPrice !== null) {
          // Caso não tenha recompra, usa o preço atual da ação
          profitLossPercentage =
            ((currentStockPrice - transactionPrice) / transactionPrice) * 100;
        }
      }
      // Se for uma venda de PUT ou CALL (sell_call, sell_put)
      else if (transaction.type === "sell_call" || transaction.type === "sell_put") {
        if (buybackPrice > 0) {
          // Se o preço de recompra (buybackPrice) estiver definido, usa ele no cálculo
          profitLossPercentage =
            ((transactionPrice - buybackPrice) / transactionPrice) * 100;
        } else {
          // Caso contrário, usa o preço atual da opção para o cálculo
          profitLossPercentage =
            ((transactionPrice - currentOptionPrice) / transactionPrice) * 100;
        }
      }
      // Se for uma compra de CALL (buy_call)
      else if (transaction.type === "buy_call") {
        if (currentOptionPrice > 0) {
          // O lucro é obtido se o preço da opção aumentar
          profitLossPercentage =
            ((currentOptionPrice - transactionPrice) / transactionPrice) * 100;
        }
      }
      // Se for uma compra de PUT (buy_put)
      else if (transaction.type === "buy_put") {
        if (currentOptionPrice > 0) {
          // O lucro é obtido se o preço da opção diminuir
          profitLossPercentage =
            ((currentOptionPrice - transactionPrice) / currentOptionPrice) * 100;
        }
      }
  
      return profitLossPercentage;
    },
    [getCurrentStockData, getCurrentOptionsData]
  );
  
  

  // Abre o diálogo de confirmação de exclusão
  const openDeleteDialog = (item) => {
    setItemToDelete(item);
    setIsDeleteDialogOpen(true);
  };

  // Fecha o diálogo de exclusão
  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  // Confirma e exclui uma transação
  const confirmDelete = () => {
    if (itemToDelete) {
      onDelete(itemToDelete);
      closeDeleteDialog();
    }
  };

  // Define o tipo de exibição para o tipo de transação
  const getDisplayType = (type) => {
    const types = { sell_put: "Put", sell_call: "Call", buy_stock: "Stock" };
    return types[type] || type;
  };

  // Define as colunas da tabela
  const columns = useMemo(
    () => [
      {
        header: "Cycle",
        accessorKey: "cycle",
        cell: (info) => {
          const cycle = info.getValue();
          
          // Define a cor com base no ciclo (usa modulo para garantir que ciclos maiores que 15 reutilizem as cores)
          const color = cycleColors[(cycle - 1) % cycleColors.length];
      
          return (
            <Box
              width="25px"
              height="25px"
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              bg={color}  // Cor de fundo do círculo
              color="#000"  // Cor do texto
              boxShadow="md" // Sombra ao redor do círculo
              fontSize="12px"
            >
              {cycle}
            </Box>
          );
        },
        meta: { isNumeric: true },
        isVisible: !isMobile,
      },
      
      
      {
          header: "Type",
          accessorKey: "type",
          cell: (info) => getDisplayType(info.getValue()),
          isVisible: true,
  
        },    
      {
        header: "Symbol",
        accessorKey: "symbol",
        cell: (info) => info.getValue(),
        isVisible: false,

      },
  
      {
        header: "Option",
        accessorKey: "option",
        cell: (info) => info.getValue(),
        isVisible: !isMobile,
      },
      {
        header: "Strike",
        accessorKey: "strike",
        cell: (info) => info.getValue(),
        isVisible: !isMobile,
      },
      {
        header: "Venc.",
        accessorKey: "dueDate",
        cell: (info) => formatDate(info.getValue()?.split("T")[0]) || "",
        isVisible: !isMobile,
      },
      {
        header: "Price",
        accessorKey: "price",
        cell: (info) => formatNumber(parseFloat(info.getValue())),
        meta: { isNumeric: true },
      },
      {
        header: "Buyback",
        accessorKey: "buyback_price",
        cell: (info) => formatNumber(parseFloat(info.getValue())),
        meta: { isNumeric: true },
        isVisible: !isMobile,
      },
      {
        header: "Qty",
        accessorKey: "quantity",
        cell: (info) => info.getValue(),
        meta: { isNumeric: true },
      },

      {
        header: "P/L",
        accessorKey: "profit_loss",
        cell: (info) => {
          const profitLoss = getProfitLoss(info.row.original);
          if (profitLoss === null) return "-";
          const isProfit = profitLoss >= 0;
          const ticker = info.row.original.option || info.row.original.symbol;
          const isUpdated = priceUpdated[ticker];

          return (
            <Stat fontSize="13px" className={isUpdated ? "highlight" : ""}>
              <StatHelpText fontSize={13}>
                <StatArrow type={isProfit ? "increase" : "decrease"} />
                {formatNumber(profitLoss)}
              </StatHelpText>
            </Stat>
          );
        },
        meta: { isNumeric: true },
      },
      {
        header: "Last",
        accessorKey: "currentPrice",
        cell: (info) => {
          const { option, symbol } = info.row.original;
      
          // Tenta pegar os dados da opção
          const currentOptionData = getCurrentOptionsData(option);
          const currentStockData = getCurrentStockData(symbol);
      
          // Verifica se o valor da opção está disponível, senão tenta pegar o valor da ação
          const price = currentOptionData?.value !== undefined && currentOptionData?.value !== null
            ? currentOptionData.value
            : currentStockData?.price || "-";
      
          const isUpdated = priceUpdated[option] || priceUpdated[symbol];
      
          return <Box className={isUpdated ? "highlight" : ""}>{formatNumber(price)}</Box>;
        },
        meta: { isNumeric: true },
      },
      
      {
        header: "Actions",
        accessorKey: "actions",
        cell: ({ row }) => (
          <Box fontSize="10px" marginLeft={4}>
            <IconButton
              aria-label="Edit"
              icon={<EditIcon />}
              size="sm"
              mr={2}
              onClick={() => onEdit(row.original)}
            />
            <IconButton
              aria-label="Delete"
              icon={<DeleteIcon />}
              size="sm"
              onClick={() => openDeleteDialog(row.original)}
            />
          </Box>
        ),
      },
    ],
    [isMobile, getProfitLoss, getCurrentStockData, priceUpdated, onEdit]
  );

  const responsiveColumns = columns.filter((column) => column.isVisible !== false);

  // Efeito para atualizar o estado priceUpdated quando os preços mudam
  useEffect(() => {
    if (stockData && typeof stockData === "object") {
      const updatedPrices = {};
      Object.keys(stockData).forEach((symbol) => {
        if (lastUpdated[symbol] !== stockData[symbol]?.value) {
          updatedPrices[symbol] = true;
        }
      });
      setPriceUpdated(updatedPrices);
      setLastUpdated(stockData);

      // Reset priceUpdated after a short delay
      const timer = setTimeout(() => {
        setPriceUpdated({});
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [stockData, lastUpdated]);

  return (
  <Box
    marginLeft="15px"
    fontSize="14px"  // Aumenta o tamanho da fonte da tabela
    overflowX="auto"
    marginTop="20px"
    sx={{
      "& .chakra-table__body tr": {
        height: "18px",
        lineHeight: "1.1",
      },
      "& .chakra-table__cell": {
        padding: "8px 12px",  // Aumenta o espaçamento dentro das células
        whiteSpace: "nowrap",
        fontWeight: "bold",  // Torna o texto mais grosso
        fontSize: "14px",    // Aumenta o tamanho do texto nas células
        borderBottom: "2px solid #ddd",  // Aumenta a espessura da borda inferior das células
      },
      "& .chakra-table__header th": {
        padding: "8px 12px",
        whiteSpace: "nowrap",
        fontWeight: "extrabold",  // Torna o texto dos headers ainda mais grosso
        fontSize: "16px",         // Aumenta o tamanho do texto nos headers
        borderBottom: "3px solid #aaa",  // Aumenta a espessura da borda inferior nos headers
      },
    }}
  >
    <DataTable
      data={transactions}
      columns={responsiveColumns}
      stockData={stockData}  // Passa todo o stockData para a tabela
    />
    <AlertDialog
      isOpen={isDeleteDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={closeDeleteDialog}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Deletar Transação
          </AlertDialogHeader>
          <AlertDialogBody>
            Você tem certeza que deseja deletar essa transação? Essa ação não pode ser desfeita.
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={closeDeleteDialog}>
              Cancelar
            </Button>
            <Button colorScheme="red" onClick={confirmDelete} ml={3}>
              Deletar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  </Box>
);

};

export default SimulationTable;
