import axios from 'axios';

const API_URL = 'https://api.fatcat.app.br/ativos/';

export const getAtivos = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

export const createAtivos = async (ativos) => {
  const response = await axios.post(API_URL, ativos);
  return response.data;
};

export const updateAtivos = async (id, transaction) => {
  const response = await axios.put(`${API_URL}${id}/`, transaction);
  return response.data;
};

export const deleteAtivos = async (id) => {
  const response = await axios.delete(`${API_URL}${id}/`);
  return response.data;
};
