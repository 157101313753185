import React, { useMemo, useRef, useEffect } from "react";
import { HStack, VStack, Text, Badge, Box, Divider, Center, useColorModeValue } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const DueDateSelector = ({ selectedDate, onSelectDate, optionData }) => {
  const scrollContainerRef = useRef(null);

  // Cores baseadas no tema
  const bgColor = useColorModeValue("gray.100", "gray.800");
  const hoverBgColor = useColorModeValue("gray.300", "gray.600");
  const textColor = useColorModeValue("black", "white");
  const badgeColor = useColorModeValue("teal.600", "teal.200");
  const dividerColor = useColorModeValue("gray.500", "gray.400");
  const selectedBorderColor = useColorModeValue("#69609F", "#69609F");  // Cor da borda quando selecionada

  // Recalcular as datas de vencimento únicas sempre que optionData mudar
  const uniqueDueDates = useMemo(() => {
    const uniqueDatesMap = new Map(); // Mapa para armazenar as datas únicas

    optionData.forEach(option => {
      if (!uniqueDatesMap.has(option.due_date)) {
        uniqueDatesMap.set(option.due_date, option); // Garantir que o due_date é único
      }
    });

    // Retorna uma lista de valores ordenados por due_date
    return Array.from(uniqueDatesMap.values()).sort((a, b) => new Date(a.due_date) - new Date(b.due_date));
  }, [optionData]);

  // Seleciona automaticamente a primeira data quando o componente é carregado, apenas uma vez
  useEffect(() => {
    if (uniqueDueDates.length > 0 && !selectedDate) {
      onSelectDate(uniqueDueDates[0].due_date);  // Seleciona a primeira data
    }
  }, [uniqueDueDates, selectedDate, onSelectDate]);

  // Função para obter as letras do mês das opções CALL
  const getCallMonthLetters = (monthIndex) => {
    const callLetters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];
    return callLetters[monthIndex];
  };

  // Função para obter as letras do mês das opções PUT
  const getPutMonthLetters = (monthIndex) => {
    const putLetters = ["M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X"];
    return putLetters[monthIndex];
  };

  // Função para formatar a data no formato dd/mm/aa
  const formatDate = (date) => {
    // Certifica-se de que a data seja tratada como local
    const [year, month, day] = date.split('-');  // Assumindo que 'due_date' está no formato 'YYYY-MM-DD'
    return `${day}/${month}/${year.slice(-2)}`;   // Retorna no formato 'dd/mm/aa'
  };
  
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= 150;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += 150;
    }
  };

  return (
    <Box position="relative" zIndex="1" bg={bgColor} borderRadius="md" p={2} display="flex" alignItems="center">
      {/* Botão de Scroll Esquerdo */}
      <Box
        as="button"
        onClick={scrollLeft}
        bg={hoverBgColor}
        borderRadius="md"
        height="40px"
        width="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        mr={2}
      >
        <ChevronLeftIcon color={textColor} />
      </Box>

      {/* Container com as Datas e Opções */}
      <Box
        ref={scrollContainerRef}
        overflowX="hidden"
        whiteSpace="nowrap"
        display="flex"
        alignItems="center"
        flex="1"
      >
        <HStack spacing={1}>
          {uniqueDueDates.map((option, index) => {
            const month = new Date(option.due_date).getMonth();
            const isSelected = selectedDate === option.due_date;  // Verifica se a data está selecionada

            return (
              <VStack
                key={index}
                onClick={() => onSelectDate(option.due_date)}  // Marca como selecionada ao clicar
                bg={hoverBgColor}
                p={1}
                borderRadius="md"
                cursor="pointer"
                minW="120px"
                textAlign="center"
                spacing={1}
                border={isSelected ? `2px solid ${selectedBorderColor}` : "2px solid transparent"}  // Adiciona borda quando selecionada
                transition="border 0.2s ease"
              >
                <Center>
                  <Text color={textColor} fontWeight="bold" fontSize="12px">
                    {getCallMonthLetters(month)}
                  </Text>
                  <Divider orientation="vertical" borderColor={dividerColor} mx={1} />
                  <Text color={textColor} fontWeight="bold" fontSize="12px">
                    {getPutMonthLetters(month)}
                  </Text>
                </Center>
                <Divider borderColor={dividerColor} />
                <HStack spacing={1}>
                  <Badge colorScheme="teal" fontSize="10px" color={badgeColor}>{option.days_to_maturity}</Badge>
                  <Text color={textColor} fontSize="12px">{formatDate(option.due_date)}</Text>
                </HStack>
              </VStack>
            );
          })}
        </HStack>
      </Box>

      {/* Botão de Scroll Direito */}
      <Box
        as="button"
        onClick={scrollRight}
        bg={hoverBgColor}
        borderRadius="md"
        height="40px"
        width="20px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        ml={2}
      >
        <ChevronRightIcon color={textColor} />
      </Box>
    </Box>
  );
};

export default DueDateSelector;
