import axios from 'axios';

const API_URL = 'https://api.fatcat.app.br/transactions';

export const getTransactions = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

export const getTransactionsSymbol = async (symbol, userId) => {
  const response = await axios.get(API_URL + `/user/${userId}/symbol/${symbol}`);
  return response.data;
};

export const createTransaction = async (transaction) => {
  const response = await axios.post(API_URL, transaction);
  return response.data;
};

export const updateTransaction = async (userId, id, transaction) => {
  const response = await axios.put(`${API_URL}/user/${userId}/${id}`, transaction);
  return response.data;
};

export const deleteTransaction = async (userId, id) => {
  console.log("deletando", id);
  const response = await axios.delete(`${API_URL}/user/${userId}/${id}`);
  return response.data;
};


export const getAllTransactions = async (userId) => {
  const response = await axios.get(`${API_URL}/user/${userId}/all`);
  return response.data;
};

